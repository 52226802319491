import { useEffect, useState } from "react";

import Unauthorized from "../common/Unauthorized/Unauthorized";
import { useLocation, useNavigate } from "react-router-dom";


export default function isAuth(Component: any) {
  return function IsAuth(props: any) {
    const [accessToken, setAccessToken] = useState("");
    const [level, setLevel] = useState<any>("");
    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
      const token = localStorage.getItem("access_token");

      if (!token) {
        return navigate("/login");
      } else {
        setAccessToken(token);
        setLevel(localStorage.getItem("level"));
      }
    }, []);


    if (!accessToken) {
      return null;
    }

    if (!["admin", "super_admin", "admin_user"].includes(level) && !pathname.includes(`/${level}/`)) {
      return <Unauthorized />
    }

    return <Component {...props} />;
  };
}