"use client";

import { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";

import "./page.css";

import { Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Clear, UploadFileOutlined } from "@mui/icons-material";
import NotificationMessage from "../../../components/common/notification/notification";
import isAuth from "../../../components/IsAuth/IsAuth";
import * as XLSX from 'xlsx';
import mappingArray from "./fileMapping";

const API_URL = process.env.REACT_APP_BASE_URL!;

const AdvStockPage = () => {
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationDetails, setNotificationDetails] = useState({
        type: "",
        message: ""
    })
    const [stockData, setStockData] = useState([]);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [companyData, setCompanyData] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedFileHeader, setSelectedFileHeader] = useState([]);
    const [mapping, setMapping] = useState(mappingArray);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [selectedColorType, setSelectedColorType] = useState('');

    useEffect(() => {
        getCompanies();
    }, []);

    const handleDialogClose = () => {
        setOpenUploadDialog(false);
    }

    const handleUploadChange = (e: any) => {
        setSelectedFile(e.target.files[0]);
        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                if (event.target && event.target.result) {
                    const data: ArrayBuffer = new Uint8Array(event.target.result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0]; // Assuming the header is in the first sheet
                    const worksheet = workbook.Sheets[sheetName];
                    const header: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
                    setSelectedFileHeader(header.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase())));
                } else {
                    throw new Error('Unable to read file');
                }
            } catch (error) {
                console.error('Error parsing Excel file:', error);
            }
        };
        reader.readAsArrayBuffer(e.target.files[0]);
    }

    const getCompanies = () => {
        axiosInstance.get(`company`).then((data: any) => {
            setCompanyData(data.data);
        })
    }


    const uploadFileHandler = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setUploadingFile(true)
        const formData = new FormData();
        formData.append("company_code", selectedCompany);
        formData.append("color_type", selectedColorType);
        formData.append("file", selectedFile);
        formData.append("mapping", JSON.stringify(mapping));
        axiosInstance.post(`stocks/upload`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            if (response.data) {
                setStockData(response.data);
                setNotificationDetails({
                    type: "success",
                    message: "File Uploaded successfully"
                })
                setShowNotification(true);
                setUploadingFile(false)
                setOpenUploadDialog(false);
                setOpenSnackbar(true);
            } else {
                setNotificationDetails({
                    type: "error",
                    message: "Error while Uploading File"
                })
                setShowNotification(true);
                setUploadingFile(false);
                setOpenSnackbar(true);
            }

        }).catch(error => {
            setNotificationDetails({
                type: "error",
                message: "Error while Uploading File"
            })
            setShowNotification(true);
            setUploadingFile(false);
            setOpenSnackbar(true);
        })
    }

    const handleDropdownChange = (event: any) => {
        setSelectedCompany(event.target.value);
    };

    const handleDropdownColorTypeChange = (event: any) => {
        setSelectedColorType(event.target.value);
    };

    const handleExcelHeaderDropdownChange = (event: any, rowIndex: any) => {
        const updatedArray = [...mapping];
        updatedArray[rowIndex]['mapping_header'] = event.target.value;
        setMapping(updatedArray);
    };

    const clearMapping = (index: number) => {
        const updatedArray = [...mapping];
        updatedArray[index]['mapping_header'] = "";
        setMapping(updatedArray);
    }

    return <>
        <div className="w-full">
            <div className="p-4 flex justify-between items-center">
            <span className="font-bold text-3xl">Stock Upload</span>
                {/* <Button variant="outlined" startIcon={<UploadFileOutlined />} onClick={() => { setOpenUploadDialog(true), getCompanies() }}>Upload Stock</Button> */}
            </div>
            <Card variant="outlined">
                <CardContent>
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-5">
                            <FormControl fullWidth required size="small">
                                <InputLabel id="dropdown-label">Select Company</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    id="dropdown"
                                    label="Select Company"
                                    value={selectedCompany}
                                    onChange={handleDropdownChange}
                                >
                                    <MenuItem value="">Select Company</MenuItem>
                                    {companyData.map((company: any, index: any) => (
                                        <MenuItem key={company?.company_code} value={company?.company_code}>{company?.company_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-span-5">
                            <FormControl fullWidth required size="small">
                                <InputLabel id="dropdown-label">Select Color Type</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    id="dropdown"
                                    label="Select Color Type<"
                                    value={selectedColorType}
                                    onChange={handleDropdownColorTypeChange}>
                                    <MenuItem value="">Select Color Type</MenuItem>
                                    <MenuItem value="white">White</MenuItem>
                                    <MenuItem value="fancy">Fancy</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-span-12 flex items-center gap-4">
                            <input
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={handleUploadChange}
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="contained" component="span">
                                    Select File
                                </Button>
                            </label>
                            {selectedFile && (
                                <Typography variant="body2">
                                    {selectedFile.name}
                                </Typography>
                            )}
                        </div>
                    </div>
                    {selectedFile && selectedCompany &&
                        <Grid container alignItems="center">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="15%">Sr.No</TableCell>
                                            <TableCell width="25%">Columns</TableCell>
                                            <TableCell>Client Excel Columns</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {mapping.map((row: any, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="dropdown-label">Select Header</InputLabel>
                                                        <Select
                                                            labelId={`dropdown-label-${index}`}
                                                            id="dropdown"
                                                            style={{ display: 'flex' }}
                                                            size="small"
                                                            label="Select Header"
                                                            onChange={(event) => handleExcelHeaderDropdownChange(event, index)}
                                                            value={mapping[index]["mapping_header"] || ""}
                                                            IconComponent={
                                                                mapping[index]["mapping_header"] && mapping[index]["mapping_header"].length > 1
                                                                    ? () => (
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={(e) => {
                                                                                clearMapping(index)
                                                                            }}
                                                                        >
                                                                            <Clear />
                                                                        </IconButton>
                                                                    )
                                                                    : undefined
                                                            }>
                                                            {selectedFileHeader.map((header: any, index: any) => (
                                                                <MenuItem key={index} value={header}>{header}</MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                    <div className="flex justify-end items-center pt-6">
                        {(selectedFile && uploadingFile) && <CircularProgress size={30} />}
                        {selectedFile && selectedCompany && selectedColorType &&
                            <Button variant="contained" type="submit" onClick={uploadFileHandler} disabled={uploadingFile}>Upload</Button>
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
        <NotificationMessage isOpen={openSnackbar} type={notificationDetails.type} message={notificationDetails.message} handleClose={() => setOpenSnackbar(false)}></NotificationMessage>
    </>
}

export default isAuth(AdvStockPage);
