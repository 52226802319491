import isAuth from "../../../../components/IsAuth/IsAuth";
import StoneDetails from "../../../../components/StoneDetails/StoneDetails";
import axiosInstance from "../../../../utils/axiosInstance";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const StockDetailsPage = () => {
    const [stockDetails, setStockDetails] = useState(null)
    const param = useParams();


    const getStockDetails = () => {
        axiosInstance.get(`stocks/${param.id}`).then((response: any) => {
            setStockDetails(response.data);
        })
    }

    useEffect(() => {
        getStockDetails();
    }, [])

    return <div className="py-4">
        {stockDetails && <StoneDetails stoneDetails={stockDetails} />}
    </div>
}

export default StockDetailsPage;
