import { Tooltip } from "@mui/material";

const Treatment = [
    {name: "As Grown"},
    {name: "Treated"},
    {name: "Unknown"},
];

const TreatmentFilter = ({selectedTreatment, onSelect}: any) => {
    const toggleSelected = (name: any) => {
        const labs = [...selectedTreatment];
        const index = labs.indexOf(name);
        if (index === -1) {
            labs.push(name)
        } else {
            labs.splice(index, 1);
        }
        onSelect(labs);
    }

    return <div className="grid grid-cols-12 w-full">
        <div className="md:col-span-2 col-span-12 flex flex-col justify-center gap-1">
            <span className="font-bold">Treatment</span>
        </div>
        <div className="md:col-span-10 col-span-12 flex flex-wrap items-center gap-2">
            {Treatment.map((e: any, i: any) => <Tooltip title={e.name}><div key={i} className={`selection-button-text wide ${selectedTreatment.includes(e.name) ? 'selected' : ""}`} onClick={() => toggleSelected(e.name)}>{e.name}</div></Tooltip>)}
        </div>
    </div>
}

export default TreatmentFilter;