import { Tooltip } from "@mui/material";
import "./SearchFilterPage.css";

export const Filter = ({ options, selectedOptions, onSelect, type, semiWide=false }: any) => {
    const toggleSelected = (name: any) => {
        const clarities = [...selectedOptions];
        const index = clarities.indexOf(name);
        if (index === -1) {
            clarities.push(name)
        } else {
            clarities.splice(index, 1);
        }
        onSelect(clarities);
    }

    const TextFilters = () => <>
        {options.map((e: any, i: any) => <Tooltip title={e.name} key={i}><div key={i} className={`selection-button-text ${selectedOptions.includes(e.value) ? 'selected' : ""} ${semiWide ? 'wide-semi' : ""}`} onClick={() => toggleSelected(e.value)}>{e.name}</div></Tooltip>)}
    </>

    const IconFilters = () => <>
        {options.map((e: any, i: any) => <Tooltip title={e.name} key={i}><div key={i} className={`selection-button ${e.icon} ${selectedOptions.includes(e.value) ? 'selected' : ""}`} onClick={() => toggleSelected(e.value)}></div></Tooltip>)}
    </>

    const IconWitTextFilters = () => <>
        {options.map((e: any, i: any) => (<Tooltip title={e.name} key={i}>
            <div key={i} className={`flex flex-col items-center selection-icon-button ${e.className} ${selectedOptions.includes(e.value) ? 'selected' : ""}`} onClick={() => toggleSelected(e.value)}>
                <span className={`icon ${e.icon}`}></span>
                <span>{e.name}</span>
            </div>
        </Tooltip>))}
    </>

    return <>
        {(!type || type == "text") && <TextFilters />}
        {type == "icon" && <IconFilters />}
        {type == "icon_with_text" && <IconWitTextFilters/> }
    </>
}



