import { EmailOutlined } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import axiosInstance from "../../utils/axiosInstance";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../utils/toastOption";


const SelectedStockSendEmail = ({ selectedRows }: any) => {

    const sendEmail = () => {
        if (Object.keys(selectedRows).length > 0) {
            axiosInstance.post('cart/select', Object.keys(selectedRows)).then((data: any) => {
                toast.success('Email sent successfully', TOAST_OPTIONS);
            }).catch((error) => {
                toast.error('Error while sending email', TOAST_OPTIONS);
            })
        } else {
            toast.error('No Stock selected', TOAST_OPTIONS);
        }
    }

    return <><Tooltip title="Email">
        <Button key="three" disabled={Object.keys(selectedRows).length == 0} size="small" onClick={sendEmail}>
            <EmailOutlined fontSize="small" />
        </Button>
    </Tooltip>
    </>
}

export default SelectedStockSendEmail;