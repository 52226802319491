import { useState } from "react";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import "./SearchFilterPage.css"
import { Filter } from "./Filter";
import { FANCY_COLORS, INTENSITY, OVERTONE, WHITE_COLORS } from "./FiltersList";

const ColorFilter = ({ selectedColorType, onSelectType, selectedWhiteColors, onSelectWhite, selectedFancyColors, onSelectFancy, selectedOvertone, onSelectOvertone, selectedIntensity, onSelectIntensity }: any) => {

    const handleColorChange = (event: any, newColor: string) => {
        onSelectType(newColor || "white");
    }

    return <div className="grid grid-cols-12 w-full">
        <div className="md:col-span-2 col-span-12 flex flex-col justify-start gap-1">
            <span className="font-bold">Color</span>
            <div className="pb-2">
                <ToggleButtonGroup
                    color="primary"
                    value={selectedColorType}
                    exclusive
                    onChange={handleColorChange}
                    aria-label="Platform"
                    size="small"
                    className="toggle-filter"

                >
                    <ToggleButton value="white">White</ToggleButton>
                    <ToggleButton value="fancy">Fancy</ToggleButton>
                </ToggleButtonGroup>
            </div>
        </div>
        <div className="md:col-span-10 col-span-12 flex flex-wrap items-center gap-2">
            {

                selectedColorType === "white" && <Filter options={WHITE_COLORS} selectedOptions={selectedWhiteColors} onSelect={onSelectWhite} />
            }

            {
                selectedColorType === "fancy" && <div className="grid grid-cols-12 gap-4 w-full ">
                    <div className="md:col-span-6 xl:col-span-4 col-span-12 flex flex-col gap-2">
                        <div className="font-semibold">Color</div>
                        <div className="flex flex-wrap gap-2 h-fit">
                            <Filter options={FANCY_COLORS} selectedOptions={selectedFancyColors} onSelect={onSelectFancy} type="icon_with_text" />
                        </div>
                    </div>
                    <div className="md:col-span-6 xl:col-span-4 col-span-12 flex flex-col gap-2">
                        <div className="font-semibold">Overtone</div>
                        <div className="flex flex-wrap gap-2 h-fit">
                            <Filter options={OVERTONE} selectedOptions={selectedOvertone} onSelect={onSelectOvertone} semiWide={true} />
                        </div>
                    </div>

                    <div className="md:col-span-6 xl:col-span-4 col-span-12 flex flex-col gap-2">
                        <div className="font-semibold">Intensity</div>
                        <div className="flex flex-wrap gap-2 h-fit">
                            <Filter options={INTENSITY} selectedOptions={selectedIntensity} onSelect={onSelectIntensity} semiWide={true} />
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
}

export default ColorFilter;