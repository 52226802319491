import { Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import no_image from "../../images/no_image.png";
import { AddShoppingCartOutlined, Favorite, MoreVertOutlined, Share, ShareOutlined } from "@mui/icons-material";
import { useState } from "react";
import { Link } from "react-router-dom";

const StockGrid = (props: any) => {

    const handleLoadMore = () => {
        const p = {
            pageIndex: props.pagination.pageIndex + 1,
            pageSize: props.pagination.pageSize
        }
        props.setPagination(p);

    }

    return <>
        {/* <div className="grid col-span-1 sm:grid-cols-2 md:grid-cols-4 2xl:grid-cols-6 gap-4 pb-4"> */}
        <div className="grid col-span-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 pb-4">
            {
                props.stockData.map((e: any) =>
                    <Card variant="outlined" className="flex flex-col col-span-5 sm:col-span-1">
                        <CardMedia component="img" alt="No Image available" height="140" image={e.imageLink || no_image} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = no_image;
                        }} />
                        {/* <CardMedia component="iframe" height="140" src={e.imageLink || e.videoLink} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = no_image;
                        }} style={{width: "100%", height: "100%"}}/> */}
                        <CardContent className="flex flex-col" style={{ paddingBottom: "0px" }}>
                            <div className="w-full grid grid-cols-2 text-sm font-semibold mb-4">
                                <div className="truncate col-span-1">
                                    {e.lab}:&nbsp;
                                    <span className="text-slate-400">{e.certificateNo}</span>
                                </div>

                                <div className="truncate col-span-1 text-right">
                                    Stock no:&nbsp;
                                    <span className="text-slate-400">{e.stockNo}</span>
                                </div>
                            </div>


                            {e.colorType == "white" && <div className="flex flex-col mb-2">
                                <span className="font-bold uppercase truncate">{e.shape} </span>
                                <span>Color: <span className="font-bold">{e.color}</span></span>
                            </div>}

                            {e.colorType == "fancy" && <div className="flex flex-col mb-2">
                                <span className="font-bold uppercase truncate">{e.shape} </span>
                                <span>{e.color}</span>
                            </div>}

                            <Divider />

                            <div className="grid grid-cols-6 text-sm gap-3 mt-3">
                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Weight</span>
                                    <span className="text-slate-500">{e.weight}</span>
                                </div>

                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Clarity</span>
                                    <span className="text-slate-500">{e.clarity}</span>
                                </div>

                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Cut</span>
                                    <span className="text-slate-500">{e.cutGrade || "-"}</span>
                                </div>

                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Polish</span>
                                    <span className="text-slate-500">{e.polish || "-"}</span>
                                </div>

                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Symmetry</span>
                                    <span className="text-slate-500">{e.symmetry || "-"}</span>
                                </div>

                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Fluorescence</span>
                                    <span className="text-slate-500">{e.fluorescence || "-"}</span>
                                </div>


                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Depth%</span>
                                    <span className="text-slate-500">{e.depthPercent || "-"}</span>
                                </div>

                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Table%</span>
                                    <span className="text-slate-500">{e.tablePercent || "-"}</span>
                                </div>

                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Ratio</span>
                                    <span className="text-slate-500">{e.ratio || "-"}</span>
                                </div>

                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Location</span>
                                    <span className="text-slate-500">{e.location || "-"}</span>
                                </div>

                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Growth</span>
                                    <span className="text-slate-500">{e.type || "-"}</span>
                                </div>

                                <div className="flex flex-col  col-span-2">
                                    <span className="font-bold">Measurements</span>
                                    <span className="text-slate-500 truncate">{e.measurments || "-"}</span>
                                </div>

                            </div>
                        </CardContent>
                        <CardActions disableSpacing>
                            <div className="flex flex-col w-full">
                                <Divider />
                                <div className="flex justify-between items-center">
                                    <div className="flex flex-col py-1">
                                        <div className="ml-2 mt-2">
                                            <span className="font-bold" style={{ fontSize: "18px", lineHeight: "18px" }}> {`$ ${e.totalPrice ? e.totalPrice.toFixed(2) : ''}`} </span>
                                            {e.discount && <span className="text-red-500" style={{ fontSize: "12px", lineHeight: "16px" }}>
                                                {`(${e.discount.toFixed(2)}% off)`}
                                            </span>}
                                        </div>
                                        <div>
                                            {e.perCaratPrice && <span className="ml-2 text-slate-500" style={{ fontSize: "12px", lineHeight: "12px" }}>
                                                {`($ ${e.perCaratPrice.toFixed(2)}/CT)`}
                                            </span>}
                                        </div>
                                    </div>

                                    <Link to={`/stock/${e._id}`} target="blank">
                                        <Button size="small">View</Button>
                                    </Link>

                                </div>
                            </div>
                        </CardActions>

                    </Card>)
            }
        </div>
        {(props.pagination.pageIndex * props.pagination.pageSize) < props.totalCount && <div className="flex justify-center pb-4">
            <Button variant="outlined" onClick={handleLoadMore}>Load More {props.loading && <>&nbsp;<CircularProgress size={20} /></>}</Button>
        </div>}
    </>
}

export default StockGrid;