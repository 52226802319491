import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider, createMuiTheme, createTheme } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import { Routes } from './Routes';
import { ToastContainer } from 'react-toastify';

// Create a custom theme
const theme = createTheme({
  typography: {
    fontFamily: `"Nunito Sans", sans-serif;`,
  },
  palette: {
    primary: {
      main: "#1A4B71"
    },
  },
});

function App() {
  const router = createBrowserRouter([
    ...Routes,
  ]);

  return <>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
    <ToastContainer />
  </>
}

export default App;
