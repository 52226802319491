import React from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import { DownloadForOfflineOutlined, DownloadOutlined, FileDownloadDoneOutlined, FileDownloadOutlined } from '@mui/icons-material';
import axiosInstance from '../../utils/axiosInstance';

const API_URL = process.env.REACT_APP_BASE_URL!;

const DownloadExcelButton = (props: any) => {
	const handleDownload = async () => {
		try {
			const params = {
				report_type: props.reportType,
			};
			const response = await axiosInstance.get(`${API_URL}excel`, {
				responseType: 'blob',
				params: params,
			});
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', props.fileName + '.xlsx');
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			console.error('Error downloading Excel file:', error);
		}
	};

	return <Tooltip title="Download Excel">
		<Button onClick={handleDownload} size={props.size || "small"}>
			<FileDownloadOutlined fontSize={props.size || "small"}/>
		</Button>
	</Tooltip>
};

export default DownloadExcelButton;
