import { Tooltip } from "@mui/material";


const TypeList = [
    { name: "HPHT" },
    { name: "CVD" },
    { name: "Other" },
]

const TypeFilter = ({selectedType, onSelect}: any) => {
    const toggleSelected = (name: any) => {
        const clarities = [...selectedType];
        const index = clarities.indexOf(name);
        if (index === -1) {
            clarities.push(name)
        } else {
            clarities.splice(index, 1);
        }
        onSelect(clarities);
    }
    return <div className="grid grid-cols-12 w-full">
        <div className="md:col-span-2 col-span-12 flex flex-col justify-center gap-1">
            <span className="font-bold">Growth Type</span>
        </div>
        <div className="md:col-span-10 col-span-12 flex items-center gap-2">
            {TypeList.map((e: any, i: any) => <Tooltip title={e.name} key={i}><div key={i} className={`selection-button-text wide ${selectedType.includes(e.name) ? 'selected' : ""}`} onClick={() => toggleSelected(e.name)}>{e.name}</div></Tooltip>)}
        </div>
    </div>
}

export default TypeFilter;