import { Email, HomeOutlined } from "@mui/icons-material";
import { Button, Card, CardContent, CircularProgress, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import NotificationMessage from "../../components/common/notification/notification";
import logo from "../../images/logo_dark_v.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";

const API_URL = process.env.REACT_APP_BASE_URL!;
const ForgotPasswordPage = () => {
	const navigate = useNavigate();
	const { control, watch, handleSubmit } = useForm({ mode: "all" });
	const [email, setEmail] = useState("");
	const [formIsValid, setFormIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [notificationDetails, setNotificationDetails] = useState({
		type: "",
		message: ""
	})
	const [loading, setLoading] = useState(false);
	const emailChangeHandler = (event: any) => {
		setEmail(event.target.value);
	};

	useEffect(() => {
		const debounce = setTimeout(() => {
			setFormIsValid(email != "");
		}, 100)
		return () => {
			clearTimeout(debounce);
		}
	}, [
		email
	])

	const onSubmit = (data: any) => {
		setLoading(true);
		setErrorMessage("");
		axios.post(`${API_URL}users/resetPasswordRequest`, data).then((response: any) => {
			setNotificationDetails({
				type: "success",
				message: "A reset link has been sent to your email address."
			})
			setLoading(false);
			setOpenSnackbar(true);
		}).catch((error: any) => {
			setLoading(false);
			setNotificationDetails({
				type: "error",
				message: error.response.data.message
			})
			setOpenSnackbar(true);
		})
	}

	return <>
		<div className="flex flex-col items-center justify-center min-h-screen gap-6 p-4 auth-bg" >
			<div className='flex flex-row justify-center items-center gap-4'>
				<Link to={"/home"}>
					<img src={logo} alt="Logo" style={{ height: "100px" }} />
				</Link>
			</div>
			<Card variant="elevation" className="md:w-1/3 w-full">
				<CardContent className="flex flex-col gap-6 justify-center items-center">
					<div className="flex flex-col items-center mb-5">
						<Typography variant="h5" style={{ fontWeight: "700" }}>Forgot Password</Typography>
						<Typography variant="caption">
							Enter the email address associated with your account and we'll send you a link to reset your password.
						</Typography>
					</div>

					{/* <TextField id="outlined-basic" required fullWidth label="email" variant="outlined" onChange={emailChangeHandler}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Email />
								</InputAdornment>
							),
						}} /> */}

					<form className="w-full" onSubmit={handleSubmit(onSubmit)}>
						<Controller name="email"
							control={control}
							rules={{
								required: {
									value: true,
									message: "Email is required"
								},
								pattern: {
									value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
									message: 'Invalid email address',
								},
							}}
							render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" fullWidth label="Email" variant="outlined" size="small" className="col-span-12 sm:col-span-6"
								helperText={error ? error.message : ""}
								error={!!error}
							/>)} />

						<div className="col-span-12 flex flex-col mt-6 gap-4">
							<div className="grow flex gap-4">
								{loading && <CircularProgress size={30} />}
								<Button variant="contained" className="grow" type="submit" disabled={loading}>CONTINUE</Button>
							</div>
							<div className="flex gap-4">
								<Tooltip title="Go to Homepage">
									<IconButton color='primary' onClick={() => { navigate("/home") }}>
										<HomeOutlined />
									</IconButton>
								</Tooltip>
								<Button variant="outlined" className="grow" onClick={() => { navigate("/login") }}>Back to Login</Button>
							</div>
						</div>

						{/* <Button disabled={!formIsValid || loading} sx={{ marginBottom: 1 }} size="medium" fullWidth variant="contained">SUBMIT {loading && <>&nbsp;<CircularProgress size={20} /></>}</Button> */}
					</form>

					{/* <div className="w-full flex gap-4">
						<Tooltip title="Go to Homepage">
							<IconButton color='primary' onClick={() => { navigate("/home") }}>
								<HomeOutlined />
							</IconButton>
						</Tooltip>
						<Button fullWidth onClick={() => { navigate("/login") }} variant='outlined'>BACK TO LOGIN</Button>
					</div> */}
				</CardContent>
			</Card>
		</div>
		<NotificationMessage isOpen={openSnackbar} type={notificationDetails.type} message={notificationDetails.message} handleClose={() => setOpenSnackbar(false)}></NotificationMessage>
	</>
}

export default ForgotPasswordPage;