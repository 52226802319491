import { TextField } from "@mui/material";

const RatioFilter = ({ratioFrom, ratioTo, onChangeRatioFrom, onChangeRatioTo}: any) => {
    return <div className="grid grid-cols-12">
        <div className="md:col-span-2 col-span-12 flex items-center font-bold">Ratio</div>
        <div className="md:col-span-10 col-span-12 flex flex-wrap gap-3">
            <TextField type="number" id="outlined-basic" label="From" variant="outlined" size="small" value={ratioFrom} defaultValue={ratioFrom} inputProps={{step: "0.01"}} onChange={e => onChangeRatioFrom(e.target.value)}/>
            <TextField type="number" id="outlined-basic" label="To" variant="outlined" size="small" value={ratioTo} defaultValue={ratioTo} inputProps={{step: "0.01"}} onChange={e => onChangeRatioTo(e.target.value)}/>
        </div>
    </div>
}

export default RatioFilter;