import { useEffect, useState } from "react";
import { Button, ButtonGroup, Tooltip } from "@mui/material";

import axiosInstance from "../../../utils/axiosInstance";
import StockList from "../../../components/StockList/StockList";
import isAuth from "../../../components/IsAuth/IsAuth";
import ToggleData from "../../../components/ToggleData/ToggleData";
import StockUpload from "../../../components/StockUpload/StockUpload";
import DownloadExcelButton from "../../../components/DownloadExcel/DownloadExcel";
import CompareStocks from "../../../components/CompareStocks/CompareStocks";
import AddToCart from "../../../components/AddToCart/AddToCart";
import ShareStocks from "../../../components/ShareStocks/ShareStocks";
import { Inventory2Outlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import SelectedStockSendEmail from "../../../components/SelectedStockSendEmail/SelectedStockSendEmail";
import useLevel from "../../../hooks/useLevel";

const CheckPermission = ({ children }: any) => {
    const { level } = useLevel();
    if (level !== "admin_user") return children;
    return null;
}

const StockPage = () => {
    const [displayType, setDisplayType] = useState("table");
    const [stockData, setStockData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 200,
    });
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState<any>({});
    const [selectedRowsData, setSelectedRowsData] = useState<any>([]);
    const [searchValue, setSearchValue] = useState<any>("");
    const [sorting, setSorting] = useState<any>([]);


    const getCompanies = () => {
        axiosInstance.get(`company`).then((data: any) => {
            setCompanyData(data.data);
        })
    }

    const getStocksData = () => {
        setLoading(true);
        const query: any = {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
        }
        if (searchValue) {
            query.q = searchValue
        }

        if (sorting.length) {
            query.sort = sorting[0].id;
            query.desc = sorting[0].desc
        }
        axiosInstance.get(`stocks`, {
            params: query,
        }).then((response: any) => {
            // const data: any = [...stockData, ...response.data.data];
            // setStockData(data);
            setStockData(response.data.data);
            setTotalCount(response.data.recordsCount);
            setLoading(false);
        }).catch(e => {
            console.log(e);
            setLoading(false);
        })
    }


    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 200,
        })
    }, [searchValue, sorting])


    useEffect(() => {
        getStocksData();
    }, [pagination])

    useEffect(() => {
        const rowsId = Object.keys(selectedRows);
        if (rowsId.length > 0) {
            const rowsData: any = [];
            rowsId.forEach(id => {
                const row = stockData.find((d: any) => d._id === id)
                rowsData.push(row);
            })
            setSelectedRowsData(rowsData);
        } else {
            setSelectedRowsData([]);
        }
    }, [selectedRows])

    useEffect(() => {
        getCompanies();
    }, []);

    const buttons = [
        <StockUpload companyData={companyData} />,
        <DownloadExcelButton reportType='stocks' fileName="StockDetails" size="small"></DownloadExcelButton>,
        <CheckPermission><CompareStocks selectedRowData={selectedRowsData} /></CheckPermission>,
        <CheckPermission><AddToCart selectedRowsData={selectedRowsData} /></CheckPermission>,
        <CheckPermission><ShareStocks selectedRowData={selectedRowsData} /></CheckPermission>,
        <CheckPermission>
            <Tooltip title="Upload Client File">
                <Link to={"/advstock"}>
                    <Button size="small" className="h-full">
                        <Inventory2Outlined fontSize="small" />
                    </Button>
                </Link>
            </Tooltip>
        </CheckPermission>
    ];

    return <>
        <div className="w-full">
            <div className="p-4 flex flex-col md:flex-row gap-4 md:justify-between md:items-center">
                <span className="font-bold text-3xl">Diamond Stock</span>
                <div className="flex gap-4">
                    <ButtonGroup>
                        {buttons}
                    </ButtonGroup>
                    <ToggleData displayType={displayType} onChange={setDisplayType} />
                </div>
            </div>

            <StockList manualSearch={true} sorting={true} displayType={displayType} stockData={stockData} pagination={pagination} setPagination={setPagination} totalCount={totalCount} loading={loading} onRowsSelect={setSelectedRows} onSearch={setSearchValue} onSort={setSorting}></StockList>


        </div>
    </>
}

export default isAuth(StockPage);
