import { useParams } from "react-router-dom";
import isAuth from "../../../../components/IsAuth/IsAuth";
import SellerDetails from "../../../../components/SellerDetails/SellerDetails";
import axiosInstance from "../../../../utils/axiosInstance";
import { useEffect, useState } from "react";

const EditSellerPage = () => {
    const [sellerData, setSellerData] = useState();

    const params = useParams();
    const getSellerById = () => {
        axiosInstance.get(`seller/${params.id}`).then((data: any) => {
			// const seller = data.data;
			setSellerData(data.data);
		})
    }

    useEffect(() => {
        getSellerById();
    }, [])

    return <SellerDetails mode="edit" sellerData={sellerData}></SellerDetails>
}
export default isAuth(EditSellerPage);