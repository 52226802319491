import { AddShoppingCartOutlined } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import axiosInstance from "../../utils/axiosInstance";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../utils/toastOption";


const AddToCart = ({ selectedRowsData }: any) => {

    const addToCart = () => {
        if (Object.keys(selectedRowsData).length > 0) {
            axiosInstance.post('cart', selectedRowsData).then((data: any) => {
                toast.success('Diamonds added to cart successfully', TOAST_OPTIONS);
            }).catch((error) => {
                toast.error('Error while adding diamonds to cart', TOAST_OPTIONS);
            })
        } else {
            toast.error('No Stock selected', TOAST_OPTIONS);
        }
    }

    return <><Tooltip title="Add to Cart">
        <Button key="three" disabled={Object.keys(selectedRowsData).length == 0} size="small" onClick={addToCart}>
            <AddShoppingCartOutlined fontSize="small" />
        </Button>
    </Tooltip>
    </>
}

export default AddToCart;