import { AppsOutlined, TableViewOutlined } from "@mui/icons-material"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"

const ToggleData = ({displayType, onChange}: any) => {

    const handleDisplayChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        onChange(newAlignment);
    };

    return <ToggleButtonGroup
        value={displayType}
        exclusive
        onChange={handleDisplayChange}
        aria-label="text alignment"
        size="small"
        color="primary"
    >
        <ToggleButton value="table" aria-label="left aligned">
            <TableViewOutlined />
        </ToggleButton>
        <ToggleButton value="grid" aria-label="centered">
            <AppsOutlined />
        </ToggleButton>
    </ToggleButtonGroup>
}

export default ToggleData