"use client";

import { ReactNode, useEffect, useState } from "react";
import axios from "axios";
import { Card, CardContent, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, debounce } from "@mui/material";
import { TextField, Button, } from '@mui/material';
import NotificationMessage from "../common/notification/notification";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";


const ThirdPartyDetails = (props: any) => {
	const navigate = useNavigate();
	const [name, setName] = useState("");
	const [contact_no, setContactNo] = useState("");
	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [seller_id, setSellerId] = useState("");
	const [user_id, setUserId] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false)
	const [isActive, setSelectedStatus] = useState(false)
	const [isChecking, setIsChecking] = useState(false);
	const [usernameExists, setUsernameExists] = useState(false);
	const [notificationDetails, setNotificationDetails] = useState({
		type: "",
		message: ""
	})

	useEffect(() => {
		if (props.mode == 'edit') {
			setName(props.thirdPartyData?.name);
			setContactNo(props.thirdPartyData?.contact_no);
			setEmail(props.thirdPartyData?.email);
			setUsername(props.thirdPartyData?.user.username);
			setSelectedStatus(props.thirdPartyData?.user.isActive);
		}
	}, [props.thirdPartyData]);

	const nameChangeHandler = (event: any) => {
		setName(event.target.value);
	};

	const contactNoChangeHandler = (event: any) => {
		setContactNo(event.target.value);
	};

	const emailChangeHandler = (event: any) => {
		setEmail(event.target.value);
	};

	const checkUsername = async (username: any) => {
		try {
			setIsChecking(true);
			const response = await axiosInstance.get(`users/${username}`);
			setUsernameExists(response.data);
		} catch (error) {
			console.error('Error checking username:', error);
		} finally {
			setIsChecking(false);
		}
	};

	const debouncedCheckUsername = debounce(checkUsername, 500); // Adjust delay as needed

	const userNameChangeHandler = (event: any) => {
		setUsername(event.target.value);
		debouncedCheckUsername(event.target.value);
	};

	const passwordChangeHandler = (event: any) => {
		setPassword(event.target.value);
	};

	const handleSubmit = async (event: { preventDefault: () => void; }) => {
		event.preventDefault();
		if (props.mode == 'edit') {
			try {
				const response = await axiosInstance.put(`thirdparty/${props.thirdPartyData._id}`, { seller_id, name, contact_no, email, username, password, isActive });
				if (response.status == 200) {
					navigate('/thirdparty');
					setNotificationDetails({
						type: "success",
						message: "Third-Party Vendor Updated Successfully"
					})
					setOpenSnackbar(true);
				}
			} catch (error) {
				console.error('Error updating user:', error);
			}
		} else {
			axiosInstance.post("thirdparty", { "name": name, "contact_no": contact_no, "email": email, "username": username, "password": password }).then(response => {
				setNotificationDetails({
					type: "success",
					message: "Third-Party Vendor Added Successfully"
				})
				setOpenSnackbar(true);
				navigate('/thirdparty');
			}).catch((error: any) => {
				setNotificationDetails({
					type: "error",
					message: error.response.data.message
				})
				setOpenSnackbar(true);
			})
		}
	};

	const handleCancelClick = () => {
		navigate("/thirdparty");
	}

	function handleDropdownChange(event: SelectChangeEvent<any>, child: ReactNode): void {
		setSelectedStatus(event.target.value);
	}

	return <>
		<div className="flex flex-col items-center h-screen pt-5">
			<div className="md:w-1/3 sx:w-full mb-4">
				<Typography variant="h5">
					{props.mode === 'edit' ? 'Edit Third-Party Vendor' : 'Add Third-Party Vendor'}
				</Typography>
			</div>
			<Card variant="elevation" className="md:w-1/3 sx:w-full">
				<CardContent>

					<form autoComplete="off" onSubmit={handleSubmit} className="mt-4 flex flex-col gap-6 justify-center items-center">
						<TextField
							required
							label="Name"
							fullWidth
							value={name}
							variant="outlined"
							name="name"
							size="small"
							onChange={nameChangeHandler}
						/>

						<TextField
							required
							label="Contact No"
							value={contact_no}
							fullWidth
							variant="outlined"
							name="contact_no"
							size="small"
							onChange={contactNoChangeHandler}
						/>

						<TextField
							label="Email"
							fullWidth
							value={email}
							variant="outlined"
							type="email"
							name="email"
							size="small"
							onChange={emailChangeHandler}
						/>



						<TextField
							required
							label="Username"
							fullWidth
							value={username}
							variant="outlined"
							disabled={props.mode === 'edit'}
							name="username"
							size="small"
							onChange={userNameChangeHandler}
							error={usernameExists} // Set error prop to true if usernameExists is true
							helperText={usernameExists ? 'Username already exists' : null} // Display error message if usernameExists is true
						/>

						{props.mode !== 'edit' && (
							<TextField
								required
								label="Password"
								fullWidth
								value={password}
								variant="outlined"
								type="password"
								name="password"
								size="small"
								onChange={passwordChangeHandler}
							/>
						)}

						{props.mode == 'edit' && (
							<FormControl fullWidth required size="small">
								<InputLabel id="dropdown-label">Account Status</InputLabel>
								<Select
									labelId="dropdown-label"
									id="dropdown"
									label="Select Company"
									value={isActive}
									onChange={handleDropdownChange}>
									<MenuItem value="">Account Status</MenuItem>
									<MenuItem value="true">Active</MenuItem>
									<MenuItem value="false">In Active</MenuItem>
								</Select>
							</FormControl>
						)}

						<div className="flex flex-row gap-4 justify-start w-full">
							<Button variant="contained" color="primary" type="submit" className="grow">
								{props.mode === 'Edit' ? 'Update' : 'Submit'}
							</Button>
							<Button variant="outlined" color="primary" type="button" className="grow" onClick={handleCancelClick}>
								Cancel
							</Button>
						</div>
					</form>

				</CardContent>
			</Card>
		</div>
		<NotificationMessage isOpen={openSnackbar} type={notificationDetails.type} message={notificationDetails.message} handleClose={() => setOpenSnackbar(false)}></NotificationMessage>
	</>
}
export default ThirdPartyDetails;

