import useViewport from "../../hooks/useViewport";
import LandingPageMobile from "../LandingPageMobile/LandingPageMobile";
import LandingPageParallax from "../LandingPageParallax/LandingPageParallax"

const HomePage = () => {
    const {width} = useViewport();

    return <>
        {width > 700 ? <LandingPageParallax/> : <LandingPageMobile/>}
    </>
}

export default HomePage;