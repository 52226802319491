import { DashboardOutlined, SearchOutlined, ShoppingCartOutlined } from "@mui/icons-material";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const CustomerSidenav = (props: any) => {
    const { pathname } = useLocation();
    return <>
        <List>
            <Tooltip placement={props.open? "bottom":"right"} title="Dashboard">
                <Link to="/client/dashboard">
                    <ListItem key="dashboard" selected={pathname.toLowerCase().includes("dashboard")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <DashboardOutlined color={!props.open && pathname.toLowerCase().includes("dashboard") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Dashboard</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>

            <Tooltip placement={props.open? "bottom":"right"} title="Search Diamond">
                <Link to="/client/search">
                    <ListItem key="search" selected={pathname.toLowerCase().includes("search") || pathname.toLowerCase().includes("client/result")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <SearchOutlined color={!props.open && (pathname.toLowerCase().includes("search") || pathname.toLowerCase().includes("client/result")) ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Search</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>

            <Tooltip placement={props.open? "bottom":"right"} title="My Cart">
                <Link to="/client/cart">
                    <ListItem key="cart" selected={pathname.toLowerCase().includes("cart")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <ShoppingCartOutlined color={!props.open && pathname.toLowerCase().includes("cart") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">My Cart</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>
        </List>
    </>
}

export default CustomerSidenav;