"use client";

import { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardContent, TextareaAutosize, Typography } from "@mui/material";
import { TextField, Button, } from '@mui/material';
import NotificationMessage from "../common/notification/notification";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_BASE_URL!;

const CompanyDetails = (props: any) => {
	const navigate = useNavigate();
	const [company_name, setCompanyName] = useState("");
	const [company_code, setCompanyCode] = useState("");
	const [contact_no, setContactNo] = useState("");
	const [email, setEmail] = useState("");
	const [address, setAddress] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false)
	const [notificationDetails, setNotificationDetails] = useState({
		type: "",
		message: ""
	})

	useEffect(() => {
		if (props.mode == 'edit') {
			setCompanyName(props.companyData?.company_name);
			setCompanyCode(props.companyData?.company_code);
			setContactNo(props.companyData?.contact_no);
			setEmail(props.companyData?.email);
			setAddress(props.companyData?.address);
		}
	}, [props.companyData]);

	const companyNameChangeHandler = (event: any) => {
		setCompanyName(event.target.value);
	};

	const companyCodeChangeHandler = (event: any) => {
		setCompanyCode(event.target.value);
	};

	const contactNoChangeHandler = (event: any) => {
		setContactNo(event.target.value);
	};

	const emailChangeHandler = (event: any) => {
		setEmail(event.target.value);
	};

	const addressChangeHandler = (event: any) => {
		setAddress(event.target.value);
	};

	const handleSubmit = async (event: { preventDefault: () => void; }) => {
		event.preventDefault();
		if (props.mode == 'edit') {
			try {
				const response = await axiosInstance.put(`${API_URL}company/${props.companyData._id}`, { company_name, company_code, contact_no, email, address });
				if (response.status == 200) {
					navigate('/companies');
					setNotificationDetails({
						type: "success",
						message: "Company Updated Successfully"
					})
					setOpenSnackbar(true);
				}
			} catch (error) {
				console.error('Error updating company:', error);
			}
		} else {
			axiosInstance.post("company", { "company_name": company_name, "company_code": company_code, "contact_no": contact_no, "email": email, "address": address }).then((res: any) => {
				setNotificationDetails({
					type: "success",
					message: "Company Added Successfully"
				})
				setOpenSnackbar(true);
				navigate('/companies');
			}).catch((error: any) => {
				setNotificationDetails({
					type: "error",
					message: error.response.data.message
				})
				setOpenSnackbar(true);
			})
		}
	};

	const handleCancelClick = () => {
		navigate("/companies");
	}

	return <>
		<div className="flex flex-col items-center h-screen pt-5">
			<div className="md:w-1/3 sx:w-full mb-4">
				<Typography variant="h5">
					{props.mode === 'edit' ? 'Edit Company' : 'Add Company'}
				</Typography>
			</div>
			<Card variant="elevation" className="md:w-1/3 sx:w-full">
				<CardContent>

					<form autoComplete="off" onSubmit={handleSubmit} className="mt-4 flex flex-col gap-6 justify-center items-center">
						<TextField
							required
							label="Company Name"
							fullWidth
							disabled={props.mode === 'edit' ? true : false}
							value={company_name}
							variant="outlined"
							name="company_name"
							size="small"
							onChange={companyNameChangeHandler}
						/>

						<TextField
							required
							label="Company Code"
							fullWidth
							disabled={props.mode === 'edit' ? true : false}
							value={company_code}
							variant="outlined"
							name="company_code"
							size="small"
							onChange={companyCodeChangeHandler}
						/>

						<TextField
							required
							label="Contact No"
							value={contact_no}
							fullWidth
							variant="outlined"
							name="contact_no"
							size="small"
							onChange={contactNoChangeHandler}
						/>

						<TextField
							label="Email"
							fullWidth
							value={email}
							variant="outlined"
							type="email"
							name="email"
							size="small"
							onChange={emailChangeHandler}
						/>

						<TextField
							label="Address"
							fullWidth
							value={address}
							variant="outlined"
							type="text"
							name="address"
							size="small"
							onChange={addressChangeHandler}
							multiline
							InputProps={{
								rows: 3, // Number of visible rows
							}}
						/>

						<div className="flex flex-row gap-4 justify-start w-full">
							<Button variant="contained" color="primary" type="submit" className="grow">
								{props.mode === 'Edit' ? 'Update' : 'Submit'}
							</Button>
							<Button variant="outlined" color="primary" type="button" className="grow" onClick={handleCancelClick}>
								Cancel
							</Button>
						</div>
					</form>

				</CardContent>
			</Card>
		</div>
		<NotificationMessage isOpen={openSnackbar} type={notificationDetails.type} message={notificationDetails.message} handleClose={() => setOpenSnackbar(false)}></NotificationMessage>
	</>
}
export default CompanyDetails;