import { useState } from "react";
import { Tooltip } from "@mui/material";
import "./SearchFilterPage.css";

const FlourList = [
    { name: "None" },
    { name: "Very Slight" },
    { name: "Faint/Slight" },
    { name: "Medium" },
    { name: "Strong" },
    { name: "Very Strong" },
]

const FlourescenceFilter = ({ selectedFlour, onSelect }: any) => {


    const toggleSelected = (name: any) => {
        const flours = [...selectedFlour];
        const index = flours.indexOf(name);
        if (index === -1) {
            flours.push(name)
        } else {
            flours.splice(index, 1);
        }
        onSelect(flours);
    }

    return <div className="grid grid-cols-12 w-full">
        <div className="md:col-span-2 col-span-12 flex flex-col justify-center gap-1">
            <span className="font-bold">Flourescence</span>
        </div>
        <div className="md:col-span-10 col-span-12 flex flex-wrap items-center gap-2">
            {FlourList.map((e: any, i: any) => <Tooltip title={e.name} key={i}><div key={i} className={`selection-button-text wide ${selectedFlour.includes(e.name) ? 'selected' : ""}`} onClick={() => toggleSelected(e.name)}>{e.name}</div></Tooltip>)}
        </div>
    </div>
}

export default FlourescenceFilter;