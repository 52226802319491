import { Card } from "@mui/material";
import isAuth from "../../../components/IsAuth/IsAuth";
import { Link } from "react-router-dom";
import { SavedSearch, SupervisedUserCircleOutlined } from "@mui/icons-material";

const SellerDashboardPage = () => {

    return <div className="py-5">
        <div>
            <span className="font-bold text-3xl px-4">Dashboard</span>
        </div>

        <div className="grid grid-cols-12 gap-5 mt-5">
            <Link to="/seller/saved-search" className="md:col-span-6 col-span-12">
                <Card variant="outlined" className="flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                        <SavedSearch />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-gray-700">
                            Saved Searches
                        </p>
                    </div>
                </Card>
            </Link>

            <Link to="/seller/clients" className="md:col-span-6 col-span-12">
                <Card variant="outlined" className="flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full">
                        <SupervisedUserCircleOutlined />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-gray-700">
                            Assigned Clients
                        </p>
                    </div>
                </Card>
            </Link>
        </div>
    </div>
}

export default isAuth(SellerDashboardPage);