import { BalanceOutlined, CompareArrowsOutlined } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../utils/toastOption";

const CompareStocks = ({ selectedRowData }: any) => {
    const [open, setOpen] = useState(false);

    const openCompareModal = () => {
        if (selectedRowData.length > 1 && selectedRowData.length <= 3) {
            setOpen(true)
        } else if (selectedRowData.length > 3) {
            toast.error('Maximum 3 diamonds are allowed for comparison', TOAST_OPTIONS);
        } else {
            toast.error('Please select atleast 2 diamonds for comparison', TOAST_OPTIONS);
        }
    }

    return <>
        <Tooltip title="Compare Stock">
            <Button key="two" size="small" onClick={openCompareModal} disabled={selectedRowData.length == 0}>
                <CompareArrowsOutlined fontSize="small" />
            </Button>
        </Tooltip>

        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
            <DialogTitle>Compare Diamonds</DialogTitle>
            <Divider />
            <DialogContent>

                <Box sx={{ flexGrow: 1 }}>
                    <div className='flex flax-row gap-4'>
                        {selectedRowData.map((item: any, index: any) => (
                            < div className='grow' key={index} >
                                < Paper elevation={5} >
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="comparison table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                                                        <strong><img src={item.imageLink} alt="" style={{ height: 'auto', marginBottom: '16px', borderRadius: '8px' }} /></strong>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Stock No:</strong></TableCell>
                                                    <TableCell>{item.stockNo}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Lab:</strong></TableCell>
                                                    <TableCell>{item.lab}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Report No:</strong></TableCell>
                                                    <TableCell>{item.certificateNo}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Shape:</strong></TableCell>
                                                    <TableCell>{item.shape}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Size:</strong></TableCell>
                                                    <TableCell>{item.weight}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Color:</strong></TableCell>
                                                    <TableCell>{item.color}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Clarity:</strong></TableCell>
                                                    <TableCell>{item.clarity}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Cut:</strong></TableCell>
                                                    <TableCell>{item.cutGrade}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Polish:</strong></TableCell>
                                                    <TableCell>{item.polish}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Symm:</strong></TableCell>
                                                    <TableCell>{item.symmetry}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Fluorescence:</strong></TableCell>
                                                    <TableCell>{item.fluorescence}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Measurements:</strong></TableCell>
                                                    <TableCell>{item.measurments}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Depth:</strong></TableCell>
                                                    <TableCell>{item.depthPercent}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Table:</strong></TableCell>
                                                    <TableCell>{item.tablePercent}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Ratio:</strong></TableCell>
                                                    <TableCell>{item.ratio}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Location:</strong></TableCell>
                                                    <TableCell>{item.Location}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Discount:</strong></TableCell>
                                                    <TableCell>{item.discount}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><strong>Amount:</strong></TableCell>
                                                    <TableCell>$ {item.totalPrice}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        ))}
                    </div>
                </Box>
            </DialogContent >
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">Close</Button>
            </DialogActions>
        </Dialog >

    </>
}

export default CompareStocks;