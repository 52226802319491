"use client";
import { useEffect, useState } from 'react';
import { TextField, Button, InputAdornment, IconButton, Card, CardContent, Typography, CircularProgress, Tooltip, FormControlLabel, Checkbox, Divider, Box } from '@mui/material';
import { Visibility, VisibilityOff, AccountCircle, HomeOutlined } from '@mui/icons-material';
// import logo from "../../images/logo-white.svg";
import logo from "../../images/logo_dark_v.png";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../utils/toastOption";

const API_URL = process.env.REACT_APP_BASE_URL!;
const LoginPage = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [notificationDetails, setNotificationDetails] = useState({
        type: "",
        message: ""
    })
    const [loading, setLoading] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const userNameChangeHandler = (event: any) => {
        setUsername(event.target.value);
    };

    const passwordChangeHandler = (event: any) => {
        setPassword(event.target.value);
    };

    useEffect(() => {
        // Check if user data is saved in local storage
        const savedUsername = localStorage.getItem('savedUsername');
        const savedPassword = localStorage.getItem('savedPassword');
        if (savedUsername && savedPassword) {
            setUsername(savedUsername);
            setPassword(savedPassword);
            setRememberMe(true);
        }
    }, [])

    useEffect(() => {
        const debounce = setTimeout(() => {
            setFormIsValid(username != "" && password != "");
        }, 100)
        return () => {
            clearTimeout(debounce);
        }
    }, [
        username, password
    ])

    const loginHandler = async () => {
        if (rememberMe) {
            localStorage.setItem('savedUsername', username);
            localStorage.setItem('savedPassword', password);
        } else {
            localStorage.removeItem('savedUsername');
            localStorage.removeItem('savedPassword');
        }

        setLoading(true);
        setErrorMessage("");
        axios.post(`${API_URL}auth/login`, { username, password }).then((response: any) => {
            if (response.data) {
                localStorage.setItem("access_token", response.data.access_token);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("user", response.data.name);
                localStorage.setItem("level", response.data.level);

                switch (response.data.level) {
                    case "super_admin":
                    case "admin":
                        navigate('/dashboard');
                        break;
                    case "admin_user":
                        navigate('/stock');
                        break;
                    case "seller":
                        navigate('/seller/dashboard');
                        break;
                    case "client":
                        navigate('/client/dashboard');
                        break;
                }
                setLoading(false);
            }
        }).catch((error: any) => {
            setLoading(false);
            // setNotificationDetails({
            //     type: "error",
            //     message: error.response.data.message
            // })
            // setOpenSnackbar(true);
            toast.error(error.response.data.message, TOAST_OPTIONS);
        })
    }

    return (
        <>
            <div className="flex flex-col items-center justify-center min-h-screen gap-6 p-4 auth-bg" >
                <div className='flex flex-row justify-center items-center gap-4'>
                    <Link to={"/home"}>
                        <img src={logo} alt="Logo" style={{ height: "100px" }} />
                    </Link>
                </div>
                <Card variant="elevation" className="md:w-1/2 w-full">
                    <CardContent className="flex flex-col gap-6 justify-center items-center">
                        <div className="flex flex-col items-center mb-5">
                            <Typography variant="h5" style={{ fontWeight: "700" }}>Sign In to Account</Typography>
                            <Typography variant="caption">
                                Use your username and password to sign in
                            </Typography>
                        </div>

                        <TextField id="outlined-basic" required fullWidth label="Username OR Email" variant="outlined" value={username} onChange={userNameChangeHandler}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" required fullWidth label="Password" variant="outlined" value={password} type={showPassword ? 'text' : 'password'}
                            onChange={passwordChangeHandler}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />


                        <div className='flex w-full items-center'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Remember Me"
                                className="grow"
                            />
                            <Divider orientation="vertical" flexItem sx={{ mx: 6 }}></Divider>
                            <Link to="/forgot-password" style={{ textDecoration: 'none', color: 'primary' }} className='grow text-right'>
                                <span>Forgot Your Password?</span>
                            </Link>
                        </div>

                        {
                            errorMessage && <Typography variant="body1" color="error">
                                {errorMessage}
                            </Typography>
                        }

                        <Button disabled={!formIsValid || loading} sx={{ marginBottom: 1 }} size="medium" fullWidth variant="contained" onClick={loginHandler}>LOGIN {loading && <>&nbsp;<CircularProgress size={20} /></>}</Button>
                        {/* <Button disabled={loading} sx={{ marginBottom: 1 }} size="medium" fullWidth variant="contained" onClick={loginHandler}>LOGIN {loading && <>&nbsp;<CircularProgress size={20} /></>}</Button> */}

                        <div className="w-full flex gap-4">
                            <Tooltip title="Go to Homepage">
                                <IconButton color='primary' onClick={() => { navigate("/home") }}>
                                    <HomeOutlined />
                                </IconButton>
                            </Tooltip>
                            {/* <Button fullWidth onClick={() => { navigate("/forgot-password") }} variant='outlined'>Forgot Password?</Button> */}
                            <Button fullWidth onClick={() => { navigate("/register") }} variant='outlined'>Register</Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
            {/* <NotificationMessage isOpen={openSnackbar} type={notificationDetails.type} message={notificationDetails.message} handleClose={() => setOpenSnackbar(false)}></NotificationMessage> */}
        </>
    );
}


export default LoginPage;
