import { AdminPanelSettingsOutlined, CorporateFareOutlined, DashboardOutlined, GroupOutlined, Inventory2Outlined, InventoryOutlined, LocationSearchingOutlined, ManageAccounts, ManageAccountsOutlined, People, PeopleAltRounded, PeopleOutlineOutlined, SavedSearch, SearchOutlined, SupervisedUserCircleOutlined } from "@mui/icons-material";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import useLevel from "../../../hooks/useLevel";

const AdminSidenav = (props: any) => {
    const { pathname } = useLocation();
    const { level } = useLevel();
    return <>
        <List>
            {level == "admin" && <Tooltip placement={props.open ? "bottom" : "right"} title="Dashboard">
                <Link to="/dashboard">
                    <ListItem key="dashboard" selected={pathname.toLowerCase().includes("dashboard")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <DashboardOutlined color={!props.open && pathname.toLowerCase().includes("dashboard") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Dashboard</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>}

            {level == "admin" && <Tooltip placement={props.open ? "bottom" : "right"} title="Search Diamond">
                <Link to="search">
                    <ListItem key="search" selected={pathname.toLowerCase().includes("/search") || pathname.toLowerCase().includes("client/result")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <SearchOutlined color={!props.open && (pathname.toLowerCase().includes("/search") || pathname.toLowerCase().includes("client/result")) ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Search</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>}

            <Tooltip placement={props.open ? "bottom" : "right"} title="Stock">
                <Link to="/stock">
                    <ListItem key="stock" selected={pathname.toLowerCase().includes("/stock")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <InventoryOutlined color={!props.open && pathname.toLowerCase().includes("/stock") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Stock</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>

            {level == "admin" && <Tooltip placement={props.open ? "bottom" : "right"} title="Admin User">
                <Link to="/admin-user">
                    <ListItem key="admin-user" selected={pathname.toLowerCase().includes("/admin-user")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <ManageAccountsOutlined color={!props.open && pathname.toLowerCase().includes("/admin-user") ? "primary" : "inherit"} />
                                </ListItemIcon >
                                {props.open && <ListItemText className="ml-3">Admin User</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>}

            {/* <Tooltip placement={props.open? "bottom":"right"} title="Client Stock">
                <Link to="/advstock">
                    <ListItem key="stock" selected={pathname.toLowerCase().includes("/advstock")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <Inventory2Outlined color={!props.open && pathname.toLowerCase().includes("/advstock") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Stock Upload</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip> */}

            {level == "admin" && <Tooltip placement={props.open ? "bottom" : "right"} title="Company">
                <Link to="/companies">
                    <ListItem key="companies" selected={pathname.toLowerCase().includes("companies")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <CorporateFareOutlined color={!props.open && pathname.toLowerCase().includes("companies") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Company</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>}

            {level == "admin" && <Tooltip placement={props.open ? "bottom" : "right"} title="Sellers">
                <Link to="/sellers">
                    <ListItem key="sellers" selected={pathname.toLowerCase().includes("sellers")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <GroupOutlined color={!props.open && pathname.toLowerCase().includes("sellers") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Sellers</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>}

            {level == "admin" && <Tooltip placement={props.open ? "bottom" : "right"} title="Clients">
                <Link to="/clients">
                    <ListItem key="clients" selected={pathname.toLowerCase().includes("clients")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <SupervisedUserCircleOutlined color={!props.open && pathname.toLowerCase().includes("clients") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Clients</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>}

            {level == "admin" && <Tooltip placement={props.open ? "bottom" : "right"} title="Thirdparty Vendors">
                <Link to="/thirdparty">
                    <ListItem key="thirdparty" selected={pathname.toLowerCase().includes("thirdparty")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <PeopleAltRounded color={!props.open && pathname.toLowerCase().includes("clients") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Third-Party Vendor</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>}

            {level == "admin" &&<Tooltip placement={props.open ? "bottom" : "right"} title="Web Tracking">
                <List>
                    <Link to="/web-tracking">
                        <ListItem key="webTracking" selected={pathname.toLowerCase().includes("/web-tracking")} disablePadding sx={{ display: 'block' }}>
                            <div className={props.open ? "content" : ""}>
                                <ListItemButton
                                    sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                    <ListItemIcon
                                        sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                        <LocationSearchingOutlined color={!props.open && pathname.toLowerCase().includes("/web-tracking") ? "primary" : "inherit"} />
                                    </ListItemIcon>
                                    {props.open && <ListItemText className="ml-3">Web Tracking</ListItemText>}
                                </ListItemButton>
                            </div>
                        </ListItem>
                    </Link>
                </List>
            </Tooltip>}

            {level == "admin" && <Tooltip placement={props.open ? "bottom" : "right"} title="Saved Search">
                <Link to="saved-search">
                    <ListItem key="search" selected={pathname.toLowerCase().includes("/saved-search")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <SavedSearch color={!props.open && (pathname.toLowerCase().includes("/saved-search")) ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Saved Search</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </Tooltip>}
        </List>
    </>
}

export default AdminSidenav;