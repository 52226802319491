import { useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material"
import { v4 as uuidv4 } from 'uuid';

import { transformCSV, verifyFileFormat } from "../../pages/admin/Stock/StockUploadUtils";
import axiosInstance from "../../utils/axiosInstance";
import { FileUploadOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../utils/toastOption";

const StockUpload = (props: any) => {
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedColorType, setSelectedColorType] = useState('');
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [uploadingFile, setUploadingFile] = useState(false);


    const handleDialogClose = () => {
        setOpenUploadDialog(false);
    }

    const handleUploadChange = async (e: any) => {
        setSelectedFile(e.target.files[0]);
    }

    const handleDropdownChange = (event: any) => {
        setSelectedCompany(event.target.value);
    };

    const handleDropdownColorTypeChange = (event: any) => {
        setSelectedColorType(event.target.value);
    };

    const uploadFileHandler = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setUploadingFile(true)
        const isFileValid = await verifyFileFormat(selectedFile, selectedColorType);
        if (!isFileValid) {
            setUploadingFile(false);
            toast.error("File format is not valid. Please download the sample and fill the data in correct foramt.", TOAST_OPTIONS);
            return;
        }

        const uuid = uuidv4();
        const data: any = await transformCSV(selectedFile, selectedCompany, selectedColorType, uuid);
        if (!data || data.length == 0) {
            setUploadingFile(false)
            toast.error("File is Empty. Please fill the data to upload it", TOAST_OPTIONS);
        } else {
            try {
                const chunkSize = 500;
                for (let i = 0; i < data.length; i += chunkSize) {
                    const chunk = data.slice(i, i + chunkSize);
                    await axiosInstance.post("stocks", chunk)
                }
                toast.success("File Uploaded.", TOAST_OPTIONS);
            } catch (error) {
                console.log("Error while uploading data ::: ", error);
                setUploadingFile(false);
            }
            setUploadingFile(false)
            handleDialogClose();
        }
        /*
        setUploadingFile(true)
        const formData = new FormData();
        formData.append("company_code", selectedCompany);
        formData.append("color_type", selectedColorType);
        formData.append("file", selectedFile);
        axiosInstance.post(`stocks/upload`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            if (response.data) {
                setStockData(response.data);
                setNotificationDetails({
                    type: "success",
                    message: "File Uploaded successfully"
                })
                setShowNotification(true);
                setUploadingFile(false)
                setOpenUploadDialog(false);
            } else {
                setNotificationDetails({
                    type: "error",
                    message: "Error while Uploading File"
                })
                setShowNotification(true);
                setUploadingFile(false)
            }

        }).catch(error => {
            setNotificationDetails({
                type: "error",
                message: "Error while Uploading File"
            })
            setShowNotification(true);
            setUploadingFile(false)
        })*/
    }

    return <>
        <Tooltip title="Upload Stock">
            <Button onClick={() => setOpenUploadDialog(true)} size={props.size || "small"}>
                <FileUploadOutlined fontSize={props.size || "small"} />
            </Button>
        </Tooltip>
        <Dialog open={openUploadDialog} fullWidth={true} maxWidth={"sm"}>
            <DialogTitle className="flex justify-between items-center">
                Upload Stock
                <div className="text-base flex gap-4">
                    <span >Download Sample:</span>
                    <a href="/WHITE.xlsx" target="_blank" className="underline decoration-blue-500 text-blue-500">White</a>
                    {/* <a href="https://rocksaltlab-public.s3.ap-south-1.amazonaws.com/WHITE.xlsx" target="_blank" className="underline decoration-blue-500 text-blue-500">White</a> */}
                    {/* <a href="https://rocksaltlab-public.s3.ap-south-1.amazonaws.com/FANCY.xlsx" target="_blank" className="underline decoration-blue-500 text-blue-500">Fancy</a> */}
                    <a href="/FANCY.xlsx" target="_blank" className="underline decoration-blue-500 text-blue-500">Fancy</a>
                </div>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <div className="mt-4 flex flex-col gap-6">
                    <FormControl required size="small">
                        <InputLabel id="dropdown-label">Select Company</InputLabel>
                        <Select
                            labelId="dropdown-label"
                            id="dropdown"
                            label="Select Company"
                            value={selectedCompany}
                            onChange={handleDropdownChange}>
                            <MenuItem value="">Select Company</MenuItem>
                            {props.companyData.map((company: any, index: any) => (
                                <MenuItem key={company?.company_code} value={company?.company_code}>{company?.company_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl required size="small">
                        <InputLabel id="dropdown-label">Select Color Type</InputLabel>
                        <Select
                            labelId="dropdown-label"
                            id="dropdown"
                            label="Select Color Type<"
                            value={selectedColorType}
                            onChange={handleDropdownColorTypeChange}>
                            <MenuItem value="">Select Color Type</MenuItem>
                            <MenuItem value="white">White</MenuItem>
                            <MenuItem value="fancy">Fancy</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="flex items-center gap-4">
                        <input
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            type="file"
                            onChange={handleUploadChange}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" component="span" >
                                Select File
                            </Button>
                        </label>
                        {
                            selectedFile && <span>{selectedFile.name}</span>
                        }
                    </div>

                </div>
            </DialogContent>
            <DialogActions>
                {(selectedFile && uploadingFile) && <CircularProgress size={30} />}
                {selectedFile && selectedCompany && selectedColorType &&
                    <Button variant="contained" type="submit" disableElevation onClick={uploadFileHandler} disabled={uploadingFile}>Upload</Button>
                }
                <Button onClick={handleDialogClose} disabled={uploadingFile}>Close</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default StockUpload;