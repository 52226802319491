import { useParams } from "react-router-dom";
import isAuth from "../../../../components/IsAuth/IsAuth";
import SellerDetails from "../../../../components/SellerDetails/SellerDetails";
import axiosInstance from "../../../../utils/axiosInstance";
import { useEffect, useState } from "react";
import ThirdPartyDetails from "../../../../components/ThirdPartyDetails/ThirdPartyDetails";

const EditThirdPartyPage = () => {
    const [thirdPartyData, setThirdPartyData] = useState();

    const params = useParams();    
    const getThirdPartyById = () => {
        axiosInstance.get(`thirdparty/${params.id}`).then((data: any) => {
            // const seller = data.data;
            setThirdPartyData(data.data);
        })
    }

    useEffect(() => {
        getThirdPartyById();
    }, [])

    return <ThirdPartyDetails mode="edit" thirdPartyData={thirdPartyData}></ThirdPartyDetails>
}
export default isAuth(EditThirdPartyPage);