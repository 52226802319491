"use client";


import { AppBar, Box, CssBaseline } from "@mui/material";

import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import Sidenav from "../Sidenav/Sidenav";
import { useEffect, useState } from "react";
import useViewport from "../../../hooks/useViewport";


const Layout = (props: any) => {
  const [sidenavOpened, setSidenavOpened] = useState(false);
  const { width } = useViewport();
  const [isTemporary, setIsTemporary] = useState(width <= 700)

  useEffect(() => {
    setIsTemporary(width <= 700);
  }, [width])

  // const sidenavOpened = useSelector((state: any) => state.sidenav.open);
  // const dispatch = useDispatch()


  const toggleDrawer = () => {
    // dispatch(sideNavActions.toggleOpen(true))
    const isOpen = sidenavOpened
    setSidenavOpened(!isOpen);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header open={sidenavOpened} onToggleDrawer={toggleDrawer} isTemporary={isTemporary}/>
      <Sidenav open={sidenavOpened} />
      <div className={`px-4 pt-16 ${isTemporary ? "table-container-temporary" : sidenavOpened  ? "table-container" : "table-container-collapsed"}`}>
        <div>
          <Outlet />
        </div>
      </div>
    </Box>
  );
}

export default Layout;