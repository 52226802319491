import { FormEvent, useEffect, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Switch, Tooltip } from "@mui/material";
import { BusinessOutlined } from "@mui/icons-material";
import DownloadExcelButton from "../../../components/DownloadExcel/DownloadExcel";
import isAuth from "../../../components/IsAuth/IsAuth";
import axiosInstance from "../../../utils/axiosInstance";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../../utils/toastOption";

const API_URL = process.env.REACT_APP_BASE_URL!;

const ClientsPage = () => {
	const [clientData, setClientData] = useState([]);
	const [openCompanyAssignDialog, setOpenCompanyAssignDialog] = useState(false);
	const [companyData, setCompanyData] = useState([]);
	const [companies, setSelectedCompanies] = useState([]);
	const [seller, setSelectedSellers] = useState([]);
	const [clientId, setClientId] = useState([0]);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [sellerData, setSellerData] = useState([]);
	const [notificationDetails, setNotificationDetails] = useState({
		type: "",
		message: ""
	})
	const [isLoading, setLoading] = useState(false);


	const columns = useMemo(() => [
		{
			accessorKey: 'seller.name', //access nested data with dot notation
			header: 'Seller',
			size: 150,
		},
		{
			accessorKey: 'firstName', //access nested data with dot notation
			header: 'First Name',
			size: 150,
		},
		{
			accessorKey: 'lastName', //normal accessorKey
			header: 'Last Name',
			size: 200,
		},
		{
			accessorKey: 'country',
			header: 'Country',
			size: 150,
		},
		{
			accessorKey: 'companyName',
			header: 'Company Name',
			size: 150,
		},
		{
			accessorKey: 'address',
			header: 'Address',
			size: 150,
		},
		{
			accessorKey: 'mobileNumber',
			header: 'Mobile No',
			size: 150,
		},
		{
			accessorKey: 'weChat',
			header: 'weChat',
			size: 150,
		},
		{
			accessorKey: 'skype',
			header: 'Skype',
			size: 150,
		},
		{
			accessorKey: 'user.username', //access nested data with dot notation
			header: 'Username',
			size: 150,
		},
		{
			accessorKey: 'user.email', //access nested data with dot notation
			header: 'Email',
			size: 150,
		},
		{
			// Add an edit button column
			accessorKey: 'account_status',
			header: 'Account Status',
			size: 100,
			headerStyle: {
				textAlign: 'center' // Align the header text to the right
			},
			Cell: ({ row }: any) => (
				<div style={{ textAlign: 'center' }}>
					<Switch
						checked={row.original.user.isActive} // assuming 'active' field is a boolean
						onChange={async (event: any) => {
							const newValue = event.target.checked;
							const updatedFields = {
								isActive: newValue
							};
							const response = await axiosInstance.put(`${API_URL}users/${row.original.user._id}`, updatedFields);
							if (response.status == 200) {
								getClients();
							}
						}}
					/>
				</div>
			),
		},
		{
			// Add an edit button column
			accessorKey: 'action',
			header: 'Action',
			size: 100,
			headerStyle: {
				textAlign: 'center' // Align the header text to the right
			},
			Cell: ({ row }: any) => (
				<div style={{ textAlign: 'center' }}>
					<Tooltip title="Assign Company">
						<IconButton size="small" onClick={() => handleAssign(row)} aria-label="assignCompany"
							sx={{ borderBottom: 'none' }} >
							<BusinessOutlined />
						</IconButton>
					</Tooltip>
				</div>
			),
		},
	], [])

	const table = useMaterialReactTable({
		columns,
		data: clientData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
		enableFullScreenToggle: false,
		enableDensityToggle: false,
		enableHiding: false,
		enableStickyHeader: true,
		enableStickyFooter: true,
		muiTableContainerProps: {
			sx: {
				maxHeight: "calc(100vh - 260px)"
			}
		},
		state: {
			isLoading
		},
		initialState: {
			showColumnFilters: false,
			density: "compact"
		},
		renderTopToolbarCustomActions: () => {
			return <div>
				<DownloadExcelButton reportType="clients" fileName="ClientDetails"></DownloadExcelButton>
			</div>
		},
		muiTableBodyCellProps: () => ({
			//conditionally style pinned columns
			sx: {
				padding: "0px 0.5rem"
			},
		}),
	});

	const getClients = () => {
		setLoading(true);
		axiosInstance.get(`${API_URL}client`, {
			params: {
				loggedin_id: localStorage.getItem("username"),
				loggedin_level: localStorage.getItem("level"),
			},
		}).then((data: any) => {
			setClientData(data.data);
			setLoading(false);
		}).catch(e => {
			console.log(e);
			setLoading(true);
		})
	}

	const getSellers = () => {
		axiosInstance.get(`seller`).then((data: any) => {
			setSellerData(data.data);
		})
	}

	const getCompanies = () => {
		axiosInstance.get(`${API_URL}company`).then((data: any) => {
			setCompanyData(data.data);
		})
	}

	const handleDialogClose = () => {
		setOpenCompanyAssignDialog(false);
	}

	function handleAssign(row: any): void {
		setClientId(row.original._id);
		setOpenCompanyAssignDialog(true);
		getCompanies();
		getSellers();
		setSelectedCompanies(row.original.companies);
		if (row.original.seller != undefined) {
			setSelectedSellers(row.original.seller._id);
		}
	}

	const handleCompanyDropdownChange = async (event: any) => {
		setSelectedCompanies(event.target.value);
	};

	const handleSellerDropdownChange = async (event: any) => {
		setSelectedSellers(event.target.value);
	};

	useEffect(() => {
		getClients();
	}, [])

	async function handleSubmitAssign(event: FormEvent<HTMLFormElement>): Promise<void> {
		event.preventDefault();
		try {
			const response = await axiosInstance.put(`${API_URL}client/${clientId}`, { companies, seller });
			if (response.status == 200) {
				setOpenCompanyAssignDialog(false);
				// setNotificationDetails({
				// 	type: "success",
				// 	message: "Company / Seller Assigned Successfully"
				// })
				// setOpenSnackbar(true);
				toast.success("Company / Seller Assigned Successfully", TOAST_OPTIONS);
				getClients();
			}
		} catch (error) {
			throw new Error('Failed to delete record');
		}
	}

	return <>

		<div className="p-4">
			<span className="font-bold text-3xl">Clients</span>
		</div>
		<div>
			<MaterialReactTable table={table} />
		</div>
		<Dialog open={openCompanyAssignDialog} fullWidth={true} maxWidth={"sm"}>
			<DialogTitle>Assign Company / Seller</DialogTitle>
			<Divider /> {/* Add a Divider component here */}
			<form autoComplete="off" onSubmit={handleSubmitAssign}>
				<DialogContent>
					<div className="mt-4 flex flex-col gap-6">
						<FormControl fullWidth required size="small">
							<InputLabel id="dropdown-label">Select Company</InputLabel>
							<Select
								labelId="dropdown-label"
								id="dropdown"
								multiple
								required
								fullWidth
								label="Select Company"
								value={companies || []} // Ensure value is always an array
								onChange={handleCompanyDropdownChange}
							>
								{companyData.map((company: any, index: any) => (
									<MenuItem key={company?._id} value={company?._id}>{company?.company_name}</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl fullWidth required size="small">
							<InputLabel id="dropdown-label">Select Seller</InputLabel>
							<Select
								labelId="dropdown-label"
								id="dropdown"
								required
								fullWidth
								label="Select Seller"
								value={seller} // Ensure value is always an array
								onChange={handleSellerDropdownChange}
							>
								{sellerData.map((seller: any, index: any) => (
									<MenuItem key={seller?._id} value={seller?._id}>{seller?.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" type="submit" disableElevation>Submit</Button>
					<Button onClick={handleDialogClose}>Close</Button>
				</DialogActions>
			</form>
		</Dialog>
	</>
}

export default isAuth(ClientsPage);