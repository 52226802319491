import { ShareOutlined } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";

const ShareStocks = ({ selectedRowData }: any) => {

    const generateMessageText = (selectedRowData: any[]) => {
        const message = selectedRowData.map(row => {
            return `Stock No: ${row.stockNo}\nLab: ${row.lab}\nReport no: ${row.certificateNo}\nShape : ${row.shape}\nSize: ${row.weight}\nColor : ${row.color}\nClarity  : ${row.clarity}\nCut: ${row.cutGrade}\nPolish : ${row.polish}\nSymn  : ${row.symmetry}\nFl: ${row.fluorescence}\nLocation : ${row.country}\n`;
        }).join('\n');
        return message;
    };

    const openWhatsAppWeb = (message: any) => {
        const baseUrl = 'https://web.whatsapp.com/send';
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `${baseUrl}?text=${encodedMessage}`;

        window.open(whatsappUrl, '_blank');
    };

    const copyToClipboard = (html: any) => {
        const textField = document.createElement('textarea');
        textField.value = html;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };

    const shareStocks = () => {
        if (selectedRowData.length == 0) {
            alert("Please select at least one diamond.");
        } else {
            const message = generateMessageText(selectedRowData);
            copyToClipboard(message);
            openWhatsAppWeb(message);
        }
    }

    return <>
        <Tooltip title="Share">
            <Button key="three" size="small" disabled={selectedRowData.length == 0} onClick={shareStocks} >
                <ShareOutlined fontSize="small" />
            </Button>
        </Tooltip>
    </>
}

export default ShareStocks;