import isAuth from "../../../components/IsAuth/IsAuth";
import axiosInstance from "../../../utils/axiosInstance";
import { Delete, DeleteOutlined, Edit, EditOutlined, PersonAddOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../../utils/toastOption";

const API_URL = process.env.REACT_APP_BASE_URL!;

const CompanyPage = () => {
	const navigate = useNavigate();
	const [companyData, setCompanyData] = useState([]);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [recordToBeDeleted, setRecordToBeDeleted] = useState(null);
	const [loading, setLoading] = useState(true);

	const columns = useMemo(() => [
		{
			accessorKey: 'company_name', //access nested data with dot notation
			header: 'Company Name',
			size: 150,
		},
		{
			accessorKey: 'company_code', //normal accessorKey
			header: 'Company Code',
			size: 200,
		},
		{
			accessorKey: 'contact_no', //normal accessorKey
			header: 'Contact No',
			size: 200,
		},
		{
			accessorKey: 'email',
			header: 'Email',
			size: 150,
		},
		{
			accessorKey: 'address',
			header: 'Address',
			size: 150,
		},
		{
			// Add an edit button column
			accessorKey: 'action',
			header: 'Action',
			size: 100,
			headerStyle: {
				textAlign: 'center' // Align the header text to the right
			},
			Cell: ({ row }: any) => (
				<div style={{ textAlign: 'center' }}>
					<IconButton size="small" onClick={() => { navigate("/companies/edit/" + row.original._id); }} aria-label="edit"
						sx={{ borderBottom: 'none' }} color="primary">
						<EditOutlined />
					</IconButton>
					<IconButton size="small" onClick={() => { setIsDialogOpen(true); setRecordToBeDeleted(row.original._id) }} aria-label="delete"
						sx={{ borderBottom: 'none' }} color="error">
						<DeleteOutlined />
					</IconButton>
				</div>
			),
		},
	], [])

	const table = useMaterialReactTable({
		columns,
		data: companyData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
		enableFullScreenToggle: false,
		enableDensityToggle: false,
		enableHiding: false,
		initialState: { density: 'compact' },
		state: {
			isLoading: loading
		},
		renderTopToolbarCustomActions: ({ table }: any) => {
			return <Tooltip title="New Company">
				<IconButton
					onClick={() => {
						navigate('/companies/add');
					}}
					size="small"
					color="primary"
				>
					<PersonAddOutlined />
				</IconButton>
			</Tooltip>
		},
		muiTableBodyCellProps: ({ column }) => ({
			//conditionally style pinned columns
			sx: {
				padding: "0px 0.5rem"
			},
		}),
	});

	const getCompanies = () => {
		setLoading(true);
		axiosInstance.get(`company`).then((data: any) => {
			setCompanyData(data.data);
			setLoading(false);
		}).catch(e => {
			console.log(e);
			setLoading(false);
		})
	}

	async function handleDelete() {
		try {
			const response = await axiosInstance.delete(`company/${recordToBeDeleted}`);
			if (response.status == 200) {
				getCompanies();
				setIsDialogOpen(false);
				toast.success("Company Deleted Successfully", TOAST_OPTIONS);
			}
		} catch (error) {
			console.error('Error deleting record:', error);
			throw new Error('Failed to delete record');
		}
	}


	const handleClose = () => {
		setIsDialogOpen(false);
	};

	useEffect(() => {
		getCompanies();
	}, [])

	return <>

		<div className="p-4">
			<span className="font-bold text-3xl">Companies</span>

		</div>

		<div>
			<MaterialReactTable table={table} />
		</div>

		<Dialog
			open={isDialogOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Do you want to Delete this Company?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Once you delete this company, the action cannot be undo. Additionally, any associated data may be affected. Are you sure you want to proceed with the deletion?
				</DialogContentText>
			</DialogContent>
			<DialogActions>

				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleDelete} autoFocus color="error">
					Continue
				</Button>
			</DialogActions>
		</Dialog>
	</>
}

export default isAuth(CompanyPage);