import { Alert, Snackbar } from "@mui/material"

const NotificationMessage = ({ type, message, isOpen, handleClose }: any) => {
    const onCloseHandler = (event: any, reason: any) => {
        if (reason === "clickaway") {
            return;
        }

        handleClose();
    }

    return <Snackbar open={isOpen} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} autoHideDuration={1500} onClose={(e: any, r: any) => { onCloseHandler(e, r) }}>
        <Alert
            // onClose={(e: any, r: any) => { onCloseHandler(e, r) }}
            severity={type}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {message}
        </Alert>
    </Snackbar>
}

export default NotificationMessage