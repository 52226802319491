"use client";
import { useState } from "react";
import { Email, EmailOutlined, FeedbackOutlined, LocationCity, LocationCityRounded, LogoutOutlined, MenuOpenOutlined, MenuOutlined, PersonOutlineOutlined, Phone, VpnKeyOutlined, WhatsApp } from "@mui/icons-material";
import { AppBar, Avatar, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover, SvgIcon, TextField, Toolbar, Tooltip, Typography } from "@mui/material";

import "./Header.css";
import { useNavigate } from "react-router-dom";
import ChangePasswod from "../../ChangePassword/ChangePassword";
import { ReactComponent as WeChat } from "../../../images/wechat.svg";
import logo from "../../../images/logo_light.png";


const Header = (props: any) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(localStorage.getItem("user"));
    const [username, setUserName] = useState(localStorage.getItem("username"));
    const [level, setLevel] = useState(localStorage.getItem("level"));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const logoutHandler = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("access_token");
        localStorage.removeItem("username");
        // setAnchorEl(null);
        navigate("/login");
    }

    function handleOpenChangePasswordModal() {
        setIsChangePasswordModalOpen(true);
    }

    function handleClose() {
        setIsChangePasswordModalOpen(false);
        setIsContactUsModalOpen(false);
        setAnchorEl(null);
    }

    function handleContactUs() {
        setIsContactUsModalOpen(true);
    }

    return <>
        <AppBar position="fixed" color="default" sx={{ boxShadow: "none", borderBottom: "1px solid rgba(0,0,0,0.2)", zIndex: "1300" }} className="shadow-sm">
            <Toolbar className="bg-white">
                <div className="flex justify-center items-center gap-4">
                    <div className="text-center text-primary">
                        {/* <div className="text-2xl font-semibold">Buy</div>
                        <div className="text-xs">Labgrown Diamonds</div> */}
                        <img src={logo} alt="logo" style={{height:"45px"}}/>
                    </div>
                    <IconButton onClick={() => { props.onToggleDrawer() }}>
                        {props.open ? <MenuOpenOutlined /> : <MenuOutlined />}
                    </IconButton>
                </div>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                </Typography>

                <div className="flex items-center gap-4">
                    <div className="flex items-center">
                        {!props.isTemporary && <span>{user}</span>}
                        <IconButton onClick={handleMenuOpen}>
                            <Avatar className="header__avatar">{user && user[0]}</Avatar>
                            {/* <div className="header__avatar">{user && user[0]}</div> */}
                        </IconButton>
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <ListItem button onClick={handleOpenChangePasswordModal}>
                                    <ListItemIcon>
                                        <VpnKeyOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Change Password" />
                                </ListItem>
                                {/* {level == 'client' &&
                                    <><Divider />
                                        <ListItem button onClick={handleMenuClose}>
                                            <ListItemIcon>
                                                <PersonOutlineOutlined />
                                            </ListItemIcon>
                                            <ListItemText primary="Change Profile" />
                                        </ListItem>
                                        <Divider />
                                        <ListItem button onClick={handleMenuClose}>
                                            <ListItemIcon>
                                                <FeedbackOutlined />
                                            </ListItemIcon>
                                            <ListItemText primary="Feedback" />
                                        </ListItem></>
                                } */}
                                <Divider />
                                <ListItem button onClick={handleContactUs}>
                                    <ListItemIcon>
                                        <EmailOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Contact Us" />
                                </ListItem>
                            </List>
                        </Popover>
                    </div>
                    <Tooltip title="Logout">
                        <IconButton onClick={logoutHandler}>
                            <LogoutOutlined />
                        </IconButton>
                    </Tooltip>
                </div>
            </Toolbar>
        </AppBar>
        <ChangePasswod showDialog={isChangePasswordModalOpen} username={username} onClose={handleClose}></ChangePasswod>

        <Dialog maxWidth="xs" open={isContactUsModalOpen} onClose={handleClose} aria-labelledby="alert-dialog-title"
            fullWidth>
            <DialogTitle id="alert-dialog-title">
                Contact Us
            </DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <Card elevation={0}>
                    <CardContent>
                        <Grid container spacing={2} padding={1} alignItems="center">
                            <Grid item>
                                <Email color="primary" />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">info@buylgd.com</Typography>
                            </Grid>
                        </Grid>
                        <Divider></Divider>
                        <Grid container spacing={2} padding={1} alignItems="center">
                            <Grid item>
                                <WhatsApp color="primary" />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">+8615277457663</Typography>
                            </Grid>
                        </Grid>
                        <Divider></Divider>
                        <Grid container spacing={2} padding={1} alignItems="center">
                            <Grid item>
                                <SvgIcon color="primary" component={WeChat} style={{ fontSize: '2rem' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">qq837558701</Typography>
                            </Grid>
                        </Grid>
                        <Divider></Divider>
                        <Grid container spacing={2} padding={1} alignItems="center">
                            <Grid item>
                                <Phone color="primary" />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">+8615277457663</Typography>
                            </Grid>
                        </Grid>
                        <Divider></Divider>
                        <Grid container spacing={2} padding={1} alignItems="center">
                            <Grid item>
                                <LocationCity color="primary" />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">
                                    Jinzhan Jewelry Plaza, Shuibei 1st Road,<br />
                                    Luohu District, <br />
                                    Shenzhen City, <br />
                                    Guangdong Province
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider></Divider>
                        <Grid container spacing={2} padding={1} alignItems="center">
                            <Grid item>
                                <LocationCityRounded color="primary" />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">
                                    广东省深圳市罗湖区水贝一路金展珠宝广场<br />
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </DialogContent>
            <Divider></Divider>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default Header;