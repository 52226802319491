import { TextField } from "@mui/material";

const CaratFilter = ({caratFrom, caratTo, onChangeCaratFrom, onChangeCaratTo}: any) => {
    return <div className="grid grid-cols-12">
    <div className="md:col-span-2 col-span-12 flex items-center font-bold">Carat</div>
    <div className="md:col-span-10 col-span-12 flex flex-wrap gap-3">
        <TextField type="number" label="From" variant="outlined" size="small" value={caratFrom} inputProps={{step: "0.01"}} onChange={e => onChangeCaratFrom(e.target.value)}/>
        <TextField type="number" label="To" variant="outlined" size="small" value={caratTo} inputProps={{step: "0.01"}} onChange={e => onChangeCaratTo(e.target.value)}/>
    </div>
</div>
}

export default CaratFilter;