import { Tooltip } from "@mui/material";
import { useState } from "react";

const Labs = [
    { name: "IGI" },
    { name: "GIA" },
    { name: "HRD" },
    { name: "GCAL" }
]

const LabFilter = ({ selectedLab, onSelect }: any) => {


    const toggleSelected = (name: any) => {
        const labs = [...selectedLab];
        const index = labs.indexOf(name);
        if (index === -1) {
            labs.push(name)
        } else {
            labs.splice(index, 1);
        }
        onSelect(labs);
    }

    return <div className="grid grid-cols-12 w-full">
        <div className="md:col-span-2 col-span-12 flex flex-col justify-center gap-1">
            <span className="font-bold">Lab</span>
        </div>
        <div className="md:col-span-10 col-span-12 flex flex-wrap items-center gap-2">
            {Labs.map((e: any, i: any) => <Tooltip title={e.name}><div key={i} className={`selection-button-text wide ${selectedLab.includes(e.name) ? 'selected' : ""}`} onClick={() => toggleSelected(e.name)}>{e.name}</div></Tooltip>)}
        </div>
    </div>
}

export default LabFilter;