import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { DeleteOutlined, EditOutlined, PersonAddOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Switch, Tooltip, Typography } from "@mui/material";

import isAuth from "../../../components/IsAuth/IsAuth";
import axiosInstance from "../../../utils/axiosInstance";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../../utils/toastOption";

const SellersPage = () => {
    const navigate = useNavigate();
    const [sellerData, setSellerData] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [recordToBeDeleted, setRecordToBeDeleted] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const columns = useMemo(() => [
        {
            accessorKey: 'user.username', //access nested data with dot notation
            header: 'Username',
            size: 150,
        },
        {
            accessorKey: 'name', //access nested data with dot notation
            header: 'Name',
            size: 150,
        },
        {
            accessorKey: 'contact_no', //normal accessorKey
            header: 'Contact No',
            size: 200,
        },
        {
            accessorKey: 'email',
            header: 'Email',
            size: 150,
        },
        {
            // Add an edit button column
            accessorKey: 'account_status',
            header: 'Account Status',
            size: 100,
            headerStyle: {
                textAlign: 'center' // Align the header text to the right
            },
            Cell: ({ row }: any) => (
                <div style={{ textAlign: 'center' }}>
                    <Switch
                        checked={row.original.user.isActive} // assuming 'active' field is a boolean
                        onChange={async (event) => {
                            const newValue = event.target.checked;
                            const updatedFields = {
                                isActive: newValue
                            };
                            const response = await axiosInstance.put(`users/${row.original.user._id}`, updatedFields);
                            if (response.status == 200) {
                                getSellers();
                            }
                        }}
                    />
                </div>
            ),
        },
        {
            // Add an edit button column
            accessorKey: 'action',
            header: 'Action',
            size: 100,
            headerStyle: {
                textAlign: 'center' // Align the header text to the right
            },
            Cell: ({ row }: any) => (
                <div style={{ textAlign: 'center' }}>
                    <IconButton size="small" onClick={() => { navigate("/sellers/edit/" + row.original._id); }} aria-label="edit"
                        sx={{ borderBottom: 'none' }} color="primary">
                        <EditOutlined />
                    </IconButton>
                    <IconButton size="small" onClick={() => { setIsDialogOpen(true); setRecordToBeDeleted(row.original._id) }} aria-label="delete"
                        sx={{ borderBottom: 'none' }} color="error">
                        <DeleteOutlined />
                    </IconButton>
                </div>
            ),
        },
    ], [])

    const table = useMaterialReactTable({
        columns,
        data: sellerData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableHiding: false,
        initialState: { density: 'compact' },
        state: {
            isLoading
        },
        renderTopToolbarCustomActions: ({ table }: any) => {
            return <Tooltip title="New Seller">
                <IconButton size="small" color="primary" onClick={() => navigate('/sellers/add')}>
                    <PersonAddOutlined />
                </IconButton>
            </Tooltip>
            // <Button
            //     variant="outlined"
            //     startIcon={<PersonAddOutlined />}
            //     onClick={() => {
            //         router.push('/sellers/add');
            //     }}
            //     size="small"
            // >
            //     New Seller
            // </Button>
        },
        muiTableBodyCellProps: ({ column }) => ({
            //conditionally style pinned columns
            sx: {
                padding: "0px 0.5rem"
            },
        }),
    });

    const getSellers = () => {
        setLoading(true);
        axiosInstance.get(`seller`).then((data: any) => {
            setSellerData(data.data);
            setLoading(false);
        }).catch(e => {
            console.log(e);
            setLoading(false);
        })
    }

    async function handleDelete() {
        try {
            const response = await axiosInstance.delete(`seller/${recordToBeDeleted}`);
            if (response.status == 200) {
                handleClose();
                getSellers();
                toast.success("Seller Deleted Successfully", TOAST_OPTIONS);
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            throw new Error('Failed to delete record');
        }
    }

    const handleClose = () => {
        setIsDialogOpen(false);
    };


    useEffect(() => {
        getSellers();
    }, [])
    return <>


        <div className="p-4">
            <span className="font-bold text-3xl">Sellers</span>
        </div>

        <div>
            <MaterialReactTable table={table} />
        </div>

        <Dialog
            open={isDialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Do you want to Delete this Seller?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Once you delete this seller, the action cannot be undo. Additionally, any associated data may be affected. Are you sure you want to proceed with the deletion?
                </DialogContentText>
            </DialogContent>
            <DialogActions>

                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleDelete} autoFocus color="error">
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default isAuth(SellersPage);