import { SendOutlined } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "../../utils/axiosInstance";
import { useState } from "react";
import NotificationMessage from "../common/notification/notification";


const ContactUs = () => {
    const [showNotification, setShowNotification] = useState(false);
    const [notificationDetails, setNotificationDetails] = useState({ type: "", message: "" })
    const { control, watch, handleSubmit } = useForm({ mode: "all" });

    const onSubmit = (data: any) => {
        axiosInstance.post("contact-us", data).then(response => {
            setNotificationDetails({
                type: "success",
                message: "Thank you for submitting your query. We will contact you shortly."
            })
            setShowNotification(true);
        })

    }

    return <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
            name="fullName"
            control={control}
            rules={{
                required: {
                    value: true,
                    message: "Please enter your name"
                }
            }}
            render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Your Name" variant="outlined" size="small"
                helperText={error ? error.message : ""}
                error={!!error}
            />)} />

        <Controller name="email"
            control={control}
            rules={{
                required: {
                    value: true,
                    message: "Please enter your Email"
                },
                pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Invalid email address',
                },
            }}
            render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Your Email" variant="outlined" size="small"
                helperText={error ? error.message : ""}
                error={!!error}
            />)} />

        <Controller
            name="contactNumber"
            control={control}
            rules={{
                required: {
                    value: true,
                    message: "Please enter your Contact number"
                },
                pattern: {
                    // value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    // value: /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/,
                    value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                    message: 'Please enter Valid mobile number',
                },
            }}
            render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Your Contact No." variant="outlined" size="small"
                helperText={error ? error.message : "Ex: +91XXXXXXXXXX"}
                error={!!error}
            />)} />


        <Controller
            name="message"
            control={control}
            rules={{
                required: {
                    value: true,
                    message: "Please enter your message"
                },
            }}
            render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Message" variant="outlined" size="small" multiline
                InputProps={{
                    rows: 3, // Number of visible rows
                }}
                helperText={error ? error.message : ""}
                error={!!error}
            />)} />
        <Button type="submit" variant="contained">Send&nbsp; <SendOutlined /></Button>
        <NotificationMessage isOpen={showNotification} type={notificationDetails.type} message={notificationDetails.message} handleClose={() => setShowNotification(false)} />
    </form>
}

export default ContactUs;