import { Typography } from "@mui/material";
import isAuth from "../../../components/IsAuth/IsAuth"
import { useEffect, useState } from "react";
import StockList from "../../../components/StockList/StockList";
import axiosInstance from "../../../utils/axiosInstance";

const MyCartPage = () => {
    const [stockData, setStockData] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    const [totalCount, setTotalCount] = useState(0);

    const getMyCartData = () => {
        axiosInstance.get(`cart`, {
            params: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize, // specify the limit here, you can change it as needed
            },
        }).then((response: any) => {
            setStockData(response.data.data);
            setTotalCount(response.data.recordsCount);
        })
    }

    useEffect(() => {
        getMyCartData();
    }, [pagination])

    return <>
        <div className="p-4 flex justify-between items-center">
            <Typography variant="h5">My Cart</Typography>
        </div>
        <div>
            <StockList stockData={stockData} pagination={pagination} setPagination={setPagination} totalCount={totalCount} onRowsSelect={() => {}}></StockList>
        </div>
    </>
}

export default isAuth(MyCartPage);