import logo from "../../images/logo_dark_v.png"
import certified from "../../images/certified.png";
import quality from "../../images/quality.png";
import valueForMoney from "../../images/value.png";
import ethics from "../../images/engagement.png";
import ecoFriendly from "../../images/save-the-world.png";
import innovative from "../../images/innovation.png";
import igi from "../../images/igi.png";
import "./LandingPageMobile.css";
import { BusinessOutlined, CallOutlined, ChatBubble, EmailOutlined, MenuOutlined, WhatsApp } from "@mui/icons-material";
import ContactUs from "../../components/ContactUs/ContactUs";
import { IconButton, Menu, MenuItem, SvgIcon } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as WeChat } from "../../images/wechat.svg";

const Card = ({ image, title, content }: any) => {
    return <div className="bg-white m-4 rounded-lg shadow-lg card text-center p-6 flex flex-col items-center justify-center gap-4">
        <img src={image} width={80} className="mt-4" />
        <h1 className="text-xl font-bold px-4">{title}</h1>
        <p className="mb-4 px-4">{content}</p>
    </div>
}

const LandingPageMobile = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const aboutRef = useRef<any>(null);
    const contactRef = useRef<any>(null);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (section: any) => {
        if (section == "about" && aboutRef && aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

        if (section == "contact" && contactRef && contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

        if (section === "login") {
            navigate("/login");
        }
        setAnchorEl(null);
    };



    return <>
        <div className="mobile-header flex justify-between items-center w-full p-4">
            <img src={logo} width={110} />
            <div>
                <IconButton color="inherit" id="menu" onClick={handleClick} >
                    <MenuOutlined color="inherit" />
                </IconButton>
                <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => { handleClose("about") }}>About Us</MenuItem>
                    <MenuItem onClick={() => { handleClose("contact") }}>Contact Us</MenuItem>
                    <MenuItem onClick={() => { handleClose("login") }}>Login</MenuItem>
                </Menu>
            </div>
        </div>
        <div className="hero flex flex-col justify-center items-center h-screen auth-bg gap-6 p-4">
            <div>
                <p className="text-4xl text-center hero-content">Discover the Future of Diamonds</p>
                <p className="mt-4 text-center font-light">Sustainable, Ethical, and Stunning Lab-Grown Diamonds</p>
            </div>
            <a href="/register" className="login-button">Get Started</a>
        </div>

        <div className="flex flex-col items-center justify-center gap-6 h-screen gap-6 px-4 py-8 about bg-white" ref={aboutRef}>
            <h1 className="text-3xl text-center font-semibold">About Buy LGD</h1>

            <p className="text-xl mt-4 text-center">At Buy LGD, we revolutionize the diamond industry by providing stunning, ethically-sourced lab-grown diamonds. Our commitment to quality and sustainability ensures that every diamond is not only beautiful but also environmentally friendly and conflict-free.</p>
            <p className="text-xl mt-4 text-center">From carbon seed to stunning gemstone, our diamonds are crafted with precision and care. Learn more about the science and artistry behind each diamond.</p>
        </div>

        <div className="flex flex-col items-center gap-6 px-4 py-8">
            <h1 className="text-3xl text-center font-semibold">Why Choose Lab-Grown Diamonds?</h1>
            <div className="flex flex-col gap-4">
                <Card image={quality} title="Quality and Purity" content="Lab-grown diamonds possess the same physical, chemical, and optical properties as mined diamonds. They are graded by the same standards, ensuring you get a diamond of exceptional quality and clarity." />
                <Card image={valueForMoney} title="Value for Money" content="Enjoy the luxury of diamonds at a fraction of the cost. Lab-grown diamonds offer significant savings, allowing you to invest in a higher quality diamond or a larger carat size for the same price as a mined diamond." />
                <Card image={certified} title="Certified" content="Lab-grown diamonds are certified by reputable institutions such as GIA, IGI, and GCAL. This ensures that you can trust their authenticity and always receive a genuine product without any risk of fraud." />
                <Card image={ethics} title="Ethical Assurance" content="Say goodbye to conflict diamonds. Our lab-grown diamonds are 100% conflict-free, ensuring that your purchase does not contribute to unethical labor practices or financing of conflicts." />
                <Card image={ecoFriendly} title="Environmental Impact" content="Our diamonds are created with minimal environmental disruption compared to traditional mining. This means no destructive mining practices, reduced carbon footprint, and a commitment to preserving natural habitats." />
                <Card image={innovative} title="Innovative and Modern" content="Lab-grown diamonds are at the forefront of technological innovation. Embrace the future with a diamond that symbolizes progress, sustainability, and modern elegance." />
            </div>
        </div>

        <div className="flex flex-col items-center justify-center gap-6 px-4 py-8 h-screen bg-white feed">
            <h1 className="text-3xl text-center font-semibold">Virtual Diamond Showcase</h1>
            <div className="text-xl text-center">Enhance your website with our lab-grown diamond collection, giving you full customization over size, quality, shapes, and more through our state-of-the-art, low-latency APIs.</div>
            <div className="text-xl px-10 font-light">
                <p className="mt-5">- Quickly scale your online diamond business</p>
                <p className="mt-5">- Offer the perfect diamond to suit every visitor's needs on your website.</p>
                <p className="mt-5">- Quickly scale your online diamond business</p>
            </div>
        </div>

        <div className="flex flex-col items-center gap-6 px-4 py-8" ref={contactRef}>
            <h1 className="text-3xl text-center font-semibold">Contact Us</h1>
            <div className="font-light flex flex-col gap-3">
                <p className="flex gap-4 text-xl">
                    <EmailOutlined /> <a href="mailto:info@buylgd.com" >info@buylgd.com</a>
                </p>

                <p className="flex gap-4 text-xl">
                    <WhatsApp /> <a href="https://api.whatsapp.com/send?phone=8615277457663">+8615277457663</a>
                </p>

                <p className="flex gap-4 text-xl">
                    <SvgIcon component={WeChat} style={{ fontSize: '2rem' }} /> qq837558701
                </p>

                <p className="flex gap-4 text-xl">
                    <CallOutlined /> <a href="tel:+8615277457663">+8615277457663</a>
                </p>

                <p className="flex gap-4 text-xl">
                    <BusinessOutlined />
                    <div>
                        <p>Jinzhan Jewelry Plaza, Shuibei 1st Road,</p>
                        <p>Luohu District,</p>
                        <p>Shenzhen City,</p>
                        <p>Guangdong Province</p>
                    </div>
                </p>

                <p className="flex gap-4 text-xl">
                    <BusinessOutlined />
                    <div>
                        广东省深圳市罗湖区水贝一路金展珠宝广场
                    </div>
                </p>
            </div>

            <div className="flex flex-col gap-7 text-center mt-10">
                <div>
                    <h1 className="text-3xl font-semibold">Have Questions?</h1>
                    <p className="font-light">
                        We're here to help. Reach out to us via email, phone, or the contact form, and we'll get back to you as soon as possible.
                    </p>
                </div>
                <ContactUs />
            </div>
        </div>

        <div className="flex flex-col items-center gap-7 bg-dark py-8">
            <h1 className="text-3xl font-semibold">Certified By</h1>
            <div className="flex justify-center gap-10">
                <img src={igi} width={150} />
                <img src="https://4cs.gia.edu/wp-content/uploads/2016/06/GIA-logo-white.png" width={150} />
            </div>
        </div>

        <div className="flex justify-center bg-black text-white p-6">
            <span className="font-semibold">
                © 2024 Buy Labgrown Diamonds. All Rights Reserved.
            </span>
        </div>

    </>
}

export default LandingPageMobile;