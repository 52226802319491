import * as XLSX from "xlsx";

const FILE_HEADERS:any = {
    fancy: ['STOCK ID', 'STATUS', 'IMAGE', 'VIDEO', 'TYPE', 'LOCATION', 'LAB', 'REPORT NO', 'SHAPE', 'WEIGHT', 'COLOR', 'INTENSITY', 'OVERTONE', 'CLARITY', 'CUT', 'POLISH', 'SYM', 'FLUORESCENCE', 'P/CT', 'TOTAL', 'MEASUREMENT1', 'MEASUREMENT2', 'MEASUREMENT3', 'DEPTH%', 'TABLE%', 'CROWN ANGLE', 'CROWN HEIGHT', 'PAVILION ANGLE', 'PAVILION HEIGHT', 'GIRDLE%', 'GIRDLE', 'CULET SIZE', 'RATIO', 'H&A', 'BLACK', 'EYE CLEAN'],
    white: ['STOCK ID', 'STATUS', 'IMAGE', 'VIDEO', 'TYPE', 'LOCATION', 'LAB', 'REPORT NO', 'SHAPE', 'WEIGHT', 'COLOR', 'CLARITY', 'CUT', 'POLISH', 'SYM', 'FLUORESCENCE', 'RAP', 'RAP VALUE', 'DISC %', 'P/CT', 'TOTAL', 'SHADE', 'MEASUREMENT1', 'MEASUREMENT2', 'MEASUREMENT3', 'DEPTH%', 'TABLE%', 'CROWN ANGLE', 'CROWN HEIGHT', 'PAVILION ANGLE', 'PAVILION HEIGHT', 'GIRDLE%', 'GIRDLE', 'CULET SIZE', 'RATIO', 'H&A', 'BLACK', 'EYE CLEAN']
}

const transformDataWhite = (data: any, company_code: any, colorType: any, uuid: string) => {
    const transformedData = data.map((e: any) => ({
        company_code,
        colorType,
        stockNo: e["STOCK ID"],
        stock_status: e["STATUS"],
        imageLink: e["IMAGE"],
        videoLink: e["VIDEO"],
        type: e["TYPE"],
        location: e["LOCATION"],
        lab: e["LAB"],
        certificateNo: e["REPORT NO"],
        shape: e["SHAPE"],
        weight: e["WEIGHT"],
        color: e["COLOR"],
        clarity: e["CLARITY"],
        cutGrade: e["CUT"],
        polish: e["POLISH"],
        symmetry: e["SYM"],
        fluorescence: e["FLUORESCENCE"],
        rap: e["RAP"],
        rapPrice: isNaN(e["RAP VALUE"]) ? undefined : e["RAP VALUE"],
        discount: isNaN(e["DISC %"]) ? undefined : e["DISC %"],
        perCaratPrice: isNaN(e["P/CT"]) ? undefined : +e["P/CT"],
        totalPrice: isNaN(e["TOTAL"]) ? undefined : +e["TOTAL"],
        shade: e["SHADE"],
        measurments: `${e["MEASUREMENT1"]}${e["MEASUREMENT2"] ? "*" + e["MEASUREMENT2"] : ""}${e["MEASUREMENT3"] ? "*" + e["MEASUREMENT3"] : ""}`,
        depthPercent: isNaN(e["DEPTH%"]) ? undefined : e["DEPTH%"],
        tablePercent: isNaN(e["TABLE%"]) ? undefined : e["TABLE%"],
        crownHeight: e["CROWN HEIGHT"],
        crownAngle: e["CROWN ANGLE"],
        pavilionHeight: e["PAVILION ANGLE"],
        pavilionAngle: e["PAVILION HEIGHT"],
        girdlePercent: isNaN(e["GIRDLE%"]) ? undefined : e["GIRDLE%"],
        girdle: e["GIRDLE"],
        cutletSize: e["CULET SIZE"],
        ratio: isNaN(e["RATIO"]) ? undefined : +e["RATIO"],
        ha: e["H&A"],
        black: e["BLACK"],
        eyeClear: e["EYE CLEAN"],
        uuid
    }))
    return transformedData;
}

const transformDataFancy = (data: any, company_code: any, colorType: any, uuid: string) => {
    const transformedData = data.map((e: any) => ({
        company_code,
        colorType,
        stockNo: e["STOCK ID"],
        stock_status: e["STATUS"],
        imageLink: e["IMAGE"],
        videoLink: e["VIDEO"],
        type: e["TYPE"],
        location: e["LOCATION"],
        lab: e["LAB"],
        certificateNo: e["REPORT NO"],
        shape: e["SHAPE"],
        weight: e["WEIGHT"],
        color: e["COLOR"],
        intensity: e["INTENSITY"],
        overtone: e["OVERTONE"],
        clarity: e["CLARITY"],
        cutGrade: e["CUT"],
        polish: e["POLISH"],
        symmetry: e["SYM"],
        fluorescence: e["FLUORESCENCE"],
        // rap: e["RAP"],
        // rapPrice: isNaN(e["RAP VALUE"]) ? undefined : e["RAP VALUE"],
        // discount: isNaN(e["DISC %"]) ? undefined : e["DISC %"],
        perCaratPrice: isNaN(e["P/CT"]) ? undefined : +e["P/CT"],
        totalPrice: isNaN(e["TOTAL"]) ? undefined : +e["TOTAL"],
        measurments: `${e["MEASUREMENT1"]}${e["MEASUREMENT2"] ? "*" + e["MEASUREMENT2"] : ""}${e["MEASUREMENT3"] ? "*" + e["MEASUREMENT3"] : ""}`,
        depthPercent: isNaN(e["DEPTH%"]) ? undefined : e["DEPTH%"],
        tablePercent: isNaN(e["TABLE%"]) ? undefined : e["TABLE%"],
        crownHeight: e["CROWN HEIGHT"],
        crownAngle: e["CROWN ANGLE"],
        pavilionHeight: e["PAVILION ANGLE"],
        pavilionAngle: e["PAVILION HEIGHT"],
        girdlePercent: isNaN(e["GIRDLE%"]) ? undefined : e["GIRDLE%"],
        girdle: e["GIRDLE"],
        cutletSize: e["CULET SIZE"],
        ratio: isNaN(e["RATIO"]) ? undefined : +e["RATIO"],
        ha: e["H&A"],
        black: e["BLACK"],
        eyeClear: e["EYE CLEAN"],
        uuid
    }))
    return transformedData;
}

export const transformCSV = async (file: any, company_code: any, colorType: any, uuid: string) => {

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const sheetData = XLSX.utils.sheet_to_json(sheet);
    if(colorType === "fancy") {
        return transformDataFancy(sheetData, company_code, colorType, uuid)
    }
    if(colorType === "white") {
        return transformDataWhite(sheetData, company_code, colorType, uuid)
    }
    return [];
}


export const verifyFileFormat = async (file: any, colorType: any) => {
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: 'array' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const options = { header: 1 };
    const sheetData2 = XLSX.utils.sheet_to_json(worksheet, options);
    const header: any = sheetData2.shift();
    return FILE_HEADERS[colorType].every((item: any) => header.includes(item))
    
}

