
const Unauthorized = () => {
    return <div className="h-screen w-full flex justify-center items-center gap-6">
        <span className="text-lg">
            {"Page not found OR You don't have access to this page"}
        </span>
    </div>  
}

export default Unauthorized;
