import isAuth from "../../../components/IsAuth/IsAuth";
import { useEffect, useState } from "react";
import WebTrackingDetails from "../../../components/WebTrackingDetails/WebTrackingDetails";

const WebTrackingPage = () => {
    const [username, setUsername] = useState('');

    useEffect(() => {
        const storedUsername = localStorage.getItem("username");
        if (storedUsername) {
            setUsername(storedUsername);
        }

    }, []);

    return <>
        <WebTrackingDetails></WebTrackingDetails>
    </>
}

export default isAuth(WebTrackingPage);