import { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { Typography } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

const WebTrackingDetails = () => {
	const [wabTrackingData, setWebTrackingData] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 50,
	});

	const trackingData = () => {
		axiosInstance.get(`tracking`, {
			params: {
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize, // specify the limit here, you can change it as needed
			},
		}).then((response: any) => {
			setWebTrackingData(response.data.data);
			setTotalCount(response.data.recordsCount);
		})
	}

	useEffect(() => {
		trackingData();
	}, [pagination])

	const columns = useMemo(() => [
		{
			accessorKey: "username",
			header: "Username",
			size: 50
		},
		{
			accessorKey: 'message',
			header: 'Message',
			size: 150,
		},
		{
			accessorKey: 'ipAddress',
			header: 'IP Address',
			size: 80,
		},
		{
			accessorKey: 'createdAt',
			header: 'Time',
			size: 80,
			accessorFn: (row: any) => new Date(row.createdAt).toLocaleString() // Convert timestamp to time format
		}
	], []);

	const table = useMaterialReactTable({
		columns,
		data: wabTrackingData,
		enableFullScreenToggle: false,
		enableRowSelection: false,
		enableStickyHeader: true,
		enableStickyFooter: true,
		enableRowVirtualization: true,
		enableDensityToggle: false,
		enableHiding: false,
		enableColumnPinning: true,
		manualPagination: true,
		rowCount: totalCount,
		muiPaginationProps: {
			rowsPerPageOptions: [50, 100, 200],
		},
		initialState: {
			showColumnFilters: false,
			density: "compact",
			columnOrder: [
				"view",
				// "stock",
			],
			columnPinning: { left: ['mrt-row-select', "view"], }
		},
		state: {
			pagination: pagination,
		},
		onPaginationChange: setPagination,
		muiTableContainerProps: {
			sx: {
				maxHeight: "calc(100vh - 260px)"
			}
		},
	})


	return <>
		<div className="p-4">
            <span className="font-bold text-3xl">Web Tracking</span>
		</div>

		<div>
			<MaterialReactTable table={table} />
		</div>
	</>
}

export default WebTrackingDetails;