import { CameraAltOutlined, Close, RemoveRedEyeOutlined, VideocamOutlined, WorkspacePremiumOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Dialog, DialogTitle, Divider, IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const RowActions = ({ row, setUrlToDiplay, setOpenMediaDialog, setMediaType, setLoadingCert }: any) => {
    return <>
        <Tooltip title="View Image">
            <span>
                <IconButton color="primary" size="small" onClick={() => {
                    setUrlToDiplay(row.original.imageLink);
                    setOpenMediaDialog(true);
                    setMediaType("Image");
                }}
                    disabled={!row.original.imageLink}>
                    <CameraAltOutlined />
                </IconButton>
            </span>
        </Tooltip>

        <Tooltip title="View Video">
            <span>
                <IconButton color="primary" size="small" onClick={() => {
                    setUrlToDiplay(row.original.videoLink);
                    setOpenMediaDialog(true);
                    setMediaType("Video");
                }} disabled={!row.original.videoLink}>
                    <VideocamOutlined />
                </IconButton>
            </span>
        </Tooltip>

        <Tooltip title="View Certificate">
            <span>
                <IconButton color="primary" size="small" onClick={() => {
                    setLoadingCert(true);
                    setUrlToDiplay(row.original.certificateUrl);
                    setMediaType("Certificate")
                    setOpenMediaDialog(true);
                }} disabled={!row.original.certificateUrl}>
                    <WorkspacePremiumOutlined />
                </IconButton>
            </span>
        </Tooltip>

        <Tooltip title="View Details">
            <Link to={`/stock/${row.original._id}`}>
                <IconButton color="info">
                    <RemoveRedEyeOutlined />
                </IconButton>
            </Link>
        </Tooltip>
    </>
}


export const RowActionsMenu = ({ row, setUrlToDiplay, setOpenMediaDialog, setMediaType, setLoadingCert, closeMenu }: any) => [ //{ row, setUrlToDiplay, setOpenMediaDialog, setMediaType, setLoadingCert }: any
    <MenuItem key="image" onClick={() => {
        setUrlToDiplay(row.original.imageLink);
        setOpenMediaDialog(true);
        setMediaType("Image");
        closeMenu();
    }}
        disabled={!row.original.imageLink}>
        <ListItemIcon>
            <CameraAltOutlined />
        </ListItemIcon>
        <ListItemText>View Image</ListItemText>
    </MenuItem>,

    <MenuItem key="video" onClick={() => {
        setUrlToDiplay(row.original.videoLink);
        setOpenMediaDialog(true);
        setMediaType("Video");
        closeMenu();
    }} disabled={!row.original.videoLink}>
        <ListItemIcon>
            <VideocamOutlined />
        </ListItemIcon>
        <ListItemText>View Video</ListItemText>
    </MenuItem>,

    <MenuItem key="cert" onClick={() => {
        setLoadingCert(true);
        setUrlToDiplay(row.original.certificateUrl);
        setMediaType("Certificate")
        setOpenMediaDialog(true);
        closeMenu();
    }} disabled={!row.original.certificateUrl}>
        <ListItemIcon>
            <WorkspacePremiumOutlined />
        </ListItemIcon>
        <ListItemText>View Certificate</ListItemText>
    </MenuItem>,

    <Link to={`/stock/${row.original._id}`} key="details">
        <MenuItem >
            <ListItemIcon onClick={() => { closeMenu(); }}>
                <RemoveRedEyeOutlined />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
        </MenuItem>
    </Link>
]

export const MediaDialog = ({ openMediaDialog, mediaType, handleDialogClose, loadingCert, urlToDiplay, setLoadingCert }: any) => {
    return <Dialog fullScreen open={openMediaDialog} fullWidth={true} maxWidth={"sm"} onClose={handleDialogClose}>
        <div className="flex justify-between items-center">
            <DialogTitle>View {mediaType}</DialogTitle>
            <Button variant="outlined" onClick={handleDialogClose} style={{ marginRight: "20px" }}>
                <Close /> <span className="font-bold">Close</span>
            </Button>
        </div>
        <Divider />
        <div className="h-full">
            {mediaType == "Image" && <img src={urlToDiplay} alt="Diamond image" className="stock-image" />}
            {mediaType == "Video" && <iframe src={urlToDiplay} title="Diamond Video" className="stock-video"></iframe>}
            {loadingCert && <div className="h-full w-full flex flex-col justify-center items-center">
                <CircularProgress />
                <Typography variant="caption">Loading Certificate...</Typography>
            </div>
            }
            {mediaType == "Certificate" && <iframe src={urlToDiplay} title="Diamond Video" className="stock-video" onLoad={() => { setLoadingCert(false) }}></iframe>}
        </div>
    </Dialog>
}
