import { useParams } from "react-router-dom";
import isAuth from "../../../../components/IsAuth/IsAuth";
import SellerDetails from "../../../../components/SellerDetails/SellerDetails";
import axiosInstance from "../../../../utils/axiosInstance";
import { useEffect, useState } from "react";
import AdminUserDetails from "../../../../components/AdminUserDetails/AdminUserDetails";

const EditAdminUserPage = () => {
    const [adminUserData, setAdminUserData] = useState();

    const params = useParams();
    const getAdminUserById = () => {
        axiosInstance.get(`adminuser/${params.id}`).then((data: any) => {
            // const seller = data.data;
            setAdminUserData(data.data);
        })
    }

    useEffect(() => {
        getAdminUserById();
    }, [])

    return <AdminUserDetails mode="edit" adminUserData={adminUserData}></AdminUserDetails>
}
export default isAuth(EditAdminUserPage);