import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL!;

const axiosInstance = axios.create({
    baseURL: API_URL
})

// Request interceptor
axiosInstance.interceptors.request.use((request: any) => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }
    return request;
})

//   // Response interceptor
//   axiosInstance.interceptors.response.use(
//     (response) => {
//       // Modify the response data here (e.g., parse, transform)
  
//       return response;
//     },
//     (error) => {
//       // Handle response errors here
  
//       return Promise.reject(error);
//     }
//   );
  
  export default axiosInstance;
