import { Box, Button, Card, CardContent, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, Typography } from "@mui/material";
import "./StoneDetails.css";
import { useState } from "react";


const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className="w-full"
        >
            {value === index && <div className="w-full">{children}</div>}
        </div>
    );
}

const StoneDetails = ({ stoneDetails }: any) => {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const generateMessageText = () => {
        const message = `I am writing to request the certificate link for the diamond associated with the certificate number : ${stoneDetails.certificateNo}\nHaving access to this link is important for my records and verification purposes\n`;
        return message;
    };

    const openWhatsAppWeb = (message: any) => {
        const baseUrl = 'https://web.whatsapp.com/send';
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `${baseUrl}?text=${encodedMessage}`;

        window.open(whatsappUrl, '_blank');
    };

    const copyToClipboard = (html: any) => {
        const textField = document.createElement('textarea');
        textField.value = html;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };

    const shareStocks = () => {
        const message = generateMessageText();
        copyToClipboard(message);
        openWhatsAppWeb(message);
    }


    return <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-6 col-span-12">
            <Card variant="outlined">
                <div className="text-lg text-center p-4">Stone Id: <strong>{stoneDetails?.stockNo}</strong></div>
            </Card>
        </div>
        <div className="md:col-span-6 col-span-12">
            <Card variant="outlined">
                <div className="font-bold text-lg text-center p-4">{stoneDetails.shape}</div>
            </Card>
        </div>
        <div className="md:col-span-6 col-span-12 flex flex-col gap-4">
            <Card variant="outlined">
                <CardContent>
                    <div className="text-lg font-bold mb-2">Diamond Details</div>
                    <div className="grid grid-cols-12 border">
                        <div className="col-span-6 md:col-span-3 border px-2">Carat</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.weight} ct.</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Shape</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.shape}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Color</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.color}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Cut</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.cutGrade}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Clarity</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.clarity}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Polish</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.polish}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Lab</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.lab}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Symmetry</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.symmetry}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Certificate No</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.certificateNo}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Type</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.type}</strong></div>
                    </div>
                </CardContent>
            </Card>
            <Card variant="outlined">
                <CardContent>
                    <div className="text-lg font-bold mb-2">Parameters</div>

                    <div className="grid grid-cols-12 border">
                        <div className="col-span-6 md:col-span-3 border px-2">Measurement</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.measurments}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Crown Angle</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.crownAngle || 'N/A'}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Avg. Dia/Ratio</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.ratio || "N/A"}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Crown Height(%)</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.crownAngle || 'N/A'}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Total Depth(%)</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.depthPercent || "N/A"}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">PAV Angle</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.pavilionAngle || "N/A"}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Table(%)</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.tablePercent || "N/A"}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">PAV Height(%)</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.pavilionHeight || "N/A"}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Star Length(%)</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.starLength || "N/A"}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Lower Half(%)</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.lowerHalf || "N/A"}</strong></div>
                    </div>
                </CardContent>
            </Card>
            <Card variant="outlined">
                <CardContent>
                    <div className="text-lg font-bold mb-2">Price</div>

                    <div className="grid grid-cols-12 border">
                        <div className="col-span-6 md:col-span-3 border px-2">Rap Disc(%)</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.discount ? Math.abs(stoneDetails.discount).toFixed(2) : "-"}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Rap</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.rap ? stoneDetails.rap : ""}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">$/ Carat</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.perCaratPrice ? +stoneDetails.perCaratPrice.toFixed(2) : ""}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Amount</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.totalPrice ? +stoneDetails.totalPrice.toFixed(2) : ""}</strong></div>
                    </div>
                </CardContent>
            </Card>
            <Card variant="outlined">
                <CardContent>
                    <div className="text-lg font-bold mb-2">Inclusion</div>

                    <div className="grid grid-cols-12 border">
                        <div className="col-span-6 md:col-span-3 border px-2">Table Inclusion</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.tableInclusion || "N/A"}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Side Inclusion</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.sideInclusion || "N/A"}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Table Black</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.black || "None"}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Side Black</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.sideBlack || "N/A"}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Table Open</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.tableOpen || "N/A"}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Side Open</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.sideOpen || "N/A"}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Shade</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.shade || "N/A"}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Extra Facet</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.extraFacet || "N/A"}</strong></div>

                        <div className="col-span-6 md:col-span-3 border px-2">Milky</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.milky || "N/A"}</strong></div>
                        <div className="col-span-6 md:col-span-3 border px-2">Eye Clean</div>
                        <div className="col-span-6 md:col-span-3 border px-2"><strong>{stoneDetails.eyeClear || "N/A"}</strong></div>
                    </div>
                </CardContent>
            </Card>
            <Card variant="outlined">
                <CardContent>
                    <div className="text-lg font-bold mb-2">Key to Symbol</div>

                    <table className="border-collapse border border-slate-400 w-full">
                        <tbody>
                            <tr>
                                <td className="border px-2">{stoneDetails.keyToSymbol || "N/A"}</td>
                            </tr>
                        </tbody>
                    </table>

                </CardContent>
            </Card>


        </div>

        <div className="md:col-span-6 col-span-12 flex flex-col gap-4">

            <Card className="col-span-6" variant="outlined">
                <CardContent className="flex justify-center">
                    <Button variant="contained" onClick={shareStocks} >Request Certificate</Button>
                </CardContent>
                {/* {loadingCert && <div className="w-full flex flex-col justify-center items-center p-4">
                    <CircularProgress />
                    <Typography variant="caption">Loading Certificate...</Typography>
                </div>
                }
                <iframe src={stoneDetails.certificateUrl} title="Diamond Video" className="cert-section" onLoad={() => { setLoadingCert(false) }}></iframe> */}
            </Card>


            <Card variant="outlined">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Image" />
                        <Tab label="Video" />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    {stoneDetails.imageLink && <img src={stoneDetails.imageLink}/>}
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>

                    {stoneDetails.videoLink && <div className="table w-full"><iframe src={stoneDetails.videoLink} title="Diamond Video" className="diamond-video table-row"></iframe></div>}
                    {!stoneDetails.videoLink && <Card variant="outlined">
                        <div className="text-center text-rose-500 p-4 text-lg font-bold">Product Video not available</div>
                    </Card>}
                </TabPanel>

            </Card>


        </div>

    </div>
}

export default StoneDetails;