const mappingArray: any = [
    { name: 'Stock#' , key: "stockNo"},
    { name: 'Shape', key: "shape" }, //
    { name: 'Weight/ Carat', key: "weight" },  //
    { name: 'Clarity', key: 'clarity' }, //
    { name: 'Color', key: "color" }, //
    { name: 'Per Carat Price', key: "perCaratPrice" }, //
    { name: 'Total Price', key: "totalPrice" }, //
    { name: 'Rap Price', key: "rapPrice" }, //
    { name: 'Discount (%)', key: "discount" }, //
    { name: 'Image Link', key: "imageLink",  }, //
    { name: 'Video Link', key: "videoLink" }, //
    { name: 'Cut Grade', key: "cutGrade" }, //
    { name: 'Polish', key: "polish" }, //
    { name: 'Symmetry', key: "symmetry" }, //
    { name: 'Depth Percent', key: "depthPercent" }, //
    { name: 'Table Percent', key: "tablePercent" }, //
    { name: 'Table Open', key: "tableOpen" }, //
    { name: 'Fluorescence', key: "fluorescence" },
    { name: 'Lab', key:"lab" }, //
    { name: 'Type', key: "type" }, //
    { name: 'Report/ Certificate No', key: "certificateNo" }, //
    { name: 'Report/ Certificate Link', key: "certificateUrl" }, //
    { name: 'Measurements', key: "measurments" }, //
    { name: 'Milky', key: "milky" }, //
    { name: 'Black', key: "black" }, //
    { name: 'Crown Height', key: "crownHeight" }, //
    { name: 'Crown Angle', key: "crownAngle" }, //
    { name: 'Pavilion Height', key: "pavilionHeight" }, //
    { name: 'Pavilion Angle', key: "pavilionAngle" }, //
    { name: 'Lower Half', key: "lowerHalf" }, //
    { name: 'H&A', key: "ha" },
    { name: 'City', key: "city"},
    { name: 'State', key: "state"},
    { name: 'Country', key: "country" },
    { name: 'Eye Clear', key: "eyeClear" }, //
    { name: 'Star Length', key: "starLength" }, //
    { name: 'Key to Symbol', key: "keyToSymbol" },
    { name: 'Ratio', key: 'ratio' }, //
    { name: 'Table Inclusion', key: 'tableInclusion' }, //
    { name: 'Shade', key: 'shade' }, //
    { name: 'Side Inclusion', key: 'sideInclusion' }, //
    { name: 'Side Black', key: 'sideBlack' }, //
    { name: 'Side Open', key: 'sideOpen' }, //
    { name: 'Extra Facet', key: 'extraFacet' }, //
];

export default mappingArray