import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import "./SearchFilterPage.css";
import { useState } from "react";
import { Filter } from "./Filter";
import { ADVANCED_SHAPES, BASIC_SHAPES } from "./FiltersList";


const ShapeFilter = ({ selectedShapes, onSelect }: any) => {
    const [shape, setShape] = useState("basic");
    // const [selectedShapes, setSelectedShapes] = useState<any>([]);
    const handleShapeChange = (event: any, newShape: string) => {
        setShape(newShape);
    }

    return <div className="flex items-center gap-2">
        <div className="grid grid-cols-12 w-full">
            <div className="md:col-span-2 col-span-12 flex flex-col justify-center gap-1">
                <span className="font-bold">Shape</span>
                <div className="pb-2">
                    <ToggleButtonGroup
                        color="primary"
                        value={shape}
                        exclusive
                        onChange={handleShapeChange}
                        aria-label="Platform"
                        size="small"
                        className="toggle-filter"
                    >
                        <ToggleButton value="basic">Basic</ToggleButton>
                        <ToggleButton value="advanced">Advanced</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            <div className="md:col-span-10 col-span-12 flex flex-wrap items-center gap-2">
                {

                    shape === "basic" ?
                        <Filter options={BASIC_SHAPES} selectedOptions={selectedShapes} onSelect={onSelect} type="icon"/> :
                        <Filter options={ADVANCED_SHAPES} selectedOptions={selectedShapes} onSelect={onSelect} type="icon"/>
                }
            </div>
        </div>
    </div>
}

export default ShapeFilter;