import { TextField } from "@mui/material";

const CertificateFilter = ({certificateNo, onChangeCertNo}: any) => {

    return <div className="grid grid-cols-12 mt-4">
        <div className="md:col-span-2 col-span-12 flex items-center font-bold">Certificate No</div>
        <div className="md:col-span-4 col-span-12">
            <TextField id="outlined-basic" label="Cert. No" variant="outlined" size="small" fullWidth value={certificateNo} onChange={e => onChangeCertNo(e.target.value)}/>
        </div>
    </div>
}

export default CertificateFilter;