// import Image from "next/image";
import { Box, Divider, Drawer, Toolbar, Typography } from "@mui/material";

import styles from "./Sidenav.module.css"
import AdminSidenav from "./Admin";
import CustomerSidenav from "./Customer";
import SellerSidenav from "./Seller";
import useViewport from "../../../hooks/useViewport";
import { useEffect, useState } from "react";


const Sidenav = (props: any) => {
    const level = localStorage.getItem("level") || 'admin';
    const {width} = useViewport();
    const [isTemporary, setIsTemporary] = useState(width <= 700)
    
    useEffect(() => {
        setIsTemporary(width <= 700);
    }, [width])

    return <>
        <Drawer
            variant={isTemporary ? "temporary" : "permanent"}
            sx={{
                width: props.open ? 240 : 60,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: props.open ? 240 : 60, boxSizing: 'border-box' },
            }}
            open={props.open}
        >
            <Toolbar>
                <div className="flex justify-center items-center gap-4">
                    {props.open && <Typography variant="h5">Buy LGD</Typography>}
                </div>
            </Toolbar>
            <Divider />
            <Box sx={{ overflow: 'auto' }} className={styles.sidenavContainer}>
                { level == "client" && <CustomerSidenav open={props.open}/> }
                { level == "seller" && <SellerSidenav open={props.open}/>}
                { (level == "admin" || level == "admin_user") && <AdminSidenav open={props.open}/>} 


            </Box>
        </Drawer>
    </>
}

export default Sidenav;