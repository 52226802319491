"use client";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Card, CardContent, CircularProgress, Divider, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import CountryList from "./countries";
// import logo from "../../images/logo_final.png";
import logo from "../../images/logo_dark_v.png";

import { Link, useNavigate } from "react-router-dom";
import NotificationMessage from "../../components/common/notification/notification";
import { HomeOutlined } from "@mui/icons-material";
import axiosInstance from "../../utils/axiosInstance";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../utils/toastOption";



const RegisterPage = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { control, watch, handleSubmit } = useForm({ mode: "all" });

    const onSubmit = (data: any) => {
        setLoading(true);
        axiosInstance.post(`client`, data).then(reponse => {
            toast.success("Your registration is completed and under review. We'll let you know via email once activated.", TOAST_OPTIONS);
            setLoading(false);
            navigate("/login");
        }).catch(error => {
            toast.error(error.response.data.message, TOAST_OPTIONS);
            setLoading(false);
        })
    }

    return <div className="flex flex-col items-center justify-center gap-6 p-4 auth-bg min-h-screen">
        <div className='flex flex-row justify-center items-center gap-4'>
            <Link to={"/home"}>
                <img src={logo} alt="Logo" style={{ height: "100px" }} />
            </Link>
        </div>
        <Card variant="elevation" className="w-full md:w-2/3 xl:w-2/4 2xl:w-1/3">
            <CardContent className="flex flex-col justify-center">
                <div className="flex flex-col items-center mb-5 self-stretch">
                    <Typography variant="h5" style={{ fontWeight: "700" }}>Create an Account</Typography>
                    <Typography variant="caption">
                        Enter Following Details in order to create account
                    </Typography>
                    <Divider className="w-full" />
                </div>

                <form className="grid grid-cols-12 gap-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-span-12 text-lg font-semibold">Login Information</div>

                    <Controller
                        name="username"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Username is required"
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Username" variant="outlined" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />
                    <Controller name="email"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Email is required"
                            },
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: 'Invalid email address',
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Email" variant="outlined" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />
                    <Controller name="password"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Password is required"
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Password" variant="outlined" type="password" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />

                    <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Confirm Password is required"
                            },
                            validate: (value) => value === watch("password") || "Passwords do not match"
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Confirm Password" variant="outlined" type="password" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />


                    <div className="col-span-12 text-lg font-semibold">Personal Information</div>


                    <Controller
                        name="firstName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "First name is required"
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="First Name" variant="outlined" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />


                    <Controller
                        name="lastName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Last name is required"
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Last Name" variant="outlined" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />

                    <Controller
                        name="country"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Please select Country"
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (<FormControl size="small" error={!!error} className="col-span-12 sm:col-span-6">
                            <InputLabel>Select Country</InputLabel>
                            <Select label="Select Country" {...field}>
                                {
                                    CountryList.map(country => <MenuItem key={country.code} value={country.name}>{country.name}</MenuItem>)
                                }

                            </Select>
                            <FormHelperText>{error ? error.message : ""}</FormHelperText>
                        </FormControl>)} />

                    <Controller
                        name="companyName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Company name is required"
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Company Name" variant="outlined" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />

                    <Controller
                        name="mobileNumber"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Mobile number is required"
                            },
                            pattern: {
                                // value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                // value: /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/,
                                value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                message: 'Please enter Valid mobile number',
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Mobile Number" variant="outlined" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : "Ex: +91XXXXXXXXXX"}
                            error={!!error}
                        />)} />

                    <Controller
                        name="address"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Address is required"
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Address" variant="outlined" size="small" className="col-span-12 sm:col-span-6" multiline
                            InputProps={{
                                rows: 3, // Number of visible rows
                            }}
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />

                    <div className="col-span-12 text-lg font-semibold">Contact Details(Fill at least one contact details)</div>

                    <Controller
                        name="whatsapp"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "This field is required"
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="WhatsApp" variant="outlined" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />

                    <Controller
                        name="weChat"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "This field is required"
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="WeChat" variant="outlined" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />

                    <Controller
                        name="skype"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "This field is required"
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Skype" variant="outlined" size="small" className="col-span-12 sm:col-span-6"
                            helperText={error ? error.message : ""}
                            error={!!error}
                        />)} />


                    <div className="col-span-12 flex flex-col mt-6 gap-4">
                        <div className="grow flex gap-4">
                            {loading && <CircularProgress size={30} />}
                            <Button variant="contained" className="grow" type="submit" disabled={loading}>Submit</Button>
                            <Button onClick={() => { navigate("/login") }} className="grow" variant="outlined">Cancel</Button>
                        </div>
                        <div className="flex gap-4">
                            <Tooltip title="Go to Homepage">
                                <IconButton color='primary' onClick={() => { navigate("/home") }}>
                                    <HomeOutlined />
                                </IconButton>
                            </Tooltip>
                            <Button variant="outlined" className="grow" onClick={() => { navigate("/login") }}>Back to Login</Button>
                        </div>

                    </div>
                </form>
            </CardContent>
        </Card>
    </div>
}

export default RegisterPage;