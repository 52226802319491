import { useEffect, useState } from "react";
import isAuth from "../../../components/IsAuth/IsAuth";
import { Card, CardContent, Divider } from "@mui/material";
import { AccountCircleOutlined, BusinessOutlined, CorporateFareOutlined, DiamondOutlined, GroupOutlined, InventoryOutlined, LocationSearchingOutlined, SavedSearch, SearchOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";

const DashboardPage = () => {
    const [stats, setStats] = useState<any>(null);

    useEffect(() => {
        axiosInstance.get('stats').then(response => {
            setStats(response.data);
        })
    }, []);


    return <div className="py-5">
        <div>
            <span className="font-bold text-3xl px-4">Dashboard</span>
        </div>
        <div className="grid grid-cols-12 gap-5 mt-5">

            <Link to="/search" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-4 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                        <SearchOutlined />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-gray-700">
                            Search Diamonds
                        </p>
                    </div>
                </Card>
            </Link>

            <Link to="/stock" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full">
                        <InventoryOutlined />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-gray-700">
                            Stock
                        </p>
                    </div>
                </Card>
            </Link>

            <Link to="/web-tracking" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-yellow-500 bg-yellow-100 rounded-full">
                        <LocationSearchingOutlined />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-gray-700">
                            Web Tracking
                        </p>
                    </div>
                </Card>
            </Link>

            <Link to="/stock" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-4 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full">
                        <DiamondOutlined />
                    </div>
                    <div>
                        <p className="text-sm font-medium text-gray-600">
                            Total Diamonds
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                            {stats?.stock}
                        </p>
                    </div>
                </Card>
            </Link>

            <Link to="/saved-search" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                        {/* <CorporateFareOutlined /> */}
                        <SavedSearch />
                    </div>
                    <div>
                        <p className="text-sm font-medium text-gray-600">
                            Total Searches Today
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                            {stats?.search}
                        </p>
                    </div>
                </Card>
            </Link>

            {/* <Link to="/companies" className="md:col-span-3 col-span-12"> */}
            <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full">
                    <CorporateFareOutlined />
                </div>
                <div>
                    <p className="text-sm font-medium text-gray-600">
                        Added To Cart Today
                    </p>
                    <p className="text-lg font-semibold text-gray-700">
                        {stats?.cart}
                    </p>
                </div>
            </Card>
            {/* </Link> */}

            <Link to="/clients" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-4 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-yellow-500 bg-yellow-100 rounded-full">
                        <AccountCircleOutlined />
                    </div>
                    <div>
                        <p className="text-sm font-medium text-gray-600">
                            Total Users
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                            {stats?.clients}
                        </p>
                    </div>
                </Card>
            </Link>

            {/* <Link to="/clients" className="md:col-span-3 col-span-12"> */}
            <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full">
                    <AccountCircleOutlined />
                </div>
                <div>
                    <p className="text-sm font-medium text-gray-600">
                        Total Active Users
                    </p>
                    <p className="text-lg font-semibold text-gray-700">
                        {stats?.activeClients}
                    </p>
                </div>
            </Card>
            {/* </Link> */}

            {/* <Link to="/clients" className="md:col-span-3 col-span-12"> */}
            <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                    <AccountCircleOutlined />
                </div>
                <div>
                    <p className="text-sm font-medium text-gray-600">
                        Users Registered today
                    </p>
                    <p className="text-lg font-semibold text-gray-700">
                        {stats?.registeredToday}
                    </p>
                </div>
            </Card>
            {/* </Link> */}

            {/* <Link to="/clients" className="md:col-span-3 col-span-12"> */}
            <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full">
                    <AccountCircleOutlined />
                </div>
                <div>
                    <p className="text-sm font-medium text-gray-600">
                        Users not Attached With Seller
                    </p>
                    <p className="text-lg font-semibold text-gray-700">
                        {stats?.sellerNotAttached}
                    </p>
                </div>
            </Card>
            {/* </Link> */}

            <Link to="/sellers" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-yellow-500 bg-yellow-100 rounded-full">
                        <GroupOutlined />
                    </div>
                    <div>
                        <p className="text-sm font-medium text-gray-600">
                            Total Sellers
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                            {stats?.sellers}
                        </p>
                    </div>
                </Card>
            </Link>

            <Link to="/companies" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full">
                        <CorporateFareOutlined />
                    </div>
                    <div>
                        <p className="text-sm font-medium text-gray-600">
                            Total Companies
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                            {stats?.companies}
                        </p>
                    </div>
                </Card>
            </Link>
        </div>
    </div>

}

export default isAuth(DashboardPage);