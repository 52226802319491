import { Card, CardContent, Divider, Fab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./SearchFilterPage.css";
import ShapeFilter from "./ShapeFilter";
import CaratFilter from "./Carat";
import ColorFilter from "./Color";
import ClarityFilter from "./Clarity";
import FlourescenceFilter from "./Flourescence";
import LabFilter from "./Lab";
import CertificateFilter from "./Certificate";
import { SearchOutlined } from "@mui/icons-material";
import FinishFilter from "./Finish";
import TypeFilter from "./Type";
import RatioFilter from "./Ratio";
import { useLocation, useNavigate } from "react-router-dom";
import OtherFilters from "./Other";
import TreatmentFilter from "./Treatment";

const SearchFilterPage = () => {
    const [certificateNo, setCertificateNo] = useState("");
    const [selectedShapes, setSelectedShapes] = useState([]);
    const [selectedColorType, setSelectedColorType] = useState("white");
    const [selectedWhiteColors, setSelectedWhiteColors] = useState<any>([]);
    const [selectedFancyColors, setSelectedFancyColors] = useState<any>([]);
    const [selectedOvertone, setSelectedOvertone] = useState<any>([]);
    const [selectedIntensity, setSelectedIntensity] = useState<any>([]);
    const [selectedClarity, setSelectedClarity] = useState<any>([]);
    const [selectedCut, setSelectedCut] = useState<any>([]);
    const [selectedPolish, setSelectedPolish] = useState<any>([]);
    const [selectedSymmetry, setSelectedSymmetry] = useState<any>([]);
    const [selectedFlour, setSelectedFlour] = useState<any>([]);
    const [selectedLab, setSelectedLab] = useState<any>([]);
    const [selectedType, setSelectedType] = useState<any>([]);
    const [caratFrom, setCaratFrom] = useState<any>(null);
    const [caratTo, setCaratTo] = useState<any>(null);
    const [ratioFrom, setRatioFrom] = useState<any>(null);
    const [ratioTo, setRatioTo] = useState<any>(null);
    const [selectedTreatment, setSelectedTreatment] = useState<any>([]);
    const [isHA, setIsHA] = useState<any>(false);

    const navigate = useNavigate();
    const location = useLocation();


    const searchDiamondHandler = () => {
        let searchUrl = `/client/result?`;
        if(certificateNo) {
            searchUrl += `cert=${certificateNo}`
        } else {

            if(selectedShapes.length) {
                searchUrl += `shapes=${selectedShapes.join(',')}&`
            }

            if(caratFrom) {
                searchUrl += `cfr=${caratFrom}&`
            }

            if(caratTo) {
                searchUrl += `cto=${caratTo}&`
            }

            if(selectedColorType == 'white' && selectedWhiteColors.length) {
                searchUrl += `ctype=white&color=${selectedWhiteColors.join(",")}&`
            }

            if(selectedColorType == 'fancy') {

                searchUrl += `ctype=fancy&`
                if(selectedFancyColors.length) {
                    searchUrl += `color=${selectedFancyColors.join(",")}&`
                }

                if(selectedOvertone.length) {
                    searchUrl += `overtone=${selectedOvertone.join(",")}&`
                }

                if(selectedIntensity.length) {
                    searchUrl += `intensity=${selectedIntensity.join(",")}&`
                }

            }

            if(selectedClarity.length) {
                searchUrl += `clarity=${selectedClarity.join(",")}&`
            }
            
            if(selectedCut.length) {
                searchUrl += `cut=${selectedCut.join(",")}&`
            }
            
            if(selectedPolish.length) {
                searchUrl += `polish=${selectedPolish.join(",")}&`
            }
            
            if(selectedSymmetry.length) {
                searchUrl += `symm=${selectedSymmetry.join(",")}&`
            }
            
            if(selectedFlour.length) {
                searchUrl += `fl=${selectedFlour.join(",")}&`
            }
            
            if(selectedLab.length) {
                searchUrl += `lab=${selectedLab.join(",")}&`
            }
            if(ratioFrom) {
                searchUrl += `rfo=${ratioFrom}&`
            }

            if(ratioTo) {
                searchUrl += `rto=${ratioTo}&`
            }


            if(selectedTreatment.length) {
                searchUrl += `treatment=${selectedTreatment.join(",")}&`
            }

            if(isHA) {
                searchUrl += `ha=Yes` 
            }
            
            if(selectedType.length) {
                searchUrl += `type=${selectedType.join(",")}`
            }



        }
            
        navigate(searchUrl);



    }

    useEffect(() => {
        if(location.state) {
            const params = location.state.split("?")
            const paramsObj: any = {};
            params[1].split('&').forEach((p: string) => {
                const param = p.split("=");
                if(param.length > 1) {
                    paramsObj[param[0]] = param[1].split(",")
                }
            })
            console.log(paramsObj);
            if(paramsObj.cert) {
                setCertificateNo(paramsObj.cert[0]);
            }

            if(paramsObj.shapes) {
                setSelectedShapes(paramsObj.shapes);
            }
            if(paramsObj.cfr) {
                setCaratFrom(paramsObj.cfr[0]);
            }
            if(paramsObj.cto) {
                setCaratTo(paramsObj.cto[0]);
            }
            if(paramsObj.ctype) {
                setSelectedColorType(paramsObj.ctype[0]);
                if(paramsObj.ctype[0] == "white" && paramsObj.color) {
                    setSelectedWhiteColors(paramsObj.color)
                }

                if(paramsObj.ctype[0] == "fancy"){
                    if(paramsObj.color) {
                        setSelectedFancyColors(paramsObj.color)
                    }

                    if(paramsObj.overtone) {
                        setSelectedOvertone(paramsObj.overtone);
                    }

                    if(paramsObj.intensity) {
                        setSelectedIntensity(paramsObj.intensity.map((i: string) => i.replace("%20", " ")));
                    }
                }
            }
            if(paramsObj.clarity){
                setSelectedClarity(paramsObj.clarity);
            }

            if(paramsObj.cut) {
                setSelectedCut(paramsObj.cut);
            }

            if(paramsObj.polish) {
                setSelectedPolish(paramsObj.polish)
            }
            if(paramsObj.symm) {
                setSelectedSymmetry(paramsObj.symm);
            }
            if(paramsObj.fl) {
                setSelectedFlour(paramsObj.fl.map((i: string) => i.replace("%20", " ")));
            }
            if(paramsObj.lab) {
                setSelectedLab(paramsObj.lab);
            }
            if(paramsObj.type) {
                setSelectedType(paramsObj.type);
            }

            if(paramsObj.treatment) {
                setSelectedTreatment(paramsObj.treatment.map((i: string) => i.replace("%20", " ")));
            }

            if(paramsObj.ha) {
                setIsHA(true);
            }
            if(paramsObj.rfo) {
                setRatioFrom(paramsObj.rfo[0]);
            }

            if(paramsObj.rto) {
                setRatioTo(paramsObj.rto[0]);
            }

        }
    }, [])

    return <>
        <div className="p-4 flex justify-between items-center mt-2">
            <span className="font-bold text-3xl">Diamond Search</span>
        </div>

        <Card variant="outlined">
            <CardContent className="flex flex-col gap-4">
                {/* <div className="font-bold text-2xl">
                    Basic
                </div> */}


                <CertificateFilter certificateNo={certificateNo} onChangeCertNo={setCertificateNo}/>
                <Divider/>
                <ShapeFilter selectedShapes={selectedShapes} onSelect={setSelectedShapes} />
                <Divider/>
                <CaratFilter caratFrom={caratFrom} onChangeCaratFrom={setCaratFrom} caratTo={caratTo} onChangeCaratTo={setCaratTo}/>
                <Divider/>
                <ColorFilter selectedColorType={selectedColorType} onSelectType={setSelectedColorType} selectedWhiteColors={selectedWhiteColors} onSelectWhite={setSelectedWhiteColors} selectedFancyColors={selectedFancyColors} onSelectFancy={setSelectedFancyColors} selectedOvertone={selectedOvertone} onSelectOvertone={setSelectedOvertone} selectedIntensity={selectedIntensity} onSelectIntensity={setSelectedIntensity} />
                <Divider/>
                <ClarityFilter selectedClarity={selectedClarity} onSelect={setSelectedClarity} />
                <Divider/>
                <FinishFilter selectedCut={selectedCut} onSelectCut={setSelectedCut} selectedPolish={selectedPolish} onSelectPolish={setSelectedPolish} selectedSymmetry={selectedSymmetry} onSelectSymmetry={setSelectedSymmetry}/>
                <Divider/>
                <FlourescenceFilter selectedFlour={selectedFlour} onSelect={setSelectedFlour} />
                <Divider/>
                <LabFilter selectedLab={selectedLab} onSelect={setSelectedLab} />
                <Divider/>
                <TypeFilter selectedType={selectedType} onSelect={setSelectedType}/>
                <Divider/>
                <RatioFilter ratioFrom={ratioFrom} onChangeRatioFrom={setRatioFrom} ratioTo={ratioTo} onChangeRatioTo={setRatioTo}/>
                <Divider/>
                <TreatmentFilter selectedTreatment={selectedTreatment} onSelect={setSelectedTreatment}/>
                <Divider/>
                <OtherFilters isHA={isHA} onChangeHA={setIsHA}/>

                {/* <Fab color="primary" variant="extended" className="search-button" size="small">
                    <SearchOutlined/>
                    <span>&nbsp;Search Diamond</span>
                </Fab> */}
                <button className="search-button" onClick={searchDiamondHandler}>
                    <span className="icon">
                        <SearchOutlined />
                    </span>
                    <span className="text">Search Diamonds</span>
                </button>
            </CardContent>
        </Card>
    </>
}

export default SearchFilterPage;