import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { DeleteOutlined, EditOutlined, LockReset, PasswordSharp, PersonAddOutlined, ResetTv } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Switch, Tooltip, Typography } from "@mui/material";

import isAuth from "../../../components/IsAuth/IsAuth";
import axiosInstance from "../../../utils/axiosInstance";
import ResetPassword from "../../../components/ResetPassword/ResetPassword";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../../utils/toastOption";

const ThirdPartyPage = () => {
	const navigate = useNavigate();
	const [thirdPartyVendorData, setThridPartyVendorData] = useState([]);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isDialogOpenResetPassword, setIsDialogOpenResetPassword] = useState(false);
	const [recordToBeDeleted, setRecordToBeDeleted] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [username, setUserName] = useState(null);

	const columns = useMemo(() => [
		{
			accessorKey: 'user.username', //access nested data with dot notation
			header: 'Username',
			size: 150,
		},
		{
			accessorKey: 'name', //access nested data with dot notation
			header: 'Name',
			size: 150,
		},
		{
			accessorKey: 'contact_no', //normal accessorKey
			header: 'Contact No',
			size: 200,
		},
		{
			accessorKey: 'email',
			header: 'Email',
			size: 150,
		},
		{
			// Add an edit button column
			accessorKey: 'account_status',
			header: 'Account Status',
			size: 100,
			headerStyle: {
				textAlign: 'center' // Align the header text to the right
			},
			Cell: ({ row }: any) => (
				<div style={{ textAlign: 'center' }}>
					<Switch
						checked={row.original.user.isActive} // assuming 'active' field is a boolean
						onChange={async (event) => {
							const newValue = event.target.checked;
							const updatedFields = {
								isActive: newValue
							};
							const response = await axiosInstance.put(`users/${row.original.user._id}`, updatedFields);
							if (response.status == 200) {
								getThirdPartyVendors();
							}
						}}
					/>
				</div>
			),
		},
		{
			// Add an edit button column
			accessorKey: 'action',
			header: 'Action',
			size: 100,
			headerStyle: {
				textAlign: 'center' // Align the header text to the right
			},
			Cell: ({ row }: any) => (
				<div style={{ textAlign: 'center' }}>
					<IconButton size="small" onClick={() => { navigate("/thirdparty/edit/" + row.original._id); }} aria-label="edit"
						sx={{ borderBottom: 'none' }} color="primary">
						<EditOutlined />
					</IconButton>
					<IconButton size="small" onClick={() => { setIsDialogOpenResetPassword(true); setUserName(row.original.user.username) }} aria-label="reset password"
						sx={{ borderBottom: 'none' }} color="success">
						<LockReset />
					</IconButton>
					<IconButton size="small" onClick={() => { setIsDialogOpen(true); setRecordToBeDeleted(row.original._id) }} aria-label="delete"
						sx={{ borderBottom: 'none' }} color="error">
						<DeleteOutlined />
					</IconButton>
				</div>
			),
		},
	], [])

	const table = useMaterialReactTable({
		columns,
		data: thirdPartyVendorData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
		enableFullScreenToggle: false,
		enableDensityToggle: false,
		enableHiding: false,
		initialState: { density: 'compact' },
		state: {
			isLoading
		},
		renderTopToolbarCustomActions: ({ table }: any) => {
			return <Tooltip title="New Third-Party Vendor">
				<IconButton size="small" color="primary" onClick={() => navigate('/thirdparty/add')}>
					<PersonAddOutlined />
				</IconButton>
			</Tooltip>
		},
		muiTableBodyCellProps: ({ column }) => ({
			//conditionally style pinned columns
			sx: {
				padding: "0px 0.5rem"
			},
		}),
	});

	const getThirdPartyVendors = () => {
		setLoading(true);
		axiosInstance.get(`thirdparty`).then((data: any) => {
			setThridPartyVendorData(data.data);
			setLoading(false);
		}).catch(e => {
			console.log(e);
			setLoading(false);
		})
	}

	async function handleDelete() {
		try {
			const response = await axiosInstance.delete(`thirdparty/${recordToBeDeleted}`);
			if (response.status == 200) {
				handleClose();
				getThirdPartyVendors();
				toast.success("Third-Party Deleted Successfully", TOAST_OPTIONS);
			}
		} catch (error) {
			console.error('Error deleting record:', error);
			throw new Error('Failed to delete record');
		}
	}

	const handleClose = () => {
		setIsDialogOpen(false);
	};


	const handleCloseResetPassword = () => {
		setIsDialogOpenResetPassword(false);
	};

	useEffect(() => {
		getThirdPartyVendors();
	}, [])
	return <>


		<div className="p-4">
			<span className="font-bold text-3xl">Third-Party Vendors</span>
		</div>

		<div>
			<MaterialReactTable table={table} />
		</div>

		<Dialog
			open={isDialogOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Do you want to Delete this Seller?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Once you delete this third-party vendor, the action cannot be undo. Additionally, any associated data may be affected. Are you sure you want to proceed with the deletion?
				</DialogContentText>
			</DialogContent>
			<DialogActions>

				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleDelete} autoFocus color="error">
					Continue
				</Button>
			</DialogActions>
		</Dialog>

		<ResetPassword showDialog={isDialogOpenResetPassword} username={username} onClose={handleCloseResetPassword}></ResetPassword>
	</>
}

export default isAuth(ThirdPartyPage);