"use client"
import { useEffect, useState } from "react";

import axiosInstance from "../../../../utils/axiosInstance";
import CompanyDetails from "../../../../components/CompanyDetails/CompanyDetails";
import isAuth from "../../../../components/IsAuth/IsAuth";
import { useParams } from "react-router-dom";


const EditCompanyPage = () => {
	const [companyData, setCompanyData] = useState();
	const params = useParams();
	const getCompanyById = () => {
		axiosInstance.get(`company/${params.id}`).then((data: any) => {
			setCompanyData(data.data);
		})
	}

	useEffect(() => {
		getCompanyById();
	}, [])

	return <CompanyDetails mode="edit" companyData={companyData}></CompanyDetails>
}
export default isAuth(EditCompanyPage);