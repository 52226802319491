
import { Tooltip } from "@mui/material";
import "./SearchFilterPage.css";
import { useState } from "react";
import { CLARITY_LIST } from "./FiltersList";
import { Filter } from "./Filter";

const ClarityList = [
    { name: "FL" },
    { name: "IF" },
    { name: "VVS1" },
    { name: "VVS2" },
    { name: "VS1" },
    { name: "VS2" },
    { name: "SI1" },
    { name: "SI2" },
    { name: "SI3" },
    { name: "I1" },
    { name: "I2" },
    { name: "I3" },
]

const ClarityFilter = ({ selectedClarity, onSelect }: any) => {

    return <div className="grid grid-cols-12 w-full">
        <div className="md:col-span-2 col-span-12 flex flex-col justify-center gap-1">
            <span className="font-bold">Clarity</span>
        </div>
        <div className="md:col-span-10 col-span-12 flex flex-wrap items-center gap-2">
            {/* {ClarityList.map((e: any, i: any) => <Tooltip title={e.name} key={i}><div key={i} className={`selection-button-text ${selectedClarity.includes(e.name) ? 'selected' : ""}`} onClick={() => toggleSelected(e.name)}>{e.name}</div></Tooltip>)} */}
            <Filter options={CLARITY_LIST} selectedOptions={selectedClarity} onSelect={onSelect}/>
        </div>
    </div>
}

export default ClarityFilter;