import { useRef, useState } from "react";
import { SvgIcon, Tooltip } from "@mui/material";
import { BannerLayer, Parallax, ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import { BusinessOutlined, CallOutlined, EmailOutlined, WhatsApp } from "@mui/icons-material";

import "./LandingPageParallax.css";
import logo from "../../images/logo_dark.png";//"../../images/logo.svg";

import banner from "../../images/bg_hero.png"
import about from "../../images/about.png";
import certified from "../../images/certified.png";
import quality from "../../images/quality.png";
import valueForMoney from "../../images/value.png";
import ethics from "../../images/engagement.png";
import ecoFriendly from "../../images/save-the-world.png";
import innovative from "../../images/innovation.png";
// import feed from "../../images/Feed-section.png";
import feed from "../../images/feed2.png";
import { ReactComponent as WeChat } from "../../images/wechat.svg";
import igi from "../../images/igi.png";
import ContactUs from "../../components/ContactUs/ContactUs";

const LandingHeader = ({ colorChange, scrollTo }: any) => {
    return <header className={`flex items-center justify-between p-4 header w-full z-10 ${colorChange ? "bg-dark" : ""}`}>
        <div className="flex items-center gap-4">
            <img src={logo} alt={"Logo"} style={{ height: "60px" }} />
        </div>
        <div className="flex items-center gap-6 font-semibold">
            <button onClick={() => { scrollTo("about") }}>About Us</button>
            <button onClick={() => { scrollTo("contact") }}>Contact Us</button>
            <a href="/login" className="login-button mr-6">Login</a>
        </div>
    </header>
}

const AdvancedBannerTop = () => {
    const background: BannerLayer = {
        image: banner,
        translateY: [0, 5],
        opacity: [0.8, 0],
        scale: [1.1, 1, "easeOutCubic"],
        // speed: -20,
        shouldAlwaysCompleteAnimation: true
    };

    const headline: BannerLayer = {
        children: (
            <Parallax speed={-100} className="grid grid-cols-2 h-full p-6 text-white">
                <div className="col-span-1 flex flex-col justify-center items-center gap-6">
                    <div>
                        <p className="text-6xl text-center hero-content">Discover the Future of Diamonds</p>
                        <p className="text-xl mt-4 text-center font-light">Sustainable, Ethical, and Stunning Lab-Grown Diamonds</p>
                    </div>
                    <a href="/register" className="login-button">Get Started</a>
                </div>
                <div className="col-span-1"></div>
            </Parallax>
        ),
    };

    const gradientOverlay: BannerLayer = {
        opacity: [0, 1, "easeOutCubic"],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: <div className="gradient inset" />
    };

    return (
        <ParallaxBanner
            layers={[background, gradientOverlay, headline]}
            // gradientOverlay
            className="full"
        />
    );
};

const BasicShapes = [
    {
        name: "Round",
        icon: 'icon-round',
    },
    {
        name: "Oval",
        icon: 'icon-Single_6',
    },
    {
        name: "Pear",
        icon: 'icon-Single_5',
    },
    {
        name: "Marquise",
        icon: 'icon-Single_7',
    },
    {
        name: "Heart",
        icon: 'icon-Single_8',
    },
    {
        name: "Emerald",
        icon: 'icon-Single_9',
    },
    {
        name: "Square Emerald",
        icon: 'j-icon-Single_11',
    },
    {
        name: "Princess",
        icon: 'icon-Single_4',
    },
    {
        name: "Radiant",
        icon: 'j-icon-Single_12',
    },
    {
        name: "Cushion",
        icon: 'j-icon-Single_14',
        selected: true
    },
    {
        name: "Baguette",
        icon: 'j-icon-Single_15',
    },
]

const AvailableShapes = () => {
    return <div className="flex flex-col gap-6 p-6 items-center text-white pt-10 pb-10 bg-dark">

        <h1 className="text-4xl font-semibold">Our Collection</h1>

        <div className="text-4xl w-full flex justify-center gap-7 mt-7 mb-7">
            {BasicShapes.map((shape, i) => (
                <Parallax
                    key={`copy-${i}`}
                    translateX={[200 * (i - 5), 0]}
                // speed={1 * (i - 4)}
                >
                    <Tooltip title={shape.name}><div className={`shape ${shape.icon}`}></div></Tooltip>
                </Parallax>
            ))}
        </div>
    </div>
}

const AboutBanner_old = () => {
    const background: BannerLayer = {
        image: about,
        translateY: [0, 15],
        translateX: [0, -10],
        opacity: [1, 0.5],
        scale: [0.9, 0.8, "easeOutCubic"],
        shouldAlwaysCompleteAnimation: true
    };

    const content: BannerLayer = {
        children: (
            <Parallax speed={-100} className="grid grid-cols-2 h-full p-6">
                <div className="col-span-1"></div>
                <div className="col-span-1 flex flex-col justify-center items-center gap-6">
                    <div>

                        <p className="text-4xl text-center font-semibold">About Buy LGD</p>
                        <p className="text-xl mt-4 font-light">At Buy LGD, we revolutionize the diamond industry by providing stunning, ethically-sourced lab-grown diamonds. Our commitment to quality and sustainability ensures that every diamond is not only beautiful but also environmentally friendly and conflict-free.</p>
                        <p className="text-xl mt-4 font-light">From carbon seed to stunning gemstone, our diamonds are crafted with precision and care. Learn more about the science and artistry behind each diamond.</p>
                    </div>
                </div>
            </Parallax>
        ),
    };

    return (
        <ParallaxBanner
            layers={[background, content]}
            className="h-screen bg-white"
        />
    );

}


const AboutBanner = () => {

    return <div className="bg-white flex flex-col items-center gap-7 p-10 " style={{ paddingTop: "50px" }}>
        <div className="w-full grid grid-cols-2 mt-10 pb-10">
            <div className="flex justify-center h-full items-center">
                <Parallax speed={20} className="flex justify-center w-full">
                    <img src={about} className="feed-image" />
                </Parallax>
            </div>

            <Parallax speed={-10} className="w-full flex flex-col justify-center gap-6">
                <div className="text-3xl px-10">About Buy LGD</div>
                <div className="text-xl px-10 font-light">At Buy LGD, we revolutionize the diamond industry by providing stunning, ethically-sourced lab-grown diamonds. Our commitment to quality and sustainability ensures that every diamond is not only beautiful but also environmentally friendly and conflict-free.</div>
                <div className="text-xl px-10 font-light">From carbon seed to stunning gemstone, our diamonds are crafted with precision and care. Learn more about the science and artistry behind each diamond.</div>

            </Parallax>

        </div>
    </div>

    // return <div className="flex flex-col items-center justify-center gap-6 px-4 py-8 h-screen bg-white about-alter">
    //     <h1 className="text-3xl text-center font-semibold">About Buy LGD</h1>
    //     <div className="text-xl text-center w-1/2">At Buy LGD, we revolutionize the diamond industry by providing stunning, ethically-sourced lab-grown diamonds. Our commitment to quality and sustainability ensures that every diamond is not only beautiful but also environmentally friendly and conflict-free.</div>
    //     <p className="text-xl mt-4 font-light text-center w-1/2">From carbon seed to stunning gemstone, our diamonds are crafted with precision and care. Learn more about the science and artistry behind each diamond.</p>
    // </div>

}

const Card = ({ image, title, content }: any) => {
    return <div className="bg-white m-4 rounded-lg shadow-lg card text-center p-6 flex flex-col items-center justify-center gap-4 w-1/4">
        <img src={image} width={80} className="mt-4" />
        <h1 className="text-2xl font-bold px-4">{title}</h1>
        <p className="text-xl mb-4 px-4">{content}</p>
    </div>
}

const WhyLabGrownDiamond = () => {
    return <>
        <div className="px-7 flex flex-col items-center gap-7" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <h1 className="text-4xl font-semibold">Why Choose Lab-Grown Diamonds?</h1>
            <div className="w-full flex flex-wrap justify-center">
                <Card image={quality} title="Quality and Purity" content="Lab-grown diamonds possess the same physical, chemical, and optical properties as mined diamonds. They are graded by the same standards, ensuring you get a diamond of exceptional quality and clarity." />
                <Card image={valueForMoney} title="Value for Money" content="Enjoy the luxury of diamonds at a fraction of the cost. Lab-grown diamonds offer significant savings, allowing you to invest in a higher quality diamond or a larger carat size for the same price as a mined diamond." />
                <Card image={certified} title="Certified" content="Lab-grown diamonds are certified by reputable institutions such as GIA, IGI, and GCAL. This ensures that you can trust their authenticity and always receive a genuine product without any risk of fraud." />
                <Card image={ethics} title="Ethical Assurance" content="Say goodbye to conflict diamonds. Our lab-grown diamonds are 100% conflict-free, ensuring that your purchase does not contribute to unethical labor practices or financing of conflicts." />
                <Card image={ecoFriendly} title="Environmental Impact" content="Our diamonds are created with minimal environmental disruption compared to traditional mining. This means no destructive mining practices, reduced carbon footprint, and a commitment to preserving natural habitats." />
                <Card image={innovative} title="Innovative and Modern" content="Lab-grown diamonds are at the forefront of technological innovation. Embrace the future with a diamond that symbolizes progress, sustainability, and modern elegance." />
            </div>
        </div>
    </>
}

const WhyChooseBuyLGD = () => {
    return <div className="bg-white flex flex-col items-center gap-7 p-10 " style={{ paddingTop: "50px" }}>
        <div className="w-full grid grid-cols-2 mt-10 pb-10">
            <Parallax speed={-10} className="w-full flex flex-col justify-center gap-6">
                <div className="text-3xl px-10">Virtual Diamond Showcase</div>
                <div className="text-xl px-10 font-light">Enhance your website with our lab-grown diamond collection, giving you full customization over size, quality, shapes, and more through our state-of-the-art, low-latency APIs.</div>
                <div className="text-xl px-10 font-light">
                    <p className="mt-5">- Quickly scale your online diamond business</p>
                    <p className="mt-5">- Offer the perfect diamond to suit every visitor's needs on your website.</p>
                    <p className="mt-5">- Quickly scale your online diamond business</p>
                </div>
            </Parallax>
            <div className="flex justify-center h-full items-center">
                <Parallax speed={20} className="flex justify-center w-full">
                    <img src={feed} className="feed-image" />
                </Parallax>
            </div>
        </div>
    </div>
}

const ContactUsSection = () => {
    return <div>
        <div className="px-7 grid grid-cols-2" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <div className="px-10 flex flex-col gap-7">
                <h1 className="text-4xl font-semibold">Contact Us</h1>

                <div className="font-light flex flex-col gap-3">
                    <p className="flex gap-4 text-xl">
                        <EmailOutlined /> <a href="mailto:info@buylgd.com" >info@buylgd.com</a>
                    </p>

                    <p className="flex gap-4 text-xl">
                        <WhatsApp /> <a href="https://api.whatsapp.com/send?phone=8615277457663">+8615277457663</a>
                    </p>

                    <p className="flex gap-4 text-xl">
                        <SvgIcon component={WeChat} style={{ fontSize: '2rem' }} /> qq837558701
                    </p>

                    <p className="flex gap-4 text-xl">
                        <CallOutlined /> <a href="tel:+8615277457663">+8615277457663</a>
                    </p>

                    <p className="flex gap-4 text-xl">
                        <BusinessOutlined />
                        <div>
                            <p>Jinzhan Jewelry Plaza, Shuibei 1st Road,</p>
                            <p>Luohu District,</p>
                            <p>Shenzhen City,</p>
                            <p>Guangdong Province</p>
                        </div>
                    </p>

                    <p className="flex gap-4 text-xl">
                        <BusinessOutlined />
                        <div>
                            广东省深圳市罗湖区水贝一路金展珠宝广场
                        </div>
                    </p>

                </div>
            </div>

            <div className="px-10 flex flex-col gap-7">
                <div>
                    <h1 className="text-4xl font-semibold">Have Questions?</h1>
                    <p className="font-light">
                        We're here to help. Reach out to us via email, phone, or the contact form, and we'll get back to you as soon as possible.
                    </p>
                </div>
                <div className="flex flex-col gap-4">
                    <ContactUs />
                </div>
            </div>
        </div>

        <div className="flex flex-col items-center gap-7 bg-dark" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <h1 className="text-4xl font-semibold">Certified By</h1>
            <div className="flex justify-center gap-10">
                <img src={igi} width={150} />
                <img src="https://4cs.gia.edu/wp-content/uploads/2016/06/GIA-logo-white.png" width={150} />
            </div>
        </div>
    </div>
}

const FooterSection = () => {
    return <div className="flex justify-center bg-black text-white p-6">
        <span className="font-semibold">
            © 2024 Buy Labgrown Diamonds. All Rights Reserved.
        </span>
    </div>
}

const LandingPageParallax = () => {
    const [colorChange, setColorchange] = useState(false);
    const topBanner = useRef<any>(null);
    const aboutRef = useRef<any>(null);
    const contactRef = useRef<any>(null);
    const changeNavbarColor = () => {
        if (topBanner.current) {
            if (window.scrollY >= (topBanner.current.clientHeight - 89)) {
                setColorchange(true);
            } else {
                setColorchange(false);
            }
        }
    };
    window.addEventListener("scroll", changeNavbarColor);

    const scrollTo = (section: string) => {
        if (section == "about" && aboutRef && aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

        if (section == "contact" && contactRef && contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    return <div className="landing-page-container">
        <LandingHeader colorChange={colorChange} scrollTo={scrollTo} />
        <ParallaxProvider>
            < div ref={topBanner}>
                <AdvancedBannerTop />
            </div>
            <AvailableShapes />
            <AboutBanner />
            <WhyLabGrownDiamond />
            <div ref={aboutRef}>
                <WhyChooseBuyLGD />
            </div>
            <div ref={contactRef}>
                <ContactUsSection />
            </div>
            <FooterSection />
        </ParallaxProvider>
    </div>

}

export default LandingPageParallax;