import { useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import "./StockList.css";

import { MediaDialog, RowActionsMenu } from "./StockTableComponents";
import StockGrid from "../StockGrid/StockGrid";


const Stats = ({ table, totalCount }: any) => {

    const getSum = (field: string) => {
        let sum = 0;
        selectedRows.forEach((e: any) => {
            sum += (e.original[field] ? +e.original[field] : 0);
        })
        return sum > 0 ? sum.toFixed(2) : 0;
    }

    const getAverage = (field: string) => {
        let sum = 0;
        selectedRows.forEach((e: any) => {
            sum += (e.original[field] ? +e.original[field] : 0);
        })
        const avg = (sum / selectedRows.length);
        return avg > 0 ? avg.toFixed(2) : 0;
    }

    const selectedRows = useMemo(() => table.getSelectedRowModel().rows, [table.getSelectedRowModel().rows])

    return <div className="h-full flex items-center gap-4 my-auto">
        <div>Selected:&nbsp;<span className="font-bold">{selectedRows.length}/{totalCount}</span></div>
        <div>Total Weight:&nbsp;<span className="font-bold">{getSum('weight')}</span></div>
        {/* <div>Discount %:&nbsp; <span className="font-bold">{getAverage('discount')}</span></div> */}
        <div>Average P/CT:&nbsp; <span className="font-bold">{getAverage('perCaratPrice')}</span></div>
        <div>Total:&nbsp; <span className="font-bold">{getSum('totalPrice')}</span></div>
    </div>
}

const StockList = (props: any) => {
    const [openMediaDialog, setOpenMediaDialog] = useState(false);
    const [urlToDiplay, setUrlToDiplay] = useState("");
    const [mediaType, setMediaType] = useState("");
    const [loadingCert, setLoadingCert] = useState(false);
    const [selectedRows, setSelectedRows] = useState({});
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState<any>([]);
    const [clickedRow, setClickedRow] = useState<any>([]);

    useEffect(() => {
        props.onRowsSelect(selectedRows);
    }, [selectedRows])

    useEffect(() => {
        if (props.sorting) {
            props.onSort(sorting)
        }
    }, [sorting])

    useEffect(() => {
        if (props.manualSearch == true) {
            props.onSearch(globalFilter);
        }
    }, [globalFilter])

    const handleDialogClose = () => {
        setUrlToDiplay("");
        setOpenMediaDialog(false);
        setMediaType("");
        setLoadingCert(false);
    }


    const columns = useMemo(() => [
        {
            accessorKey: "type",
            header: "Type",
            size: 60,
        },
        {
            accessorKey: 'lab',
            header: 'Lab',
            size: 50,
        },
        {
            accessorKey: 'certificateNo',
            header: 'Report No',
            size: 100,
            // accessorFn: (row: any): string => (row.certificateNo ? row.certificateNo : "N/A"),
        },
        {
            accessorKey: "location",
            header: "Location",
            size: 60
        },
        {
            accessorKey: 'shape',
            header: 'Shape',
            size: 120,
        },
        {
            accessorKey: 'weight', // To be added in database
            header: 'Weight',
            size: 70,
        },
        {
            accessorKey: 'color',
            header: 'Color',
            size: 120,
        },
        {
            accessorKey: 'clarity',
            header: 'Clarity',
            size: 70,
        },
        {
            accessorKey: 'cutGrade',
            header: 'Cut',
            size: 80,
            enableSorting: false,
        },
        {
            accessorKey: 'polish',
            header: 'Polish',
            size: 60,
            enableSorting: false,
        },
        {
            accessorKey: 'symmetry',
            header: 'Symm',
            size: 60,
            enableSorting: false,
        },
        {
            accessorKey: 'fluorescence',
            header: 'Flor',
            size: 80,
            enableSorting: false,
        },
        {
            accessorKey: 'rap',
            header: 'Rap',
            size: 80,
            enableSorting: false,
        },
        {
            accessorKey: 'discount',
            header: 'Disc',
            size: 80,
            accessorFn: (row: any) => `${row.discount ? row.discount.toFixed(2) : ''}`,
            // enableSorting: false,
        },
        {
            accessorKey: 'perCaratPrice',
            header: 'P/CT',
            size: 80,
            accessorFn: (row: any) => `${row.perCaratPrice ? row.perCaratPrice.toFixed(2) : ''}`,
            // enableSorting: false,
        },
        {
            accessorKey: 'totalPrice',
            header: ' Amount',
            size: 80,
            accessorFn: (row: any) => `${row.totalPrice ? row.totalPrice.toFixed(2) : ''}`,
            // enableSorting: false
        },
        {
            accessorKey: 'measurments',
            header: 'Measurement',
            size: 120,
            enableSorting: false,
        },
        {
            accessorKey: 'depthPercent',
            header: 'Depth',
            size: 60,
            enableSorting: false,
        },
        {
            accessorKey: 'tablePercent',
            header: 'Table',
            size: 70,
            enableSorting: false,
        },
        {
            accessorKey: 'ratio',
            header: 'L/W Ratio',
            size: 60,
            enableSorting: false,
        },
        {
            accessorKey: 'shade',
            header: 'Shade',
            size: 60,
            enableSorting: false,
        },
        {
            accessorKey: 'keyToSymbol',
            header: 'Key to Symbol',
            size: 80,
            enableSorting: false,
        },
        {
            accessorKey: "company_code",
            header: "Company Code",
            size: 80,
            enableSorting: false,
        }
    ], []);

    const stockTable = useMaterialReactTable({
        columns,
        data: props.stockData,
        enableFullScreenToggle: false,
        enableRowSelection: true,
        enableSelectAll: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableRowVirtualization: true,
        enableDensityToggle: false,
        enableHiding: false,
        enableColumnPinning: true,
        manualPagination: true,
        rowCount: props.totalCount,
        positionToolbarAlertBanner: "none",
        enableColumnFilters: false,
        enableColumnActions: false,
        enableRowActions: true,
        enableFilters: props.manualSearch || false,
        manualFiltering: props.manualSearch || false,
        enableSorting: props.sorting || false,
        manualSorting: props.sorting || false,
        positionActionsColumn: 'first',
        initialState: {
            showColumnFilters: false,
            density: "compact",
            columnOrder: [
                "view",
            ],
            columnPinning: { left: ['mrt-row-select', 'mrt-row-actions'], },
            columnVisibility: { company_code: localStorage.getItem('level') == "admin" ? true : false }
        },
        state: {
            isLoading: props.pagination.pageIndex == 0 && props.loading,
            showProgressBars: props.pagination.pageIndex > 0 && props.loading,
            rowSelection: selectedRows,
            pagination: props.pagination,
            globalFilter,
            sorting
        },
        getRowId: (row) => row._id,
        onRowSelectionChange: setSelectedRows,
        onSortingChange: setSorting,
        onPaginationChange: (p) => props.setPagination(p),
        onGlobalFilterChange: setGlobalFilter,
        renderRowActionMenuItems: ({ closeMenu, row }: any) => {
            return RowActionsMenu({ row, setUrlToDiplay, setOpenMediaDialog, setMediaType, setLoadingCert, closeMenu });
        },
        muiTableBodyCellProps: () => ({
            sx: {
                padding: "0px 0.5rem"
            },
        }),
        muiTableContainerProps: {
            ref: tableContainerRef,
            sx: {
                maxHeight: "calc(100vh - 260px)"
            },
        },
        muiPaginationProps: {
            rowsPerPageOptions: [50, 100, 200],
        },
        muiTableHeadCellProps: {
            sx: {
                textTransform: "uppercase",
                // backgroundColor: "#1A4B71",
                // color: "#FFFFFF"
            },
        },
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: 'DNA', //change header text
            },
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Stats table={table} totalCount={props.totalCount} />
        ),
        muiTableBodyRowProps: ({ row }: any) => ({
            onClick: () => {
                const selectedrows = clickedRow;
                setClickedRow([...selectedrows, row.id])
                window.open(`/stock/${row.id}`, '_blank')
            },
            sx: {
                cursor: 'pointer',
                '& td': {
                    backgroundColor: clickedRow.includes(row?.id) ? "#BDBDBD" : "inherit"
                }, //rgba(26, 75, 113, 0.2);
                '& td[data-pinned="true"]:before': {
                    backgroundColor: clickedRow.includes(row?.id) ? "#BDBDBD" : "inherit"
                }

            },
        }),
    })
    return <>
        {props.displayType == "grid" ?
            <StockGrid {...props} /> : <div className="list-table"><MaterialReactTable table={stockTable} /></div>
        }

        <MediaDialog openMediaDialog={openMediaDialog} mediaType={mediaType} handleDialogClose={handleDialogClose} loadingCert={loadingCert} urlToDiplay={urlToDiplay} setLoadingCert={setLoadingCert} />
    </>
};

export default StockList;