import { useEffect, useState } from "react";

const useLevel = () => {
    const [level, setLevel] = useState<any>("");

    useEffect(() => {
        setLevel(localStorage.getItem("level"));
    }, [])

    return {level};
}

export default useLevel;