import { DashboardOutlined, LocationSearchingOutlined, ManageSearchOutlined, SavedSearchOutlined, SupervisedUserCircleOutlined } from "@mui/icons-material";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const SellerSidenav = (props: any) => {
    const { pathname } = useLocation();
    return <>
        <Tooltip placement={props.open? "bottom":"right"} title="Dashboard">
            <List>
                <Link to="/seller/dashboard">
                    <ListItem key="dashboard" selected={pathname.toLowerCase().includes("dashboard")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <DashboardOutlined color={!props.open && pathname.toLowerCase().includes("dashboard") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Dashboard</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </List>
        </Tooltip>

        <Tooltip placement={props.open? "bottom":"right"} title="Clients">
            <List>
                <Link to="/seller/clients">
                    <ListItem key="clients" selected={pathname.toLowerCase().includes("seller/clients")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <SupervisedUserCircleOutlined color={!props.open && pathname.toLowerCase().includes("seller/clients") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Clients</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </List>
        </Tooltip>

        <Tooltip placement={props.open? "bottom":"right"} title="Web Tracking">
            <List>
                <Link to="/seller/web-tracking">
                    <ListItem key="webTracking" selected={pathname.toLowerCase().includes("/seller/web-tracking")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <LocationSearchingOutlined color={!props.open && pathname.toLowerCase().includes("/seller/web-tracking") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Web Tracking</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </List>
        </Tooltip>

        <Tooltip placement={props.open? "bottom":"right"} title="Saved Search">
            <List>
                <Link to="/seller/saved-search">
                    <ListItem key="savedSearch" selected={pathname.toLowerCase().includes("/seller/saved-search")} disablePadding sx={{ display: 'block' }}>
                        <div className={props.open ? "content" : ""}>
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 'center', px: 2.5, }}>
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: 'auto', justifyContent: 'center', }}>
                                    <SavedSearchOutlined color={!props.open && pathname.toLowerCase().includes("/seller/saved-search") ? "primary" : "inherit"} />
                                </ListItemIcon>
                                {props.open && <ListItemText className="ml-3">Saved Search</ListItemText>}
                            </ListItemButton>
                        </div>
                    </ListItem>
                </Link>
            </List>
        </Tooltip>
    </>
}

export default SellerSidenav;