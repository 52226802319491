import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, TextField } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "../../utils/axiosInstance";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../utils/toastOption";

function ResetPassword(props: any) {
	const { control, watch, handleSubmit } = useForm({ mode: "all" });
	const [notificationDetails, setNotificationDetails] = useState({ type: "", message: "" })
	const [showNotification, setShowNotification] = useState(false);

	function handleClose() {
		props.onClose(); // Call the onClose function passed from the parent component to close the modal		
	}

	const onSubmit = (data: any) => {
		data.username = props.username;
		axiosInstance.post(`users/resetPassword`, data).then(reponse => {
			// setNotificationDetails({
			// 	type: "success",
			// 	message: "Your password reset successfully."
			// })
			// setShowNotification(true);
			toast.success("Your password has been reset successfully", TOAST_OPTIONS);
			props.onClose();
		}).catch(error => {
			// setNotificationDetails({
			// 	type: "error",
			// 	message: error.response.data.message
			// })
			// setShowNotification(true);
			toast.error(error.response.data.message, TOAST_OPTIONS);
		})
	}

	return <>
		<Dialog maxWidth="xs" open={props.showDialog} onClose={handleClose} aria-labelledby="alert-dialog-title"
			fullWidth>
			<DialogTitle id="alert-dialog-title">
				Reset Password
			</DialogTitle>
			<Divider></Divider>
			<DialogContent>
				<Card elevation={0}>
					<CardContent>
						<form autoComplete="off" className="flex flex-col gap-6 items-center"
							onSubmit={handleSubmit(onSubmit)}>
							<Controller
								name="username"
								control={control}
								render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Username" value={props.username} variant="outlined" size="small" disabled className="col-span-6" fullWidth
									helperText={error ? error.message : ""}
									error={!!error}
								/>)} />

							<Controller name="password"
								control={control}
								rules={{
									required: {
										value: true,
										message: "Password is required"
									}
								}}
								render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="New Password" variant="outlined" type="password" size="small" className="col-span-6" fullWidth
									helperText={error ? error.message : ""}
									error={!!error}
								/>)} />

							<Controller
								name="confirmPassword"
								control={control}
								rules={{
									required: {
										value: true,
										message: "Confirm Password is required"
									},
									validate: (value) => value === watch("password") || "Passwords do not match"
								}}
								render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Confirm New Password" variant="outlined" type="password" size="small" className="col-span-6" fullWidth
									helperText={error ? error.message : ""}
									error={!!error}
								/>)} />
							<Divider></Divider>
							<div className="flex flex-row gap-4 justify-start w-full">
								<Button variant="contained" color="primary" type="submit" className="grow">
									Submit
								</Button>
								<Button variant="outlined" color="primary" type="button" className="grow" onClick={handleClose}>
									Cancel
								</Button>
							</div>
						</form>
					</CardContent>
				</Card>
			</DialogContent>
			{/* <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleDelete} autoFocus color="error">
          Continue
        </Button>
      </DialogActions> */}
		</Dialog>
		{/* <NotificationMessage isOpen={showNotification} type={notificationDetails.type} message={notificationDetails.message} handleClose={() => setShowNotification(false)} /> */}
	</>;
}
export default ResetPassword;
