import { Tooltip } from "@mui/material";

const Cut = [
    { name: "ID", value: ["ID","Ideal"] },
    { name: "EX", value: ["EX","Excellent"] },
    { name: "VG", value: ["VG", "Very Good"] },
    { name: "GD", value: ["GD", "Good"] },
    { name: "Fair", value: ["F", "Fair"]  },
    { name: "Poor", value: ["P", "Poor"]  },
];

const Polish = [
    { name: "ID", value: ["ID","Ideal"] },
    { name: "EX", value: ["EX","Excellent"] },
    { name: "VG", value: ["VG", "Very Good"] },
    { name: "GD", value: ["GD", "Good"] },
    { name: "Fair", value: ["F", "Fair"]  },
    { name: "Poor", value: ["P", "Poor"]  },
]

const Symmetry = [
    { name: "ID", value: ["ID","Ideal"] },
    { name: "EX", value: ["EX","Excellent"] },
    { name: "VG", value: ["VG", "Very Good"] },
    { name: "GD", value: ["GD", "Good"] },
    { name: "Fair", value: ["F", "Fair"]  },
    { name: "Poor", value: ["P", "Poor"]  },
]

const FinishFilter = ({ selectedCut, onSelectCut, selectedPolish, onSelectPolish, selectedSymmetry, onSelectSymmetry }: any) => {
    const toggleSelected = (name: any, state: any, callback: any) => {
        const list = [...state];
        const index = list.indexOf(name);
        if (index === -1) {
            list.push(name)
        } else {
            list.splice(index, 1);
        }
        callback(list);
    }
    return <div className="grid grid-cols-12 w-full">
        <div className="md:col-span-2 col-span-12 flex flex-col justify-center gap-1">
            <span className="font-bold">Finish</span>
        </div>


        <div className="md:col-span-10 col-span-12 grid grid-cols-12 gap-6">
            <div className="flex flex-col justify-center gap-2 md:col-span-6 xl:col-span-4 col-span-12">
                <span className="font-semibold">Cut</span>
                <div className="flex gap-2">
                    {Cut.map((e: any, i: any) => <Tooltip title={e.name} key={i}><div key={i} className={`selection-button-text ${selectedCut.includes(e.name) ? 'selected' : ""}`} onClick={() => toggleSelected(e.name, selectedCut, onSelectCut)}>{e.name}</div></Tooltip>)}
                </div>
            </div>

            <div className="flex flex-col justify-center gap-2 md:col-span-6 xl:col-span-4 col-span-12">
                <span className="font-semibold">Polish</span>
                <div className="flex gap-2">
                    {Polish.map((e: any, i: any) => <Tooltip title={e.name} key={i}><div key={i} className={`selection-button-text ${selectedPolish.includes(e.name) ? 'selected' : ""}`} onClick={() => toggleSelected(e.name, selectedPolish, onSelectPolish)}>{e.name}</div></Tooltip>)}
                </div>
            </div>

            <div className="flex flex-col justify-center gap-2 md:col-span-6 xl:col-span-4 col-span-12">
                <span className="font-semibold">Symmetry</span>
                <div className="flex gap-2">
                    {Symmetry.map((e: any, i: any) => <Tooltip title={e.name} key={i}><div key={i} className={`selection-button-text ${selectedSymmetry.includes(e.name) ? 'selected' : ""}`} onClick={() => toggleSelected(e.name, selectedSymmetry, onSelectSymmetry)}>{e.name}</div></Tooltip>)}
                </div>
            </div>
        </div>
    </div>
}

export default FinishFilter;