import { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";


const SavedSearch = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState<any>([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 200,
    });
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState("all");

    const columns = useMemo(() => [
        {
            accessorKey: 'user.username', //access nested data with dot notation
            header: 'User',
            size: 150,
        },
        {
            accessorKey: "params",
            header: "Params",
            Cell: ({ row }: any) => {
                return <>

                    {
                        !row.original.params && <span>All</span>
                    }
                    {
                        row.original.params && <div className="flex flex-col">
                            {row.original.params.cert && <div>Cert No.: {row.original.params.cert}</div>}
                            {row.original.params.shapes && <div>Shapes: {row.original.params.shapes}</div>}
                            {row.original.params.cfr && <div>Carat: {row.original.params.cfr} - {row.original.params.cto}</div>}
                            {row.original.params.ctype && <div>Color: {row.original.params.ctype} {row.original.params.color} {row.original.params.intensity} {row.original.params.overtone}</div>}
                            {row.original.params.clarity && <div>Clarity: {row.original.params.clarity}</div>}
                            {row.original.params.cut && <div>Cut: {row.original.params.cut}</div>}
                            {row.original.params.symm && <div>Symm: {row.original.params.symm}</div>}
                            {row.original.params.fl && <div>Fluor: {row.original.params.fl}</div>}
                            {(row.original.params.rfo || row.original.params.rto) && <div>Ratio: {row.original.params.rfo}-{row.original.params.rto}</div>}
                            {row.original.params.type && <div>Growth Type: {row.original.params.type}</div>}
                            {row.original.params.lab && <div>Lab: {row.original.params.lab}</div>}
                            {row.original.params.treatment && <div>Treatment: {row.original.params.treatment}</div>}
                            {row.original.params.ha && <div>H&A: {row.original.params.ha}</div>}
                        </div>
                    }
                    {/* {!row.original.params || "-"} */}
                </>
            }

        },
        {
            accessorKey: "recordsCount",
            header: "Records Found"
        },
        {
            accessorKey: 'createdAt',
            header: 'Time',
            size: 80,
            accessorFn: (row: any) => new Date(row.createdAt).toLocaleString() // Convert timestamp to time format
        }
    ], [])

    const table = useMaterialReactTable({
        columns,
        data: searchData,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableHiding: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        manualPagination: true,
        onPaginationChange: setPagination,
        rowCount: totalCount,
        state: {
            isLoading,
            pagination
        },
        initialState: {
            showColumnFilters: false,
            density: "compact"
        },
        muiTableContainerProps: {
            sx: {
                maxHeight: "calc(100vh - 260px)"
            }
        },
        muiTableBodyCellProps: () => ({
            //conditionally style pinned columns
            // sx: {
            // 	padding: "0px 0.5rem"
            // },
        }),
        muiPaginationProps: {
            rowsPerPageOptions: [50, 100, 200],
        }

    });

    const handleFilterChange = (
        event: any,
        newFilter: string,
    ) => {
        setFilter(newFilter);
    };

    useEffect(() => {
        setIsLoading(true);
        axiosInstance.get(`saved-search?filter=${filter}&pageSize=${pagination.pageSize}&pageIndex=${pagination.pageIndex}`).then(response => {
            setSearchData(response.data.data);
            setTotalCount(response.data.recordsCount)
            setIsLoading(false);
        }).catch(error => {
            console.log("Error ::: ", error)
            setIsLoading(false);
        })
    }, [pagination, filter])

    return <div className="w-full">
        <div className="p-4 flex justify-between items-center">
            <span className="font-bold text-3xl">Search History</span>
            <ToggleButtonGroup
                color="primary"
                value={filter}
                exclusive
                onChange={handleFilterChange}
                aria-label="Platform"
                size="small"
            >
                <ToggleButton value="all">All</ToggleButton>
                <ToggleButton value="today">Today</ToggleButton>
                <ToggleButton value="last7days">Last 7 days</ToggleButton>
            </ToggleButtonGroup>
        </div>

        <div>
            <MaterialReactTable table={table} />
        </div>

    </div>
}

export default SavedSearch;