import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Typography } from "@mui/material";
import isAuth from "../../../components/IsAuth/IsAuth";
import StockList from "../../../components/StockList/StockList";
import axiosInstance from "../../../utils/axiosInstance";
import ToggleData from "../../../components/ToggleData/ToggleData";
import CompareStocks from "../../../components/CompareStocks/CompareStocks";
import AddToCart from "../../../components/AddToCart/AddToCart";
import ShareStocks from "../../../components/ShareStocks/ShareStocks";
import SelectedStockSendEmail from "../../../components/SelectedStockSendEmail/SelectedStockSendEmail";
import { ManageSearchOutlined, SearchOffOutlined } from "@mui/icons-material";

const SearchResultPage = () => {
    const [displayType, setDisplayType] = useState("table");
    const [stockData, setStockData] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 200,
    });
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState<any>({});
    const [selectedRowsData, setSelectedRowsData] = useState<any>([]);
    const [sorting, setSorting] = useState<any>([]);
    const location = useLocation();
    const navigate = useNavigate();
    
    const getStocksData = () => {
        setLoading(true);
        const query: any = {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
        }

        if (sorting.length) {
            query.sort = sorting[0].id;
            query.desc = sorting[0].desc
        }
        axiosInstance.get(`stocks/search${location.search}`, {
            params: query
        }).then((response: any) => {
            // const data: any = [...stockData, ...response.data.data];
            // setStockData(data);
            setStockData(response.data.data);
            setTotalCount(response.data.recordsCount);
            setLoading(false);
        }).catch(e => {
            console.log(e);
            setLoading(false);
        })
    }


    const clearSearch = () => {
        navigate(location.pathname);
    }

    const modifySearch = () => {
        const level = localStorage.getItem('level');
        if(level == 'admin') {
            navigate('/search', {state: location.search} )
        }
        if(level == 'client') {
            navigate("/client/search", {state: location.search})
        }
    }

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 200,
        })
    }, [sorting, location.search])

    useEffect(() => {
        getStocksData();
    }, [pagination])

    useEffect(() => {
        const rowsId = Object.keys(selectedRows);
        if (rowsId.length > 0) {
            const rowsData: any = [];
            rowsId.forEach(id => {
                const row = stockData.find((d: any) => d._id === id)
                rowsData.push(row);
            })
            setSelectedRowsData(rowsData);
        } else {
            setSelectedRowsData([]);
        }
    }, [selectedRows])

    const buttons = [
        <CompareStocks selectedRowData={selectedRowsData} />,
        <AddToCart selectedRowsData={selectedRowsData} />,
        <SelectedStockSendEmail selectedRows={selectedRows} />,
        <ShareStocks selectedRowData={selectedRowsData} />
    ];

    const stocksButton = [
        <Button startIcon={<SearchOffOutlined />} color="error" onClick={clearSearch}>Clear Search</Button>,
        <Button startIcon={<ManageSearchOutlined />} color="info" onClick={modifySearch}>Modify Search</Button>
    ]

    return <>
        <div className="p-4 flex flex-col md:flex-row gap-4 md:justify-between md:items-center">
            <Typography variant="h5">Search Result</Typography>
            <div className="flex flex-wrap gap-4">
                <ButtonGroup>
                    {stocksButton}
                </ButtonGroup>
                <ButtonGroup>
                    {buttons}
                </ButtonGroup>
                <ToggleData displayType={displayType} onChange={setDisplayType} />
            </div>
        </div>
        <div>
            <StockList manualSearch={false} sorting={true} displayType={displayType} stockData={stockData} pagination={pagination} setPagination={setPagination} totalCount={totalCount} loading={loading} onRowsSelect={setSelectedRows} onSort={setSorting}></StockList>
        </div>
    </>
}

export default isAuth(SearchResultPage);