export const CLARITY_LIST = [
    { name: "FL", value: "FL" },
    { name: "IF", value: "IF" },
    { name: "VVS1", value: "VVS1" },
    { name: "VVS2", value: "VVS2" },
    { name: "VS1", value: "VS1" },
    { name: "VS2", value: "VS2" },
    { name: "SI1", value: "SI1" },
    { name: "SI2", value: "SI2" },
    { name: "SI3", value: "SI3" },
    { name: "I1", value: "I1" },
    { name: "I2", value: "I2" },
    { name: "I3", value: "I3" }
]

export const WHITE_COLORS = [
    { name: "D", value: "D" },
    { name: "E", value: "E" },
    { name: "F", value: "F" },
    { name: "G", value: "G" },
    { name: "H", value: "H" },
    { name: "I", value: "I" },
    { name: "J", value: "J" },
    { name: "K", value: "K" },
    { name: "L", value: "L" },
    { name: "M", value: "M" },
    { name: "N", value: "N" },
    { name: "O", value: "O" },
    { name: "P", value: "P" },
    { name: "Q", value: "Q" },
    { name: "R", value: "R" },
    { name: "S", value: "S" },
    { name: "T", value: "T" },
    { name: "U", value: "U" },
    { name: "V", value: "V" },
    { name: "W", value: "W" },
    { name: "X", value: "X" },
    { name: "Y", value: "Y" },
    { name: "Z", value: "Z" },
]

export const BASIC_SHAPES = [
    {
        name: "Round",
        icon: 'icon-round',
        value: "Round",
    },
    {
        name: "Oval",
        value: "Oval",
        icon: 'icon-Single_6',
    },
    {
        name: "Pear",
        value: "Pear",
        icon: 'icon-Single_5',
    },
    {
        name: "Marquise",
        value: "Marquise",
        icon: 'icon-Single_7',
    },
    {
        name: "Heart",
        value: "Heart",
        icon: 'icon-Single_8',
    },
    {
        name: "Emerald",
        value: "Emerald",
        icon: 'icon-Single_9',
    },
    {
        name: "Square Emerald",
        value: "Square Emerald",
        icon: 'j-icon-Single_11',
    },
    {
        name: "Princess",
        value: "Princess",
        icon: 'icon-Single_4',
    },
    {
        name: "Radiant",
        value: "Radiant",
        icon: 'j-icon-Single_12',
    },
    {
        name: "Cushion",
        value: "Cushion",
        icon: 'j-icon-Single_14',
    },
    {
        name: "Baguette",
        value: "Baguette",
        icon: 'j-icon-Single_15',
    },
]


export const ADVANCED_SHAPES = [
    {
        name: "Hexagonal",
        value: "Hexagonal",
        icon: 'icon-hexa',
    },
    {
        name: "Bullet Cut",
        value: "Bullet Cut",
        icon: 'icon-bullet',
    },
    {
        name: "Trilliant",
        value: "Trilliant",
        icon: 'j-icon-Single_13',
    },
    {
        name: "Trapezoid",
        value: "Trapezoid",
        icon: 'icon-tr',
    },
    {
        name: "Half Moon",
        value: "Half Moon",
        icon: 'icon-half-moon',
    },
    {
        name: "Cadillac",
        value: "Cadillac",
        icon: 'j-icon-Single_19',
    },
    {
        name: "Shield",
        value: "Shield",
        icon: 'j-icon-Single_21',
    },
    {
        name: "Rose Cut",
        value: "Rose Cut",
        icon: 'j-icon-Single_20',
    },
    {
        name: "Other",
        value: "Other",
        icon: 'icon-other',
    },
]

export const FANCY_COLORS = [
    {
        name: 'Yellow',
        value: 'Yellow',
        className: 'yellow',
        icon: 'icon-round'
    },
    {
        name: 'Orange',
        value: 'Orange',
        className: 'orange',
        icon: 'icon-round'
    },
    {
        name: 'Pink',
        value: 'Pink',
        className: 'pink',
        icon: 'icon-round'
    },
    {
        name: 'Blue',
        value: 'Blue',
        className: 'Blue',
        icon: 'icon-round'
    },
    {
        name: 'Green',
        value: 'Green',
        className: 'Green',
        icon: 'icon-round'
    },
    {
        name: 'Brown',
        value: 'Brown',
        className: 'Brown',
        icon: 'icon-round'
    },
    {
        name: 'Red',
        value: 'Red',
        className: 'Red',
        icon: 'icon-round'
    },
    {
        name: 'White',
        value: 'White',
        className: 'White',
        icon: 'icon-round'
    },
    {
        name: 'Violet',
        value: 'Violet',
        className: 'Violet',
        icon: 'icon-round'
    },
    {
        name: 'Purple',
        value: 'Purple',
        className: 'Purple',
        icon: 'icon-round'
    },
    {
        name: 'Gray',
        value: 'Gray',
        className: 'Gray',
        icon: 'icon-round'
    },
    {
        name: 'Olive',
        value: 'Olive',
        className: 'Olive',
        icon: 'icon-round'
    },
    {
        name: 'Black',
        value: 'Black',
        className: 'Black',
        icon: 'icon-round'
    },
]

export const OVERTONE = [
    { name: "White", value: "White"  },
    { name: "Black", value: "Black" },
    { name: "Yellow", value: "Yellow" },
    { name: "Yellowish", value: "Yellowish" },
    { name: "Pink", value: "Pink" },
    { name: "Pinkish", value: "Pinkish" },
    { name: "Blue", value: "Blue" },
    { name: "Bluish", value: "Bluish" },
    { name: "Red", value: "Red" },
    { name: "Reddish", value: "Raddish" },
    { name: "Green", value: "Green" },
    { name: "Greenish", value: "Greenish" },
    { name: "Purple", value: "Purple" },
    { name: "Purplish", value: "Purplish" },
    { name: "Orange", value: "Orange" },
    { name: "Orangy", value: "Orangy" },
    { name: "Violet",value: "Violet"  },
    { name: "Violetish", value: "Violetish" },
    { name: "Gray", value: "Gray" },
    { name: "Grayish", value: "Grayish" },
    { name: "Brown", value: "Brown" },
    { name: "Brownish", value: "Brownish" },
    { name: "Champagne", value: "Champagne" },
    { name: "Cognac", value: "Cognac" },
    { name: "Chameleon", value: "Chameleon" },
    { name: "Other", value: "Other" },
]

export const INTENSITY = [
    { name: "Light", value: "Light" },
    { name: "Very Light", value: "Very Light" },
    { name: "Fancy Light", value: "Fancy Light" },
    { name: "Fancy", value: "Fancy" },
    { name: "Fancy Deep", value: "Fancy Deep" },
    { name: "Fancy Dark", value: "Fancy Dark" },
    { name: "Fancy Vivid", value: "Fancy Vivid" },
    { name: "Fancy Intense", value: "Fancy Intense" },
    { name: "Faint", value: "Faint" },
]