import { useEffect, useState } from "react";
import isAuth from "../../../components/IsAuth/IsAuth"
import shapes from "../../../images/DShape.png";
import { Card, CardContent, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { DiamondOutlined, SearchOutlined, ShoppingCartOutlined } from "@mui/icons-material";
import axiosInstance from "../../../utils/axiosInstance";

const ClientDashboardPage = () => {
    const [stats, setStats] = useState<any>(null);

    useEffect(() => {
        axiosInstance.get('stats').then(response => {
            setStats(response.data);
        })
    }, []);

    return <div className="py-5">
        <div>
            <span className="font-bold text-3xl px-4">Dashboard</span>
        </div>
        <div className="grid grid-cols-12 gap-5 mt-5">
            <Link to="/client/search" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-4 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                        <SearchOutlined />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-gray-700">
                            Search Diamonds
                        </p>
                    </div>
                </Card>
            </Link>

            <Link to="/client/result?ctype=fancy" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-4 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full">
                        <DiamondOutlined />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-gray-700">
                            Fancy Colors
                        </p>
                    </div>
                </Card>
            </Link>

            <Link to="/client/search" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-4 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-yellow-500 bg-yellow-100 rounded-full">
                        <DiamondOutlined />
                    </div>
                    <div>
                        <p className="text-sm font-medium text-gray-600">
                            New Arrival
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                            {stats?.stock}
                        </p>
                    </div>
                </Card>
            </Link>

            <Link to="/client/cart" className="md:col-span-3 col-span-12">
                <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                    <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full">
                        <ShoppingCartOutlined />
                    </div>
                    <div>
                        <p className="text-sm font-medium text-gray-600">
                            Items added to Cart
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                            {stats?.cart}
                        </p>
                    </div>
                </Card>
            </Link>

            <Card variant="outlined" className="md:col-span-3 col-span-12 flex items-center p-4 hover:drop-shadow-md">
                <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                    <DiamondOutlined />
                </div>
                <div>
                    <p className="text-sm font-medium text-gray-600">
                        Jewellry
                    </p>
                    <p className="text-lg font-semibold text-gray-700">
                        Coming Soon
                    </p>
                </div>
            </Card>

        </div>
    </div>

    // const [username, setUsername] = useState('');

    // useEffect(() => {
    //     const storedUsername = localStorage.getItem("username");
    //     if (storedUsername) {
    //         setUsername(storedUsername);
    //     }

    // }, []);

    // return <>
    //     <Card variant="elevation" className="sx:w-full mt-5">
    //         <CardContent>
    //             <h1>Welcome {username} !</h1>
    //             <Divider sx={{ marginY: 0 }} />
    //             {/* Add more content or functionality here */}
    //         </CardContent>
    //         <div style={{ display: "flex" }}> {/* Flex container to display videos side by side */}
    //             <Card variant="elevation" style={{ flex: 1, marginRight: 5 }}>
    //                 <video
    //                     autoPlay={true}
    //                     loop={true}
    //                     muted={false}
    //                     width="100%"
    //                     height="315"
    //                 >
    //                     <source src="https://rocksaltlab-public.s3.ap-south-1.amazonaws.com/vd2.mp4" type="video/mp4" />
    //                     Your browser does not support the video tag.
    //                 </video>
    //             </Card>
    //             <Card variant="elevation" style={{ flex: 1, marginLeft: 5 }}>
    //                 <img src={shapes}></img>
    //             </Card>
    //             {/* <Card variant="elevation" style={{ flex: 1, marginLeft: 1 }}>
    //                 <video
    //                     autoPlay={true}
    //                     loop={true}
    //                     muted={false}
    //                     width="100%"
    //                     height="315"
    //                 >
    //                     <source src="/vd2.mp4" type="video/mp4" />
    //                     Your browser does not support the video tag.
    //                 </video>
    //             </Card> */}
    //         </div>
    //     </Card >
    // </>
}

export default isAuth(ClientDashboardPage);