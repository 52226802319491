import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";


const OtherFilters = ({isHA, onChangeHA}: any) => {
    return <div className="grid grid-cols-12">
        <div className="md:col-span-2 col-span-12 flex flex-col justify-center gap-1"></div>

        <div className="md:col-span-10 col-span-12 flex flex-wrap items-center gap-2">
            <FormGroup>
                <FormControlLabel
                    value="end"
                    control={<Checkbox checked={isHA} onChange={(e) => {onChangeHA(e.target.checked)}}/>}
                    label="H&A"
                    labelPlacement="end"
                />
            </FormGroup>
        </div>
    </div>
}

export default OtherFilters;